import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Link } from 'react-router-dom';
import 'safedb/common/css/IniCustomCss.css';
import { handleValueDelete } from 'components/common/SunnyUtils';

const AccountForm = ({
  register,
  errors,
  watch,
  onFormComplete,
  onDataChange
}) => {

  const name = watch('name');
  const tel = watch('tel');
  const email = watch('email');
  const org = watch('org');
  const agreedToTerms = watch('agreedToTerms'); // 체크박스 값 추가
  // const telDataPattern = /^[0-9]{9,11}$/;
  const telDataPattern = /^(\d{9,11}|\d{2,3}-\d{3,4}-\d{4,5})$/;






  // 모든 필드가 채워졌는지 확인
  useEffect(() => {
    // const formattedTel = tel?.replace(/[^0-9]/g, ''); // 숫자만 남기고 비교


    const FormCheck =
      name &&
      !errors.name &&
      // (selectedPhonePrefix !== '') &&
      tel &&
      // telDataPattern.test(formattedTel) && // 수정: 7~8자리 숫자만 허용
      !errors.tel &&
      email &&
      !errors.email &&
      org &&
      agreedToTerms;

    onFormComplete(FormCheck);

    // }, [name, tel, email, org, agreedToTerms, errors, onFormComplete, selectedPhonePrefix]);
  }, [name, tel, email, org, agreedToTerms, errors, onFormComplete]);

  const nameDataPattern = /^(?=.{1,9}$)[가-힣a-zA-Z][가-힣a-zA-Z\s]*$/i;
  const emailDataPattern =
    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i;

  const [formData, setFormData] = useState({
    name: '',
    tel: '',
    email: '',
    org: ''
  });


  const formatPhoneNumber = value => {
    const onlyNumbers = value.replace(/[^0-9]/g, ''); // 숫자만 추출

    if (onlyNumbers.startsWith('02')) {
      // 1. 지역번호가 두자리 (02)
      if (onlyNumbers.length === 9) {
        return onlyNumbers.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3'); // XX-XXX-XXXX
      } else if (onlyNumbers.length === 10) {
        return onlyNumbers.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'); // XX-XXXX-XXXX
      }
    } else if (onlyNumbers.startsWith('010')) {
      // 2. 핸드폰 번호 (010)
      if (onlyNumbers.length === 10) {
        return onlyNumbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); // XXX-XXX-XXXX
      } else if (onlyNumbers.length === 11) {
        return onlyNumbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'); // XXX-XXXX-XXXX
      }
    } else {
      // 3. 일반 3자리 지역번호
      if (onlyNumbers.length === 10) {
        return onlyNumbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'); // XXX-XXX-XXXX
      } else if (onlyNumbers.length === 11) {
        return onlyNumbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'); // XXX-XXXX-XXXX
      }
    }

    // 포맷이 맞지 않는 경우 그대로 반환
    return onlyNumbers;
  };



  const handleInput = e => {
    const { name, value } = e.target;

    if (name === 'tel') {
      const formattedValue = formatPhoneNumber(value);
      setFormData(prev => {
        const updatedData = { ...prev, [name]: formattedValue };
        // onDataChange(updatedData, selectedPhonePrefix); // 부모에게 변경된 데이터 전달
        onDataChange(updatedData); // 부모에게 변경된 데이터 전달
        return updatedData;
      });
    } else {
      setFormData(prev => {
        const updatedData = { ...prev, [name]: value };
        // onDataChange(updatedData, selectedPhonePrefix); // 부모에게 변경된 데이터 전달
        onDataChange(updatedData); // 부모에게 변경된 데이터 전달
        return updatedData;
      });
    }
  };

  const handleReset = (e, inputName) => {
    e.preventDefault();
    handleValueDelete(inputName, formData, setFormData);
  };

  return (
    <>
      {/* 원래 있던 소스 */}
      <WizardInput
        label={
          <span>
            이름<span style={{ color: 'red' }}>*</span>
          </span>
        }
        name="name"
        maxLength={20}
        handleInputProps={handleInput}
        inputData={formData.name}
        handleReset={handleReset}
        verifiedValue={nameDataPattern.test(formData.name)}
        errors={errors}
        formGroupProps={{ className: 'mb-1' }}
        placeholder={'이름을 입력해 주세요.'}

        formControlProps={{
          ...register('name', {
            required: {
              value: true,
              message: '이름 입력이 필요합니다.' // 빈 값일 때 표시할 메시지
            },
            pattern: {
              value: nameDataPattern, // 한글 또는 영문만 허용, 숫자는 제외
              message: '유효한 이름을 입력해주세요.'
            }
          }),
          className: 'input-hover' // Hover 클래스 추가
        }}
      />
      {errors.name && (
        <span style={{ color: 'red' }}>
          {errors.name.message}
        </span>

      )}

      <WizardInput
        label={
          <span>
            연락처<span style={{ color: 'red' }}>*</span>
          </span>
        }
        name="tel"
        // type="text"
        maxLength={20}
        handleInputProps={handleInput}
        inputData={formData.tel}
        handleReset={handleReset}
        // verifiedValue={telDataPattern.test(formData.tel.replace(/[^0-9]/g, ''))} // 숫자만 검증
        verifiedValue={telDataPattern.test(formData.tel)}
        errors={errors}
        formGroupProps={{ className: 'mb-1' }}
        placeholder={
          '연락처를 입력해 주세요.(하이픈 제외)'
        }
        formControlProps={{
          ...register('tel', {

            required: {
              value: true,
              message: '연락처 입력이 필요합니다.' // 빈 값일 때 표시할 메시지
            },
            pattern: {
              value: telDataPattern,
              message:
                '유효한 전화번호 형식으로 입력해주세요. (ex. 010-0000-0000 또는 02-0000-0000)'
            }
          }),
          className: 'input-hover', // Hover 클래스 추가
          // onKeyDown: handleKeyDown // 키 입력 이벤트 처리 추가
        }}
      />
      {errors.tel && (
        <span style={{ color: 'red' }}>
          {errors.tel.message}
        </span>

      )}
      <WizardInput
        type="email"
        errors={errors}
        label={
          <span>
            Email<span style={{ color: 'red' }}>*</span>
          </span>
        }
        name="email"
        maxLength={50}
        handleInputProps={handleInput}
        inputData={formData.email}
        handleReset={handleReset}
        verifiedValue={emailDataPattern.test(formData.email)}
        formGroupProps={{ className: 'mb-1' }}
        placeholder={'이메일을 입력해 주세요.'}
        formControlProps={{
          ...register('email', {
            required: '이메일 입력이 필요합니다.',
            pattern: {
              value: emailDataPattern,
              message: '유효한 이메일 형식으로 작성해주세요.'
            }
          }),
          className: 'input-hover' // Hover 클래스 추가
        }}
      />
      {errors.email && (
        <span style={{ color: 'red' }}>
          {errors.email.message}
        </span>

      )}
      <WizardInput
        label={
          <span>
            회사 정보<span style={{ color: 'red' }}>*</span>
          </span>
        }
        maxLength={20}
        name="org"
        handleInputProps={handleInput}
        inputData={formData.org}
        handleReset={handleReset}
        verifiedValue={formData.org.trim() !== ''}
        errors={errors}
        formGroupProps={{ className: 'mb-1' }}
        placeholder={'회사 정보를 입력해 주세요.'}
        formControlProps={{
          ...register('org', {
            required: '회사 정보 입력이 필요합니다.'
          }),
          className: 'input-hover' // Hover 클래스 추가
        }}
      />
      {errors.org && (
        <span style={{ color: 'red' }}>
          {errors.org.message}
        </span>

      )}
      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            <Link
              target="_blank"
              to="https://www.initech.com/html/sub/inve/pers_info.html"
            >
              {' '}
              개인정보 수집 방침
            </Link>
            에 동의합니다.
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: '개인정보수집방치에 동의가 필요합니다.'
          })
        }}
      />
      {/* <YongInput label='이름' />
      <YongInput label='연락처' />
      <YongInput label='이메일' />
      <YongInput label='회사 정보' />

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            <Link target='_blank' to="https://www.initech.com/html/sub/inve/pers_info.html"> 개인정보 수집 방침</Link>에 동의합니다.
          </>
        }

        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: '개인정보수집방치에 동의가 필요합니다.'
          })
        }}
      /> */}
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  // onDataChange: PropTypes.func.isRequired,
  onFormComplete: PropTypes.func.isRequired
};

export default AccountForm;
