import React, { useState, useEffect } from 'react';
import { Button, Modal, } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
// import data from '../../safedb/SA/sampledata/sampledataGroup';
import CheckOutModal from 'components/modal/CheckOutModal';
import GroupAdvanceTable from 'components/common/advance-table/GroupAdvanceTable';
import { toast } from 'react-toastify';
import { fetchGroupList, deleteGroupList } from 'hooks/CallApi'; // API 함수 임포트


function AdvanceTableExample() {
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');

  const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [totalElements, setTotalElements] = useState(0);
  const [selectedGroupUuid, setSelectedGroupUuid] = useState(null); // 선택된 그룹 UUID 저장


  const columns = [
    {
      accessorKey: 'num',
      header: '순번',
      meta: {
        headerProps: { className: 'text-900', style: { width: '60px' } },
        cellProps: { style: { width: '60px' } }
      },
      enableSorting: false
    },
    {
      accessorKey: 'groupName',
      header: '그룹',
      meta: { maxlength: 20, headerProps: { className: 'text-900' } },
      enableSorting: true
    },
    {
      accessorKey: 'groupDescription',
      header: '설명',
      meta: { maxlength: 50, headerProps: { className: 'text-900' } },
      enableSorting: false
    },
    {
      accessorKey: 'delete',
      header: '삭제',
      cell: ({ row }) => (
        <Button variant="danger" size="sm" onClick={() => handleDeleteClick(row.original.apigroup_uuid)}>
          삭제
        </Button>
      ),
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '80px', textAlign: 'center' }
        },
        cellProps: { style: { width: '80px' } }
      },
      enableSorting: false
    }
  ];
  const handleDeleteClick = (uuid) => {
    setSelectedGroupUuid(uuid); // 삭제할 그룹 UUID 저장
    setModalShow(true); // 모달 표시
  };

  const handleConfirmDelete = async () => {
    try {
      if (!selectedGroupUuid) return;

      await deleteGroupList(selectedGroupUuid); // 삭제 API 호출

      // 테이블 데이터 새로고침
      const updatedData = tableData.filter(
        (item) => item.apigroup_uuid !== selectedGroupUuid
      );

      setModalReShow(true);
      setTableData(updatedData); // 테이블 데이터 업데이트
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);

      setModalErrorShow(true);

    } finally {
      setModalShow(false); // 모달 닫기
      setSelectedGroupUuid(null); // 선택된 UUID 초기화
    }
  };


  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage: 5
  });

  // API 호출 
  useEffect(() => {
    // 전체 데이터 로드를 위한 함수
    const loadAllData = async () => {
      let currentPage = 0;
      let allData = [];

      // 300ms 후에 스피너를 표시하는 타이머 설정
      const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

      try {
        const totalData = await fetchGroupList(0, pageSize);
        const totalPages = totalData.data.page.totalPages; // totalPages를 통해 페이지 수 계산
        for (currentPage = 0; currentPage < totalPages; currentPage++) {
          const data = await fetchGroupList(currentPage, pageSize);

          const content = data.data.content.map((item, index) => ({
            ...item,
            num: currentPage * pageSize + index + 1, // 순번 추가 (전체 순번 기준)
            apigroup_uuid: item.apigroupId.apigroupUuid,
            groupName: item.groupName,
            groupDescription: item.groupDescription,
          }));

          allData = [...allData, ...content];
        }
        setTableData(allData);
        setTotalElements(totalData.data.page.totalElements); // 총 요소 수 설정
      } catch (error) {
        console.error('데이터 불러오기 에러:', error);
      } finally {
        clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
        setLoading(false);
        setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
      }
    };

    loadAllData();
  }, [pageSize]); // pageSize가 변경될 때마다 loadAllData 실행



  return (
    <AdvanceTableProvider {...table}>
      <GroupAdvanceTable
        headerClassName="bg-200 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          striped: true,
          className: 'fs-10 mb-0 overflow-hidden'
        }}
      />
      <div className="mt-3">
        <AdvanceTablePagination />
      </div>
      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirmDelete}
        title="확인"
        body="해당 그룹을 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={() => setModalReShow(false)}
        title="완료"
        body="삭제 되었습니다."
        confirmText="확인"
        variant="success"
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"

        SubmitButton={true}
      />

    </AdvanceTableProvider>
  );
}

const GroupManagementModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1100 }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">그룹 관리</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvanceTableExample />
      </Modal.Body>
      <Modal.Footer>
        <Button className="mx-2" variant="outline-secondary" onClick={onHide}>
          취소
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          등록
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupManagementModal;
