import paths, { rootPaths } from './paths';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import BillingHistory from '../safedb/common/billing/BillingHistory';

export const DashRoutes = {
  label: '정 보',
  children: [
    {
      name: '대시보드',
      icon: 'chart-pie',
      active: true,
      to: rootPaths.root
    },
    {
      name: '회사정보',
      icon: faBuilding,
      active: true,
      to: paths.SiteInfo,
    },
  ]
};

export const AccountRoutes = {

  label: '계 정 관 리',
  children: [
    {
      name: '관리자',
      icon: 'chart-pie',
      active: true,
      to: paths.SiteAdminAccount,
    },
  ]
};

export const appRoutes = {
  label: '키 관 리',
  children: [
    {
      name: '인증키',
      icon: 'calendar-alt',
      to: paths.apikey,
      active: true,
    },
    {
      name: '공유키',
      icon: 'envelope-open',
      to: paths.encryptPublicKey,
      active: true
    },
    {
      name: '암호키',
      icon: 'comments',
      to: paths.encryptKeyPolicy,
      active: true
    }

  ]
};

export const pagesRoutes = {
  label: '모 니 터 링',
  children: [
    {
      name: '관리 로그',
      icon: 'flag',
      to: paths.logManage,
      active: true
    },
    {
      name: '암복호화 로그',
      icon: 'flag',
      to: paths.logEncDec,
      active: true
    },
  ]
};

export const modulesRoutes = {
  label: '청 구',
  children: [
    {
      name: '청구 내역',
      active: true,
      to: paths.billing,
      icon: 'file-invoice-dollar',
      element: <BillingHistory />
    },
  ]
};

export const guideRoutes = {
  label: '배 포',
  children: [
    {
      name: 'API 가이드',
      active: true,
      to: paths.apiGuide,
      icon: 'file-alt',
    },
    {
      name: 'SDK 배포',
      active: true,
      to: paths.SdkDownload,
      icon: 'file-alt',
    },
  ]
};

export default [
  DashRoutes,
  AccountRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  guideRoutes
];
