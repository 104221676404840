import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import CheckOutModal from 'components/modal/CheckOutModal';
import { addGroup } from 'hooks/CallApi'; // API 함수 임포트


const GroupAddModal = ({ show, onHide, onConfirm }) => {
  const [modalShow, setModalShow] = useState(false);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [resultShow, setResultShow] = useState(false);
  const [toastMessage, setToastMessage] = useState(''); // Toast 메시지 상태
  const [toastType, setToastType] = useState(''); // Toast 타입 상태


  const [formData, setFormData] = useState({
    group: '',
    desc: ''
  });


  const handleConfirm = async () => {
    try {
      await addGroup({
        groupName: formData.group,
        groupDescription: formData.desc,
      });
      // localStorage.setItem('toastMessage', '등록이 완료되었습니다');
      // localStorage.setItem('toastType', 'success'); // 메시지 타입 저장
      setToastMessage('등록이 완료되었습니다');
      setToastType('success');
      setResultShow(true);
      setModalShow(false);
      onHide();
      // window.location.reload(); // 등록 완료 후 페이지 새로고침
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
      setModalShow(false);
    }
  };

  const handleSubmitClick = () => {
    setModalShow(true); // 모달 열기
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // 입력된 값을 formData에 업데이트
    }));
  };
  useEffect(() => {
    if (show) {
      // 초기화할 기본 값 설정
      setFormData({
        group: '',
        desc: '',
      });
    }
  }, [show, setFormData]); // show가 변경될 때마다 실행

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 1100 }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">그룹 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>그룹<span className='text-danger'>*</span></Form.Label>
            <Form.Control
              type="text"
              name="group"
              value={formData.group}
              onChange={handleChange}
              placeholder='그룹명을 입력해주세요.'
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>설명</Form.Label>
            <Form.Control
              type="text"
              name="desc"
              value={formData.desc}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mx-2" variant="outline-secondary" onClick={onHide} >
            취소
          </Button>
          <Button variant="primary" onClick={handleSubmitClick} disabled={!formData.group}>
            저장
          </Button>
        </Modal.Footer>
      </Modal>



      <CheckOutModal
        show={resultShow}
        onHide={() => setResultShow(false)}
        onConfirm={() => setResultShow(false)}
        title={toastType === 'success' ? '완료' : '경고'}
        body={toastMessage}
        confirmText="확인"
        variant={toastType === 'success' ? 'success' : 'warning'}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirm}
        title="확인"
        body="해당 그룹을 등록하시겠습니까?"
        cancelText="취소"
        confirmText="등록"
        variant="info"
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"

        SubmitButton={true}
      />
    </>
  );
};

export default GroupAddModal;
