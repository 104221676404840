import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  // documentationRoutes
} from 'routes/siteMaps';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarDropdownModules from './NavbarDropdownModules';
import { useAppContext } from 'providers/AppProvider';


const NavbarTopDropDownMenus = (route) => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useAppContext();

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {/* <Link to="/">dashboard</Link> */}
      {/* hrefLink를 만들어줘서 NavbarDropdown에 같이보냄, NavbarDropdown이 링크 만들어줌 */}
      <NavbarDropdown title="총괄대시보드" hrefLink='/dashboard/crm'>
        {/* {dashboardRoutes.children[0].children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
          // onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))} */}
      </NavbarDropdown>


      <NavbarDropdown title="관리자계정" hrefLink='/safedb/SA/adminAccount'>
        {/* <NavbarDropdownApp items={appRoutes.children} /> */}
      </NavbarDropdown>

      <NavbarDropdown title="관리정책" hrefLink='/safedb/SA/policyManager'>
        {/* <NavbarDropdownPages items={pagesRoutes.children} /> */}
      </NavbarDropdown>
      <NavbarDropdown title="관리활동로그" hrefLink='/safedb/SA/logAdmin'>
        {/* <NavbarDropdownModules items={paths.starter} /> */}

      </NavbarDropdown>
      {/* <NavbarDropdown title="청구관리" hrefLink='/pages/starter'> */}
      {/* <NavbarDropdownModules items={modulesRoutes.children} /> */}
      {/* </NavbarDropdown> */}
      <NavbarDropdown title="고객사관리" hrefLink='/safedb/SA/adminSites'>
        {/* <NavbarDropdownModules items={modulesRoutes.children} /> */}
      </NavbarDropdown>

      {/* <NavbarDropdown title="documentation">
        {flatRoutes(documentationRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown> */}
    </>
  );
};

export default NavbarTopDropDownMenus;
