import React, { useState, useEffect } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { fetchAdminList, deleteMultiAdminInfo } from 'hooks/CallApi';
import { formatDate } from 'components/common/SunnyUtils';
import { useAppContext } from 'providers/AppProvider';

const columns = [
  {
    accessorKey: 'DuserRole',
    header: '역할',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'companyName',
    header: '소속',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'userId',
    header: '아이디',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'username',
    header: '이름',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'active',
    header: '상태',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'createdAt',
    header: '등록일시',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'updateAt',
    header: '최근접속일시',
    meta: { headerProps: { className: 'text-900' } }
  }
];

function AdvanceTableExample() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]); // 데이터 상태
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const { setConfig } = useAppContext();

  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });

  // API 호출 함수
  const loadData = async (page, size) => {
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const response = await fetchAdminList(page, size); // API 호출
      const logData = response.data.content.map((item, index) => ({
        num: page * size + index + 1, // 역순으로 순번 계산
        userId: item.userId,
        username: item.username,
        userRole: item.userRole,
        DuserRole:
          item.userRole === 'SA'
            ? '시스템 관리자'
            : item.userRole === 'ADMIN'
              ? '최고 관리자'
              : '운영 관리자',
        userUuid: item.userIds.userUuid,
        companyId: item.userIds.companyId,
        active: item.active ? '정상' : '비정상',
        activestatus: item.active,
        description: item.userDescription,
        companyName: item.companyName,
        imgUrl: item.imgUrl,
        createdAt: item.insertTimeUtc
          ? formatDate(item.insertTimeUtc) // 포맷 함수 호출
          : '-',
        updateAt: item.loginTimeUtc
          ? formatDate(item.loginTimeUtc) // 포맷 함수 호출
          : '-'
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    loadData(currentPage, perPage);
    setConfig('checkorg', '');
    setConfig('isNavbarVerticalCollapsed', true); // 선택된 고객사 이름 설정
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };

  const handlePerPageChange = newPerPage => {
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
  };

  const handleRowClick = row => {
    const {
      userId,
      username,
      userRole,
      userUuid,
      companyId,
      activestatus,
      companyName,
      createdAt,
      description,
      updateAt,
      imgUrl
    } = row.original;
    navigate(`/safedb/SA/adminAccount/edit`, {
      state: {
        createdAt,
        updateAt,
        activestatus,
        userUuid,
        userId,
        username,
        companyName,
        userRole,
        description,
        companyId,
        imgUrl
      } // 전달할 데이터
    });

  };

  const handleDeleteClick = () => {
    setModalShow(true);
  };

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDelete = async () => {
    setModalShow(false);

    const selecteduserUuids = table
      .getSelectedRowModel()
      .rows.map(row => row.original.userUuid); // 선택된 행의 companyId 가져오기
    try {
      await deleteMultiAdminInfo(selecteduserUuids); // 삭제 API 호출

      setModalReShow(true);
      table.resetRowSelection();
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
    }
  };

  const handleDeleteRe = () => {
    setModalReShow(false);
    window.location.reload(); // 등록 완료 후 페이지 새로고침
  };

  return (
    <AdvanceTableProvider {...table}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        className="mb-2"
      >
        {/* <AdvanceTableFooter rowsPerPageSelection /> */}
        <AdvanceTableFooter
          rowsPerPageSelection
          onPageChange={(page, newPageSize) => {
            handlePerPageChange(newPageSize); // 페이지 크기 변경
            setCurrentPage(page); // 현재 페이지 설정
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}
        >
          {selectedRowCount > 0 ? (
            <Button
              variant="outline-danger"
              style={{ width: '100px' }}
              onClick={handleDeleteClick}
            >
              삭제
            </Button>
          ) : (
            <Link to={paths.addadminAccount} style={{ textDecoration: 'none' }}>
              <Button variant="outline-success" style={{ width: '100px' }}>
                등록
              </Button>
            </Link>
          )}
        </div>
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
          onRowClick={handleRowClick}
        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>
          총 {totalElements.toLocaleString()}건
        </p>
        {/* <AdvanceTablePagination /> */}
        <AdvanceTablePagination
          currentPage={currentPage}
          totalItems={totalElements}
          itemsPerPage={perPage}
          onChange={newPage => handlePageChange(newPage)}
        />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDelete}
        title="경고"
        body="삭제된 관리자 정보는 복구할 수 없습니다. 정말 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="삭제"
        variant="warning"
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료"
        body="삭제가 완료 되었습니다."
        confirmText="확인"
        variant="success"
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />
    </AdvanceTableProvider>
  );
}

const AdminAccount = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">관리자 계정</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminAccount;
