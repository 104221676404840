import React, { useState, useRef, useEffect } from 'react';

import { useAppContext } from 'providers/AppProvider';

import { Row, Col, Button } from 'react-bootstrap';

import Summary from './Summary';
import EncOptCompare from './EncOptCompare';
import AlgorithmCompare from './AlgorithmCompare';
import TotalSales from './TotalSales';
import RecentlyProcess from './RecentlyProcess';
import RecentlyEncDec from './RecentlyEncDec';
import ReqPolicy from './ReqPolicy';

import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js'; // 한국어 로케일 가져오기
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatDate_datepicker } from 'components/common/SunnyUtils';


const Dashboard = () => {
  const [activeButton, setActiveButton] = useState('now'); // 초기값: 어제 버튼 활성화
  const {
    config: { dashboardDateValue, Chart_startdate, Chart_enddate, period },
    setConfig
  } = useAppContext();

  const [rows, setRows] = useState([
    [
      { id: 'Summary', component: <Summary />, size: { xxl: 6, xl: 12 } }, // xxl: 6, xl: 8, lg: 12, md: 12
      { id: 'RecentlyProcess', component: <RecentlyProcess />, size: { xxl: 6, xl: 12 } }, // INITECH
    ],
    // 두 번째 행
    [
      { id: 'EncOptCompare', component: <EncOptCompare />, size: { xxl: 3, xl: 12 } },
      { id: 'AlgorithmCompare', component: <AlgorithmCompare />, size: { xxl: 3, xl: 12 } },
      { id: 'RecentlyEncDec', component: <RecentlyEncDec />, size: { xxl: 6, xl: 12 } }, // INITECH
    ],
    // 세 번째 행
    [
      { id: 'topProducts', component: <ReqPolicy />, size: { xxl: 6, xl: 12 } },
      { id: 'totalSales', component: <TotalSales />, size: { xxl: 6, xl: 12 } },
    ],

  ]);

  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setConfig('Chart_startdate', null);
      setConfig('Chart_enddate', null);
      setConfig('dashboardDateValue', 'now');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);


    if (!startPickerRef.current) {
      startPickerRef.current = flatpickr('#timepicker_start', {
        mode: 'single',
        dateFormat: 'Y-m-d',
        locale: Korean,
        disableMobile: true,
        defaultDate: yesterday,
        maxDate: today,
        onChange: (selectedDates) => {
          const startDate = selectedDates[0];
          const currentEndDate = endPickerRef.current?.selectedDates[0] || today;

          if (currentEndDate && startDate > currentEndDate) {
            alert('시작 날짜는 종료 날짜 이후일 수 없습니다.');
            startPickerRef.current.setDate(currentEndDate);
            return;
          }
          // console.log('formatDate(currentEndDate)', currentEndDate);
          setConfig('Chart_enddate', formatDate_datepicker(currentEndDate, 'e'));
          setConfig('Chart_startdate', formatDate_datepicker(startDate, 's'));

          setActiveButton('custom');
          if (endPickerRef.current) {
            endPickerRef.current.set('minDate', startDate);
          }
        },
      });
    }

    if (!endPickerRef.current) {
      endPickerRef.current = flatpickr('#timepicker_end', {
        mode: 'single',
        dateFormat: 'Y-m-d',
        locale: Korean,
        disableMobile: true,
        defaultDate: today,
        minDate: yesterday,
        maxDate: today,
        onChange: (selectedDates) => {
          const endDate = selectedDates[0];
          const currentStartDate = startPickerRef.current?.selectedDates[0] || yesterday;

          if (currentStartDate && endDate < currentStartDate) {
            alert('종료 날짜는 시작 날짜 이전일 수 없습니다.');
            endPickerRef.current.setDate(currentStartDate);
            return;
          }

          setConfig('Chart_startdate', formatDate_datepicker(currentStartDate, 's'));

          setConfig('Chart_enddate', formatDate_datepicker(endDate, 'e'));
          setActiveButton('custom');
          if (startPickerRef.current) {
            startPickerRef.current.set('maxDate', endDate);
          }
        },
      });
    }
    return () => {
      startPickerRef.current?.destroy();
      endPickerRef.current?.destroy();
      // 데이터 초기화용
      setConfig('Chart_startdate', null);
      setConfig('Chart_enddate', null);
      setConfig('dashboardDateValue', 'now');

    };

  }, []);


  useEffect(() => {
    let dateLabel;
    let dateValue;


    switch (activeButton) {
      case 'now':
        dateLabel = '최근 1시간';
        dateValue = 'now';
        break;
      case 'week':
        dateLabel = '최근 1주일';
        dateValue = 'week';
        break;
      case 'month':
        dateLabel = '최근 1개월';
        dateValue = 'month';
        break;
      default:
        dateLabel = '커스텀'; // 기본값 또는 처리 필요 시 추가
        dateValue = 'custom';
        break;
    }
    if (activeButton !== 'custom') {
      setConfig('Chart_startdate', null);
      setConfig('Chart_enddate', null);
    }
    setConfig('dashboardDate', dateLabel);
    setConfig('dashboardDateValue', dateValue);
  }, [activeButton]);

  const handleButtonClick = (button) => {
    setConfig('Chart_startdate', null);
    setConfig('Chart_enddate', null);

    // demo 모드가 활성화되어 있으면 버튼 클릭 무시
    if (period === 'demo') {
      return;
    }
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    let startDate, endDate;

    switch (button) {
      case 'now':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        startDate.setDate(today.getDate() - 1); // 하루 전
        // startDate.setHours(endDate.getHours() - 1); // 한시간 전
        break;

      case 'week':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7); // 7일전
        break;
      case 'month':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 28); // 오늘 제외한 28일 전
        break;

      default:
        return;

    }

    if (startPickerRef.current) {
      startPickerRef.current.setDate(formatDate_datepicker(startDate));
      startPickerRef.current.set('maxDate', today);
    }
    if (endPickerRef.current) {
      endPickerRef.current.setDate(formatDate_datepicker(endDate));
      endPickerRef.current.set('minDate', startDate);
    }

    setActiveButton(button);
  };

  const getButtonVariant = (button) => {
    // period가 'demo'면 모든 버튼 비활성화
    if (period === 'demo') {
      return 'outline-primary';
    }
    return activeButton === button ? 'primary' : 'outline-primary'; // 활성화된 버튼에 따라 스타일 적용
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // 영역 외에 드롭이면 무반응
    if (!destination) return;

    const sourceRowIndex = parseInt(source.droppableId);
    const destinationRowIndex = parseInt(destination.droppableId);

    const sourceRow = Array.from(rows[sourceRowIndex]);
    const destinationRow = Array.from(rows[destinationRowIndex]);

    if (destination.index >= destinationRow.length) return;

    // 동일한 Row
    if (sourceRowIndex === destinationRowIndex) {
      // 위치 스왑
      const temp = sourceRow[source.index];
      sourceRow[source.index] = sourceRow[destination.index];
      sourceRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      setRows(newRows);
    }
    // 서로 다른 Row
    else {
      const [movedItem] = sourceRow.splice(source.index, 1);
      destinationRow.splice(destination.index, 0, movedItem);

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      newRows[destinationRowIndex] = destinationRow;
      setRows(newRows);
    }
  };
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  return (
    <>
      <div className="my-2 d-flex justify-content-between align-items-center">

        <h4>SafeDB 대시보드</h4>
        <div className="d-flex align-items-center" style={{ position: 'relative' }}>

          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_start"
            ref={startInputRef} // input 요소 참조
          />
          <span
            onClick={() => startInputRef.current?.click()}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              left: '7.5rem',
              top: '48%',
              transform: 'translateY(-50%)',
              zIndex: 1
            }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '5px' }} />
          </span>
          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_end"
            ref={endInputRef} // input 요소 참조
          />

          <span
            onClick={() => endInputRef.current?.click()} // click() 호출
            style={{
              cursor: 'pointer',
              position: 'absolute',
              left: '16.5rem',
              top: '48%',
              transform: 'translateY(-50%)',
              zIndex: 1
            }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '5px' }} />
          </span>

          <Button
            variant={getButtonVariant('now')}
            onClick={() => handleButtonClick('now')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem' }}>
            최근 1시간
          </Button>
          <Button
            variant={getButtonVariant('week')}
            onClick={() => handleButtonClick('week')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem', marginRight: '1rem' }}

          >
            최근 1주일
          </Button>
          <Button
            variant={getButtonVariant('month')}
            onClick={() => handleButtonClick('month')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap' }}

          >
            최근 1개월
          </Button>
        </div>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        {rows.map((row, rowIndex) => (
          <Droppable droppableId={`${rowIndex}`} key={rowIndex} direction="horizontal">
            {(provided) => (
              <Row className="g-3 mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                {row.map((comp, colIndex) => (
                  <Draggable key={comp.id} draggableId={comp.id} index={colIndex}>
                    {(provided) => (
                      <Col
                        md={comp.size.md}
                        lg={comp.size.lg}
                        xl={comp.size.xl}
                        xxl={comp.size.xxl}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {comp.component}
                      </Col>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      {/* <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={6}>
          <TotalSales data={totalSales} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <SpaceWarning />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={files} className="h-lg-100" />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <BandwidthSaved />
        </Col>
        <Col xxl={6}>
          <TopProducts data={topProducts} className="h-100" />
        </Col>
      </Row> */}

      {/* <Row className="g-3 mb-3">
        <Col xxl={4}>
          <Summary />
        </Col>
        <Col xxl={6}>
          <StorageStatus />
        </Col>

      </Row>

      <Row className="g-3 mb-3">
        <Col md={6} xxl={2}>
          <EncOptCompare />
        </Col>
        <Col md={6} xxl={2}>
          <AlgorithmCompare />
        </Col>
        <Col md={6} xxl={2}>
          <RecentlyChange />
        </Col>
        <Col md={6} xxl={6}>
          <RunningProjects />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6} xxl={7}>
          <ReqPolicy />
        </Col>
        <Col md={6} xxl={5}>
          <TotalSales />
        </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;
