import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import { fetchDataSize } from 'hooks/CallApi';
import { formatCountStyle3 } from 'components/common/SunnyUtils';

const ChartOptions = () => {
  const {
    config: {
      dashboardDate,
      dashboardDateValue,
      Chart_startdate,
      Chart_enddate,
      period
    }
  } = useAppContext();

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    timeData: [],
    successData: [],
    failData: []
  });
  const [view, setView] = useState('cf'); // 'top' or 'bottom'

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchDataSize({
          period: period || dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view
        });

        const allTimeData = data.data.map((item) => item.periodKst);

        let timeData;
        if (dashboardDateValue === 'now') {
          // "now"일 경우 6분 단위로 최대 10개 선택인데 10개가 선택이 안되는 경우가 많음
          // 60분을 분단위로 다 안줄 경우 균등하게 처리해서 데이터는 표시되도록
          const totalCount = allTimeData.length; // 전체 데이터 개수
          const displayCount = 10; // 보여줄 데이터 개수

          if (totalCount <= displayCount) {
            // 전체 데이터가 10개 이하인 경우, 모든 데이터를 사용
            timeData = allTimeData.map((time) =>
              new Date(time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })
            );
          } else {
            // 전체 데이터가 10개 초과인 경우, 균등 간격으로 데이터 선택
            const interval = Math.floor(totalCount / (displayCount - 1)); // 간격 계산
            timeData = Array.from({ length: displayCount }, (_, i) => {
              const index = Math.min(i * interval, totalCount - 1); // 마지막 데이터 포함
              return new Date(allTimeData[index]).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              });
            });
          }

          // 아래 주석 내용은 무조건 60개가 온다고 할 경우 아래 로직 사용
          // timeData = Array.from({ length: 10 }, (_, i) => {
          //   const index = i * 6; // 6분 간격으로 인덱스 생성
          //   console.log('index', index);
          //   return index < allTimeData.length
          //     ? new Date(allTimeData[index]).toLocaleTimeString([], {
          //       hour: '2-digit',
          //       minute: '2-digit',
          //       hour12: false
          //     })
          //     : ''; // 데이터가 없으면 빈값 반환
          // });

        } else if (dashboardDateValue === 'week' || dashboardDateValue === 'month') {
          // "week" 또는 "month"일 경우 날짜만 표시
          timeData = allTimeData.map((time) => {
            const date = new Date(time);
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월 2자리
            const day = date.getDate().toString().padStart(2, '0'); // 일 2자리
            return `${month}.${day}`; // 원하는 포맷으로 변환
          });
        } else {
          timeData = allTimeData; // 기본값
        }
        // const timeData = data.data.map(item => item.periodKst);
        const successData = data.data.map(item => parseInt(item.size, 10) || 0);
        const failData = data.data.map(
          item => parseInt(item.failSize, 10) || 0
        );
        setChartData({ timeData, successData, failData });
        // console.log('xAxis data:', chartData.timeData);

      } catch (error) {
        //        console.error('데이터 조회 에러:', error);
        if (error.response.data.code === -9999) {
          setChartData({
            timeData: ['데이터 없음'],
            successData: [0],
            failData: [0]
          });
        }
      }
    };

    fetchData();
  }, [dashboardDate, dateRange, view, period]);

  useEffect(() => {
    // 실패 데이터의 최소 높이 설정
    const failDataAdjusted = chartData.failData.map((failValue, index) => {
      const successValue = chartData.successData[index];

      const failPercentage = failValue / successValue;

      if (failValue === 0) return null; // 실패 데이터가 없으면 표시하지 않음
      if (failPercentage <= 0.1) {
        return 2;
      }
      // 실패량이 10% 초과일 경우 비율만큼 계산
      return failValue;
    });

    const isDataEmpty = chartData.successData.every(value => value === 0);

    const option = {
      title: isDataEmpty
        ? {
          text: '데이터 없음',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#aaa',
            fontSize: 16
          }
        }
        : null,

      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: params => {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach(item => {
            if (item.seriesName === '성공') {
              tooltipText += `${item.marker} 성공: ${formatCountStyle3(
                item.value
              )}<br/>`;
            } else if (item.seriesName === '실패') {
              const failValue = chartData.failData[item.dataIndex] || 0;
              tooltipText += `${item.marker} 실패: ${formatCountStyle3(
                failValue
              )}<br/>`;
            }
          });
          return tooltipText;
        }
      },
      legend: {
        data: [
          { name: '성공', icon: 'rect', itemStyle: { color: '#6abf40' } },
          { name: '실패', icon: 'rect', itemStyle: { color: '#ff4d4d' } }
        ],
        bottom: '2%'
      },

      grid: {
        left: '7%',
        right: '1%',
        bottom: '30%',
        top: '10%'
      },
      xAxis: {
        type: 'category',
        data: chartData.timeData.length > 0 ? chartData.timeData : [''],

        axisLabel: {
          formatter: (value, index) => {
            if (!value) {
              return ''; // 공백 반환
            }
            if (dashboardDateValue === 'now') {

              // "now"의 경우 시간만 표시, 6분 단위로 10개 표시
              // return index % 6 === 0
              //   ? new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
              //   : value;
              // 분단위 모든 데이터와서 나눌 필요 없음
              return value;

            } else if (dashboardDateValue === 'week') {
              // "week"의 경우 날짜만 표시]-
              return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
            } else if (dashboardDateValue === 'month') {
              // "month"의 경우 날짜만 표시
              return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
            } else if (dashboardDateValue === 'custom') {
              const startDate = new Date(Chart_startdate);
              const endDate = new Date(Chart_enddate);
              if (endDate - startDate <= 24 * 60 * 60 * 1000) {
                // "custom"의 경우 24시간 이내에는 시간만 표시
                return new Date(value).toLocaleTimeString([], { hour: '2-digit' });
              } else {
                // "custom"의 경우 24시간 이상이면 날짜만 표시
                return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
              }
            }
            return value; // 기본값
          },
          interval: 0, // 모든 데이터를 대상으로 formatter 적용
          show: true, // 항상 레이블 표시
          // rotate: dashboardDateValue === 'month' ? 45 : 0
          rotate: dashboardDateValue === 'custom' && chartData.timeData.length >= 22
            ? 45
            : dashboardDateValue === 'month'
              ? 45
              : 0 // 기본값
        },
        axisLine: { show: true }, // x축 선은 항상 표시
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        splitLine: { show: true },
        axisLabel: {
          formatter: function (value) {
            if (value >= 1e9) {
              return value / 1e9 + 'GB';
            } else if (value >= 1e6) {
              return value / 1e6 + 'MB';
            } else if (value >= 1e3) {
              return value / 1e3 + 'kB';
            } else {
              return value + 'B';
            }
          }
        }
      },
      series: [
        {
          name: '성공',
          type: 'bar',
          stack: '처리량', // 성공 위에 쌓이도록 설정

          data: isDataEmpty ? [] : chartData.successData,
          itemStyle: { color: '#6abf40' }
        },
        {
          name: '실패',
          type: 'bar',
          stack: '처리량', // 성공 위에 쌓이도록 설정
          data: isDataEmpty
            ? []
            : chartData.failData,
          // : chartData.timeData.map((time, index) => ({
          //   value:
          //     failDataAdjusted[index] !== null
          //       ? failDataAdjusted[index]
          //       : 0,
          //   itemStyle: { color: '#ff4d4d' }
          // }))
          itemStyle: { color: '#ff4d4d' }

        },
        {
          name: '실패 마커',
          type: 'scatter',
          symbol: 'pin',
          symbolSize: 40,
          label: {
            show: true,
            formatter: '실패',
            position: 'inside',
            color: '#fff',
            fontSize: 10
          },
          data: isDataEmpty
            ? []
            : chartData.timeData
              .map((time, index) => {
                // 실패 데이터가 0이거나 null이면 마커 추가하지 않음
                const failValue = chartData.failData[index];
                if (failValue !== null && failValue > 0) {
                  return [
                    time,
                    chartData.successData[index] + failDataAdjusted[index]
                  ];
                }
                return null;
              })
              .filter(item => item), // null 값 필터링
          itemStyle: { color: '#ff4d4d' }
        }
      ]
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    const handleResize = () => chart.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [chartData]);

  return (
    <Card className="h-md-100">
      <Card.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h5 style={{ margin: 0 }}>암복호화 처리량</h5>
          <span
            style={{
              marginLeft: '8px',
              marginTop: 0,
              marginBottom: 0,
              padding: '2px 8px',
              // backgroundColor: '#A1C4E3', // 배경색 파란색
              backgroundColor: '#2C7BE5',
              color: 'white', // 텍스트 색상 흰색
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            {dashboardDate}
          </span>
        </div>
        <ButtonGroup>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
          >
            SDK
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="center">
        <div
          id="main"
          ref={chartRef}
          style={{
            width: '100%',
            maxWidth: '1200px',
            height: '15rem',
            margin: '0 auto'
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default ChartOptions;
