import React, { useState } from 'react';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faClock, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BillingTable from './BillingTable';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent
]);

// 더미 데이터
const DUMMY_BILLING_DATA = {
    summary: {
        currentMonth: '2025년 1월',
        totalAmount: '1,234,000',
        previousMonth: '1,123,000',
        percentageChange: 9.8,
        isIncrease: true,
        forecast: '1,300,000'
    },
    details: [
        {
            date: '2025-01-23',
            service: 'API 호출',
            usage: '12,345',
            amount: '98,760'
        },
        {
            date: '2025-01-22',
            service: '암호화 키 관리',
            usage: '8,234',
            amount: '65,872'
        },
        {
            date: '2025-01-21',
            service: 'API 호출',
            usage: '15,678',
            amount: '125,424'
        },
        {
            date: '2025-01-20',
            service: '데이터 저장',
            usage: '256GB',
            amount: '51,200'
        },
        {
            date: '2025-01-19',
            service: 'API 호출',
            usage: '9,876',
            amount: '79,008'
        },
        {
            date: '2025-01-18',
            service: '암호화 키 관리',
            usage: '7,654',
            amount: '61,232'
        },
        {
            date: '2025-01-17',
            service: 'API 호출',
            usage: '11,234',
            amount: '89,872'
        },
        {
            date: '2025-01-16',
            service: '데이터 저장',
            usage: '312GB',
            amount: '62,400'
        },
        {
            date: '2025-01-15',
            service: 'API 호출',
            usage: '13,456',
            amount: '107,648'
        },
        {
            date: '2025-01-14',
            service: '암호화 키 관리',
            usage: '6,789',
            amount: '54,312'
        },
        {
            date: '2025-01-13',
            service: 'API 호출',
            usage: '10,987',
            amount: '87,896'
        },
        {
            date: '2025-01-12',
            service: '데이터 저장',
            usage: '178GB',
            amount: '35,600'
        },
        {
            date: '2025-01-11',
            service: 'API 호출',
            usage: '14,321',
            amount: '114,568'
        },
        {
            date: '2025-01-10',
            service: '암호화 키 관리',
            usage: '5,432',
            amount: '43,456'
        },
        {
            date: '2025-01-09',
            service: 'API 호출',
            usage: '12,789',
            amount: '102,312'
        },
        {
            date: '2025-01-08',
            service: '데이터 저장',
            usage: '145GB',
            amount: '29,000'
        },
        {
            date: '2025-01-07',
            service: 'API 호출',
            usage: '9,876',
            amount: '79,008'
        },
        {
            date: '2025-01-06',
            service: '암호화 키 관리',
            usage: '4,567',
            amount: '36,536'
        },
        {
            date: '2025-01-05',
            service: 'API 호출',
            usage: '11,234',
            amount: '89,872'
        },
        {
            date: '2025-01-04',
            service: '데이터 저장',
            usage: '98GB',
            amount: '19,600'
        },
        {
            date: '2025-01-03',
            service: 'API 호출',
            usage: '8,765',
            amount: '70,120'
        },
        {
            date: '2025-01-02',
            service: '암호화 키 관리',
            usage: '3,456',
            amount: '27,648'
        },
        {
            date: '2025-01-01',
            service: 'API 호출',
            usage: '5,432',
            amount: '43,456'
        }
    ]
};

// 차트 데이터 추가
const chartData = {
    months: ['2024-08', '2024-09', '2024-10', '2024-11', '2024-12', '2025-01'],
    values: [980000, 1050000, 1150000, 1080000, 1123000, 1234000]
};

const getChartOptions = (data) => ({
    tooltip: {
        trigger: 'axis',
        formatter: (params) => {
            const value = params[0].value.toLocaleString();
            return `${params[0].name}<br/>청구 금액: ₩${value}`;
        }
    },
    grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        top: '5%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: data.months,
        boundaryGap: false,
        axisLine: {
            lineStyle: {
                color: '#E5E5E5'
            }
        }
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            formatter: (value) => `₩${(value / 10000).toFixed(0)}만`
        },
        splitLine: {
            lineStyle: {
                color: '#E5E5E5'
            }
        }
    },
    series: [
        {
            data: data.values,
            type: 'line',
            smooth: true,
            lineStyle: {
                width: 3
            },
            itemStyle: {
                color: '#2C7BE5'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgba(44, 123, 229, 0.3)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(44, 123, 229, 0)'
                    }
                ])
            }
        }
    ]
});

const BillingHistory = () => {
    const [billingData, setBillingData] = useState(DUMMY_BILLING_DATA);
    const [loading, setLoading] = useState(false);

    return (
        <div className="container mt-5">
            {/* 비용 요약 섹션 */}
            <Row className="g-3 mb-4">
                <Col md={4}>
                    <Card className="h-100 mb-3">
                        <Card.Body className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="text-600">이번 달 총 비용</h6>
                                    <h4 className="mb-0">₩{billingData.summary.totalAmount}</h4>
                                    <Badge bg={billingData.summary.isIncrease ? 'warning' : 'success'} className="mt-2">
                                        <FontAwesomeIcon
                                            icon={billingData.summary.isIncrease ? 'caret-up' : 'caret-down'}
                                            className="me-1"
                                        />
                                        {billingData.summary.percentageChange}%
                                    </Badge>
                                </Col>
                                <Col xs="auto">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" className="text-primary" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100 mb-3">
                        <Card.Body className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="text-600">예상 청구 금액</h6>
                                    <h4 className="mb-0">₩{billingData.summary.forecast}</h4>
                                    <p className="fs--1 text-600 mt-2">
                                        {billingData.summary.currentMonth} 예상
                                    </p>
                                </Col>
                                <Col xs="auto">
                                    <FontAwesomeIcon icon={faChartLine} size="2x" className="text-warning" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100 mb-3">
                        <Card.Body className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="text-600">지난달 청구 금액</h6>
                                    <h4 className="mb-0">₩{billingData.summary.previousMonth}</h4>
                                    <p className="fs--1 text-600 mt-2">최종 정산 완료</p>
                                </Col>
                                <Col xs="auto">
                                    <FontAwesomeIcon icon={faClock} size="2x" className="text-info" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* 차�� 섹션 추가 */}
            <Card className="mb-3">
                <Card.Header className="bg-light py-2">
                    <h5 className="mb-0">최근 6개월간 요금 청구량</h5>
                </Card.Header>
                <Card.Body>
                    <div style={{ height: '300px' }}>
                        <ReactEChartsCore
                            echarts={echarts}
                            option={getChartOptions(chartData)}
                            style={{ height: '100%' }}
                        />
                    </div>
                </Card.Body>
            </Card>

            {/* 상세 내역 테이블 */}
            <Card className="mb-3">
                <Card.Header className="bg-light py-2">
                    <h5 className="mb-0">상세 청구 내역</h5>
                </Card.Header>
                <Card.Body className="p-0">
                    <BillingTable data={billingData.details} loading={loading} />
                </Card.Body>
            </Card>
        </div>
    );
};

export default BillingHistory; 