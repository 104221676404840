import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';

import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillCloseCircle
} from 'react-icons/ai';
import {
  handleValueDelete,
  goToParentPath,
  formatDate
} from 'components/common/SunnyUtils';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { toast } from 'react-toastify';
import GroupManagementModal from 'components/modal/GroupManagementModal';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import {
  SelectGroupList,
  fetchCompanyInfo,
  updateApiKeyInfo,
  remakeApiKeyInfo,
  deleteApiKeyInfo
} from 'hooks/CallApi'; // API 함수 임포트
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
const editapikey = ({ setValue, handleClose }) => {
  const navigate = useNavigate();
  const {
    config: { userAuth_K, userId, checkorg },
    setConfig
  } = useAppContext();
  const [modalShow, setModalShow] = useState(false);
  const [deleteCheckShow, setDeleteCheckShow] = useState(false);
  const [isRemakeSelected, setIsRemakeSelected] = useState(false);
  const [isModified, setIsModified] = useState(false); // 변경 상태 추적
  const [showToast, setShowToast] = useState(false); // 토스트 상태
  const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
  const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가
  const [groups, setGroups] = useState([]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const [ConfirmCheckModalShow, setConfirmCheckModalShow] = useState(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const location = useLocation();
  const {
    createdAt,
    invalidAt,
    remakeAt,
    lastuseAt,
    apikeyStatus,
    status,
    group,
    keyname,
    role,
    desc,
    keyuuid,
    keycompanyId,
    adderId,
    adderRole,
    apikey,
    apiGroupUuid
  } = location.state || {}; // 추가 데이터 포함

  // API 호출 후 데이터 확인
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await SelectGroupList();
        setGroups(response.data); // 데이터가 response.data에 있을 경우
      } catch (error) {
        console.error('그룹 목록 불러오기 실패:', error);
      }
    };
    fetchGroups();
  }, []);
  const handleSubmitClick = e => {
    e.preventDefault(); // 기본 제출 방지
    setConfirmModalShow(true); // 확인 모달 열기
  };

  const handleGroupModalShow = () => {
    setShowGroupModal(true); // 모달 열기
  };

  const handleGroupModalHide = () => {
    setShowGroupModal(false); // 모달 닫기
  };

  const handleCheckOutModal = () => {
    setModalShow(false); // 관리자 모달 닫기
  };
  const handleGroupModalConfirm = () => {
    setShowGroupModal(false);
    setShowGroupAddModal(true);
  };
  const handleGroupAddModalShow = () => {
    setShowGroupAddModal(true); // 모달 열기
  };

  const handleGroupAddModalHide = () => {
    setShowGroupModal(false); // 모달 닫기
  };
  const handleGroupAddModalConfirm = () => {
    setShowGroupAddModal(false);
  };
  const handleDeleteClick = () => {
    setModalShow(true); // 모달 열기
  };

  //

  //
  const handleConfirmSubmit = () => {
    setConfirmModalShow(false);
    handleSubmit();
  };

  const [pwType, setpwType] = useState({
    type: 'password',
    visible: false
  });
  const handlePasswordType = e => {
    setpwType(() => {
      // 만약 현재 pwType.visible이 false 라면
      if (!pwType.visible) {
        return { type: 'text', visible: true };

        //현재 pwType.visible이 true 라면
      } else {
        return { type: 'password', visible: false };
      }
    });
  };

  const [formData, setFormData] = useState({
    createdAt: createdAt,
    group: group,
    invalidDate: invalidAt,
    keyname: keyname,
    role: role,
    description: desc,
    adderRole:
      adderRole === 'SA'
        ? '사이트 관리자'
        : adderRole === 'ADMIN'
          ? '최고 관리자'
          : '운영 관리자', // 조건에 따른 값 설
    adderId: adderId,
    accesskey: apikey,
    status: apikeyStatus,
    keyuuid: keyuuid,
    keycompanyId: keycompanyId,
    lastuseDate: lastuseAt,
    remakeDate: remakeAt,
    apiGroupUuid: apiGroupUuid,
    imgUrl: ''
  });
  const formatDateTimeForInput = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatDateTimeForDisplay = dateString => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // UTC를 로컬로 변환

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  };


  // const formatDateTimeForDisplay = dateString => {
  //   if (!dateString) return '-';
  //   if (dateString === '-') return '-';
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const seconds = String(date.getSeconds()).padStart(2, '0');

  //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  // };

  // 회사이미지 불러오기용
  useEffect(() => {
    const loadCompanyInfo = async () => {
      try {
        const response = await fetchCompanyInfo(formData.keycompanyId);
        const data = response.data;

        setFormData(prevState => ({
          ...prevState,
          imgUrl: data.imgUrl
        }));
      } catch (error) {
        console.error('회사 정보 불러오기 에러:', error);
      }
    };

    if (formData.keycompanyId) {
      loadCompanyInfo();
    } else {
      // toast.error('유효하지 않은 회사 ID입니다.');
      alert('유효하지 않은 회사 ID입니다.');

    }
  }, []);

  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([{ src: '' }]); // 초기값 설정
  // 초기 상태 설정
  useEffect(() => {
    const initialAvatar =
      formData.imgUrl && formData.imgUrl.trim() !== ''
        ? formData.imgUrl
        : avatarImg;

    // setavatarImg(initialAvatar); // 기본 이미지 설정
    setAvatar([{ src: initialAvatar }]); // Avatar 상태 업데이트
  }, [formData.imgUrl]); // imgUrl이나 userAuth 변경 시 실행

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setIsModified(true); // 필드가 변경되면 상태 업데이트
  };

  const handleComboChange = ({ target }) => {
    const { name, value } = target;

    setFormData(prev => ({
      ...prev,
      [name]: name === 'status' ? (value === 'true' ? true : false) : value // 'status' 필드가 true/false boolean 값으로 업데이트
    }));
    setIsModified(true); // 필드가 변경되면 상태 업데이트
  };

  const handleSubmit = async e => {
    e.preventDefault();
    // 등록 과정 추가 필요

    try {
      const response = await updateApiKeyInfo(keyuuid, {
        apikeyName: formData.keyname,
        apiGroupName: formData.group,
        apiGroupUuid: formData.apiGroupUuid,
        apikeyType: formData.role,
        apikeyStatus: formData.status, // 문자열을 불리언으로 변환
        apikeyDescription: formData.description
      });

      if (response) {
        setConfirmModalShow(false);
        setConfirmCheckModalShow(true);
        // navigate(`/safedb/common/apikey`);
      } else {
        // toast.error('인증키 정보 업데이트가 실패했습니다.'); // Toast Notification 추가
        alert('인증키 정보 업데이트가 실패했습니다.');
      }
    } catch (error) {
      // console.error('인증키  정보 업데이트 에러:', error);
      // toast.error('인증키  정보 업데이트 중 오류가 발생했습니다.');
      alert('인증키 정보 업데이트 중 오류가 발생했습니다.');
    }
  };
  const handleRemakeClick = async () => {
    // setIsRemakeSelected(prev => !prev); // Toggle selected state
    setIsRemakeSelected(true);

    // 재발급 과정정
    try {
      const response = await remakeApiKeyInfo(keyuuid);
      if (response) {
        setFormData(prevFormData => ({
          ...prevFormData,
          accesskey: response.data.apiKey, // 새로운 accessKey로 업데이트
          // remakeDate: response.data.reissuanceTimeUtc
          //   ? formatDate(response.data.reissuanceTimeUtc) // 포맷 함수 호출
          //   : '-'
          remakeDate: response.data.reissuanceTimeUtc
            ? response.data.reissuanceTimeUtc // 포맷 함수 호출
            : '-'

        }));
      } else {
        console.log('remkae API 가 실패했습니다.'); // Toast Notification 추가

      }
    } catch (error) {

      console.log('remkae API  중 오류가 발생했습니다.');
    }
  };

  const handleCheckOutModalConfirm = async () => {
    setModalShow(false);
    try {
      await deleteApiKeyInfo(keyuuid); // 삭제 API 호출
      setDeleteCheckShow(true);

    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
    }
  };
  const isFormValid = () => {
    const { group, keyname, accesskey, description } = formData;
    return (
      (isModified || isRemakeSelected) &&
      (group || keyname || accesskey || description)
    );
  };
  const handleFinalConfirm = () => {
    setConfirmCheckModalShow(false);
    navigate('/safedb/common/key/apikey');
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          인증키
        </Card.Header>

        <Card.Title className=" px-3 pt-3"> 상세 정보</Card.Title>
        <Card.Body>
          <Form onSubmit={handleSubmitClick}>
            <Row>
              <Col md={4} className="text-center profile-section">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto'
                  }}
                >
                  <Flex justifyContent="center">
                    <img
                      src={avatar.length > 0 ? avatar[0].src : avatar[0].src}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'contain' // 이미지 비율 유지
                      }}
                    />
                  </Flex>
                </div>
                <p className="mt-3">[{checkorg}]</p> {/* @@ 여기 @@*/}
              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        // type="datetime-local"
                        type="text"
                        name="addDate"
                        value={formatDateTimeForDisplay(formData.createdAt)}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>만료 일시</Form.Label>
                      <Form.Control
                        // type={formData.invalidDate ? "datetime-local" : "text"}
                        type="text"
                        name="lastuseDate"
                        value={
                          formData.invalidDate
                            ? formatDateTimeForDisplay(formData.v)
                            : '-'
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>발급자 역할</Form.Label>
                      <Form.Control
                        type="text"
                        name="adderRole"
                        value={formData.adderRole}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>발급자 아이디</Form.Label>
                      <Form.Control
                        type="text"
                        name="adderId"
                        value={formData.adderId}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        인증키 그룹<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div
                        style={{
                          display: 'flex',
                          gap: '4px'
                        }}
                      >
                        <Form.Select
                          name="group"
                          onChange={handleComboChange}
                          placeholder="그룹을 선택해주세요."
                          value={formData.group}
                        >
                          {groups.map(group => (
                            <option
                              key={group.apigroupuuid}
                              value={group.groupName}
                            >
                              {group.groupName}
                            </option>
                          ))}
                        </Form.Select>
                        <Button
                          type="button"
                          variant="primary"
                          style={{
                            minWidth: 'max-content'
                          }}
                          onClick={handleGroupModalShow}
                        >
                          그룹 등록
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>상태</Form.Label>
                      <Form.Select
                        name="status"
                        onChange={handleComboChange}
                        value={formData.status ? 'true' : 'false'} // boolean을 string으로 변환
                      >
                        <option value="true">정상</option>
                        <option value="false">만료</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        인증키 이름<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type="text"
                          name="keyname"
                          value={formData.keyname}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="API 인증키 별칭을 입력해 주세요.."
                        />

                        {formData.keyname && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'keyname',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>용도</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        value={formData.role}
                        disabled
                      >
                        <option value="API">API</option>
                        <option value="CF">CF</option>
                        <option value="SDK">SDK</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>Access Key</Form.Label>
                      <div
                        className="d-flex align-items-center"
                        style={{ position: 'relative' }}
                      >
                        <Form.Control
                          type="text"
                          name="accesskey"
                          value={formData.accesskey}
                          onChange={handleChange}
                          readOnly
                          disabled
                        />
                        {/* {formData.accesskey && (
                                                    <span
                                                        onClick={handlePasswordType}
                                                        style={{
                                                            cursor: 'pointer',
                                                            position: 'absolute',
                                                            right: '6rem', // 아이콘 위치 조정
                                                            top: '50%',
                                                            transform: 'translateY(-50%)' // 수직 중앙 정렬
                                                        }}
                                                    >
                                                        {pwType.visible ? <AiFillEye /> : <AiFillEyeInvisible />}
                                                    </span>
                                                )} */}
                        <Button
                          variant={
                            isRemakeSelected ? 'outline-primary' : 'primary'
                          }
                          style={{
                            fontSize: '1rem',
                            padding: '0.3rem 0.9rem',
                            marginLeft: '10px', // Access Key 필드와 버튼 간격 조정
                            whiteSpace: 'nowrap'
                          }}
                          onClick={handleRemakeClick}
                        >
                          재발급
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>재발급일시</Form.Label>

                      <Form.Control
                        // type={formData.remakeDate ? "datetime-local" : "text"}
                        type="text"
                        name="lastuseDate"
                        value={
                          formData.remakeDate
                            ? formatDateTimeForDisplay(formData.remakeDate)
                            : '-'
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>최근사용일시</Form.Label>
                      <Form.Control
                        // type={formData.lastuseDate ? "datetime-local" : "text"}
                        type="text"
                        name="lastuseDate"
                        value={
                          formData.lastuseDate
                            ? formatDateTimeForDisplay(formData.lastuseDate)
                            : '-'
                        }
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <div style={{ position: 'relative' }}>
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows={1}
                      value={formData.description}
                      onChange={handleChange}
                      className="input-hover"
                      placeholder="키의 용도, 사용할 부서 또는 시스템을 입력할 수 있습니다. (예: 마케팅 부서, pod-001 복호화 전용)"
                    />

                    {formData.description && (
                      <span
                        onClick={e =>
                          handleValueDelete(
                            'description',
                            formData,
                            setFormData
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 9
                        }}
                      >
                        <AiFillCloseCircle />
                      </span>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="outline-danger"
                style={{ width: '7rem' }}
                onClick={handleDeleteClick}
              >
                삭제
              </Button>
              <Button
                variant="primary"
                type="submit"
                style={{ width: '7rem' }}
                disabled={!isFormValid()}
              >
                변경
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModalConfirm}
        title="경고" // 제목
        body={'인증키는 다시 복구할 수 없습니다. 정말 삭제하시겠습니까?'}
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        SubmitButton={true}
      />

      <CheckOutModal
        show={deleteCheckShow}
        onHide={() => setDeleteCheckShow(false)}
        onConfirm={() => {
          setDeleteCheckShow(false);
          navigate('/safedb/common/key/apikey');
        }}
        title="완료" // 제목
        body={'삭제가 완료 되었습니다.'}
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        onConfirm={handleSubmit}
        title="경고"
        variant="warning"
        body="인증키 정보를 변경하시겠습니까?"
        cancelText="취소"
        confirmText="확인"
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={ConfirmCheckModalShow}
        onHide={() => setConfirmCheckModalShow(false)}
        onConfirm={handleFinalConfirm}
        title="완료"
        variant="success"
        body="변경이 완료 되었습니다."
        confirmText="확인"
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />

      {/* <GroupManagementModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            /> */}
      <GroupAddModal
        show={showGroupModal}
        onHide={handleGroupAddModalHide}
        onConfirm={handleGroupAddModalConfirm}
      />
    </div>
  );
};

export default editapikey;
