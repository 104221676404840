import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { fetchEncPolicyList, updateEncryptKeyPolicy } from 'hooks/CallApi';
import { toast } from 'react-toastify';
import { formatDate } from 'components/common/SunnyUtils';

const encryptKeyPolicyModal = ({ show, onHide, onConfirm, policyUuid }) => {
    // Table columns 설정
    const columns = [
        {
            accessorKey: 'status',
            header: '상태',
            style: { width: '4rem', minWidth: '4rem', maxWidth: '4rem' }
        },
        {
            accessorKey: 'group',
            header: '그룹명',
            style: { width: '5rem', minWidth: '5rem', maxWidth: '5rem' }
        },
        {
            accessorKey: 'keyname',
            header: '인증키 이름',
            style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        },
        {
            accessorKey: 'role',
            header: '용도',
            style: { width: '5rem', minWidth: '5rem', maxWidth: '5rem' }
        },
        {
            accessorKey: 'aboutEnc',
            header: '암호화 권한',
            style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        },
        {
            accessorKey: 'aboutDec',
            header: '복호화 권한',
            style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        },
        {
            accessorKey: 'masking',
            header: '마스킹',
            style: { width: '5rem', minWidth: '5rem', maxWidth: '5rem' }
        },
        {
            accessorKey: 'createdAt',
            header: '등록일시',
            style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        },
        {
            accessorKey: 'invalidAt',
            header: '만료일시',
            style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        },
        {
            accessorKey: 'lastUsedAt',
            header: '최근 사용 일시',
            style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        }
    ];


    // 적용 가능한 인증키 목록 데이터와 적용 대상 인증키 목록 데이터
    const [availableKeys, setAvailableKeys] = useState([]); // 서버에서 불러온 데이터 사용
    const [targetKeys, setTargetKeys] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
    const [selectedAvailableRows, setSelectedAvailableRows] = useState([]);
    const [selectedTargetRows, setSelectedTargetRows] = useState([]);
    const [isDragging, setIsDragging] = useState(false); // 드래그 상태 
    const [movedToTargetKeys, setMovedToTargetKeys] = useState([]); // 새로 추가된 상태
    const [draggingFromAvailable, setDraggingFromAvailable] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
    const [pageSize, setPageSize] = useState(10); // 페이지 크기
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
    const [isChanged, setIsChanged] = useState(false); // 변경 상태를 관리하는 새로운 상태
    const [initialAvailableKeys, setInitialAvailableKeys] = useState([]);
    const [initialTargetKeys, setInitialTargetKeys] = useState([]);

    useEffect(() => {
        const loadAllData = async () => {
            let allData = [];

            setLoading(true);

            // 300ms 후에 스피너를 표시하는 타이머 설정
            const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

            try {
                // const data = await fetchEncPolicyList(policyUuid);
                const initialData = await fetchEncPolicyList(policyUuid, 0, 1);
                const totalElements = initialData.data.page.totalElements || 1; // totalElements가 0이면 1로 설정 - 0이면 에러남
                const fullData = await fetchEncPolicyList(policyUuid, 0, totalElements);

                const content = fullData.data.content.map((item, index) => ({
                    ...item,
                    num: index + 1, // 순번 추가
                    status: item.state ? "정상" : "만료",
                    state: item.state,
                    group: item.groupName,
                    keyname: item.apiKeyName,
                    apikeyuuid: item.apiKeyUuid,
                    role: item.apiKeyType,
                    aboutEnc: item.encPermission,
                    aboutDec: item.decPermission,
                    masking: item.maskingEnabled,

                    createdAt: item.inserTimeUtc
                        ? formatDate(item.inserTimeUtc) // 포맷 함수 호출
                        : '-',
                    invalidAt: item.expiredTimeUtc
                        ? formatDate(item.expiredTimeUtc) // 포맷 함수 호출
                        : '-',
                    lastUsedAt: item.lastUsedTimeUtc
                        ? formatDate(item.lastUsedTimeUtc) // 포맷 함수 호출
                        : '-',
                    selected: item.selected
                }));

                const removeDuplicates = (data, key) => {
                    return data.filter(
                        (item, index, self) => index === self.findIndex((t) => t[key] === item[key])
                    );
                };

                // 고유 데이터 필터링
                const uniqueAvailableKeys = removeDuplicates(
                    content.filter((item) => item.selected === "0"),
                    "apikeyuuid"
                );
                const uniqueTargetKeys = removeDuplicates(
                    content.filter((item) => item.selected === "1"),
                    "apikeyuuid"
                );

                // setAvailableKeys(uniqueAvailableKeys);
                // setTargetKeys(uniqueTargetKeys);
                setAvailableKeys(ensureMinimumRows(uniqueAvailableKeys, 5)); // 최소 5줄 보장
                setTargetKeys(ensureMinimumRows(uniqueTargetKeys, 5));      // 최소 5줄 보장
                setInitialAvailableKeys(uniqueAvailableKeys); // 초기 상태 저장
                setInitialTargetKeys(uniqueTargetKeys); // 초기 상태 저장
            } catch (error) {
                console.error('데이터 불러오기 에러:', error);
            } finally {
                clearTimeout(spinnerTimer);
                setLoading(false);
                setShowSpinner(false);

            }
        };

        if (show) { // 모달이 열릴 때만 데이터 로드
            loadAllData();
        }
    }, [show, policyUuid]);




    const availableTable = useAdvanceTable({
        data: availableKeys,
        columns,
        selection: true,
        perPage: 5
    });

    const targetTable = useAdvanceTable({
        data: targetKeys,
        columns,
        selection: true,
        perPage: 5
    });

    useEffect(() => {
        // 실제 데이터만 추출 (빈 행 제외)
        const filteredAvailableKeys = availableKeys.filter((key) => key.apikeyuuid);
        const filteredTargetKeys = targetKeys.filter((key) => key.apikeyuuid);

        // 정렬된 배열로 비교 (순서 무시)
        const sortByUuid = (a, b) => a.apikeyuuid.localeCompare(b.apikeyuuid);

        const sortedAvailableKeys = [...filteredAvailableKeys].sort(sortByUuid);
        const sortedTargetKeys = [...filteredTargetKeys].sort(sortByUuid);

        const sortedInitialAvailableKeys = [...initialAvailableKeys].sort(sortByUuid);
        const sortedInitialTargetKeys = [...initialTargetKeys].sort(sortByUuid);

        // 초기 상태와 현재 상태 비교
        const hasChanged =
            JSON.stringify(sortedAvailableKeys) !== JSON.stringify(sortedInitialAvailableKeys) ||
            JSON.stringify(sortedTargetKeys) !== JSON.stringify(sortedInitialTargetKeys);

        setIsChanged(hasChanged);
    }, [availableKeys, targetKeys, initialAvailableKeys, initialTargetKeys]);

    const [dragStartIndex, setDragStartIndex] = useState(null); // 드래그 시작 인덱스 저장


    const handleDragStart = (startIndex, isAvailableTable) => {
        setIsDragging(true); // 드래그 상태 활성화
        setDragStartIndex(startIndex); // 드래그 시작 인덱스 저장
        setDraggingFromAvailable(isAvailableTable); // 어떤 테이블에서 드래그 시작했는지 저장
    };

    const handleDragEnd = () => {
        setIsDragging(false); // 드래그 상태 비활성화
        setDragStartIndex(null); // 드래그 시작 인덱스 초기화
        setDraggingFromAvailable(null); // 드래그 출발 테이블 초기화
    };
    const handleDragSelect = (currentIndex, isAvailableTable) => {
        if (!isDragging || draggingFromAvailable !== isAvailableTable) return;

        const start = Math.min(dragStartIndex, currentIndex);
        const end = Math.max(dragStartIndex, currentIndex);

        if (isAvailableTable) {
            const updatedRows = Array.from(new Set([...selectedAvailableRows, ...Array.from({ length: end - start + 1 }, (_, i) => start + i)]));
            setSelectedAvailableRows(updatedRows);
        } else {
            const updatedRows = Array.from(new Set([...selectedTargetRows, ...Array.from({ length: end - start + 1 }, (_, i) => start + i)]));
            setSelectedTargetRows(updatedRows);
        }
    };

    const handleRowClickToggle = (rowIndex, isAvailableTable) => {
        if (isAvailableTable) {
            setSelectedAvailableRows(prev =>
                prev.includes(rowIndex)
                    ? prev.filter(index => index !== rowIndex) // 이미 선택된 경우 해제
                    : [...prev, rowIndex] // 선택되지 않은 경우 추가
            );
        } else {
            setSelectedTargetRows(prev =>
                prev.includes(rowIndex)
                    ? prev.filter(index => index !== rowIndex) // 이미 선택된 경우 해제
                    : [...prev, rowIndex] // 선택되지 않은 경우 추가
            );
        }
    };

    const handleMoveToTarget = () => {
        const itemsToMove = selectedAvailableRows.map(index => availableKeys[index]);


        // 선택된 항목 이동 및 상태 업데이트
        const newAvailableKeys = availableKeys.filter((_, index) => !selectedAvailableRows.includes(index));
        const newTargetKeys = [...targetKeys, ...itemsToMove];

        setAvailableKeys(ensureMinimumRows(newAvailableKeys, 5)); // 최소 5줄 보장
        setTargetKeys(ensureMinimumRows(newTargetKeys, 5));      // 최소 5줄 보장
        setSelectedAvailableRows([]); // 선택된 항목 초기화

    };

    const handleMoveToAvailable = () => {
        const itemsToMove = selectedTargetRows.map(index => targetKeys[index]);

        // 선택된 항목 이동 및 상태 업데이트
        const newTargetKeys = targetKeys.filter((_, index) => !selectedTargetRows.includes(index));
        const newAvailableKeys = [...availableKeys, ...itemsToMove];

        setTargetKeys(ensureMinimumRows(newTargetKeys, 5));      // 최소 5줄 보장
        setAvailableKeys(ensureMinimumRows(newAvailableKeys, 5)); // 최소 5줄 보장
        setSelectedTargetRows([]); // 선택된 항목 초기화

    };
    const handleConfirm = () => {
        const filteredTargetKeys = targetKeys.filter((key) =>
            key.status && key.group && key.keyname && key.role
        );

        const apiKeyPolicies = filteredTargetKeys.map((key) => ({
            apikeyUuid: key.apikeyuuid, // apikeyuuid 추가
            status: key.status,
            group: key.group,
            keyname: key.keyname,
            role: key.role,
            aboutEnc: key.aboutEnc ? 'O' : 'X',
            aboutDec: key.aboutDec ? 'O' : 'X',
            masking: key.masking ? '설정' : '미설정',
            createdAt: key.createdAt,
            invalidAt: key.invalidAt,
            lastUsedAt: key.lastUsedAt
        }));
        onConfirm(apiKeyPolicies);
        setIsChanged(false); // 변경 적용 후 비활성화

        setSelectedAvailableRows([]);
        setSelectedTargetRows([]);


    };

    const ensureMinimumRows = (data, minRows) => {
        const actualData = data.filter(row => row.status || row.group || row.keyname); // 실제 데이터 필터링
        const emptyRows = Array.from({ length: Math.max(0, minRows - actualData.length) }, () => ({
            status: '',
            group: '',
            keyname: '',
            role: '',
            aboutEnc: '',
            aboutDec: '',
            masking: '',
            createdAt: '',
            invalidAt: '',
            lastUsedAt: '',
        }));
        return [...actualData, ...emptyRows];
    };


    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>인증키 설정</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ userSelect: 'none' }}>
                <Row>
                    <Col>
                        <h5>적용 가능한 인증키 목록</h5>
                        <div
                            style={{
                                maxHeight: '250px', // 원하는 최대 높이 설정
                                overflowY: 'auto'   // 스크롤이 필요할 경우만 스크롤바 표시
                            }}
                        >
                            <table className="table table-responsive fs--1 mb-0 overflow-hidden"
                                onMouseDown={() => setIsDragging(true)}
                                onMouseUp={() => setIsDragging(false)}
                                style={{
                                    maxHeight: '100%',
                                    // tableLayout: 'fixed',
                                    width: '100%'
                                }} // 높이를 고정하고 스크롤 활성화

                            >
                                <thead className="bg-200 text-nowrap align-middle">
                                    <tr>
                                        {columns.map(col => (
                                            <th
                                                key={col.accessorKey}
                                                className="fs-10"
                                                style={col.style}
                                            >
                                                {col.header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody
                                    className='fs-11'
                                    onMouseUp={handleDragEnd} // 드래그 종료를 테이블 전체에서 감지
                                >
                                    {availableKeys.map((item, index) => (
                                        <tr
                                            key={index}

                                            className={`align-middle ${item.status === '만료' ? 'text-muted' : ''} ${selectedAvailableRows.includes(index) ? 'bg-primary-subtle' : ''}`}

                                            onClick={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 클릭 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleRowClickToggle(index, true); // 선택 토글
                                                }
                                            }}
                                            onMouseEnter={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 드래그 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleDragSelect(index, true); // 드래그 선택
                                                }
                                            }}
                                            onMouseDown={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 드래그 시작 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleDragStart(index, true);
                                                }
                                            }}
                                            // onMouseUp={handleDragEnd} // 드래그 종료
                                            style={{
                                                height: '42px',
                                                opacity: item.status === '만료' ? 0.5 : 1, // 상태에 따라 글씨 흐리게 적용
                                            }}
                                        >
                                            <td>{item.status}</td>
                                            <td>{item.group}</td>
                                            <td>{item.keyname}</td>
                                            <td>{item.role}</td>
                                            <td>{item.aboutEnc === true ? 'O' : item.aboutEnc === false ? 'X' : ''}</td>
                                            <td>{item.aboutDec === true ? 'O' : item.aboutDec === false ? 'X' : ''}</td>
                                            {/* <td>{item.aboutDec ? "O" : "X"}</td> */}
                                            {/* <td>{item.masking ? "설정" : "미설정"}</td> */}
                                            <td>{item.masking === true ? '설정' : item.masking === false ? '미설정' : ''}</td>

                                            <td>{item.createdAt}</td>
                                            <td>{item.invalidAt}</td>
                                            <td>{item.lastUsedAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="pt-3 d-flex flex-column align-items-center justify-content-center">
                        <Col>
                            <Button variant="primary" onClick={handleMoveToTarget} className="mx-2">∨</Button>

                            <Button variant="primary" onClick={handleMoveToAvailable}>∧</Button>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <h5>적용 대상 인증키 목록</h5>
                        <div
                            style={{
                                maxHeight: '250px', // 원하는 최대 높이 설정
                                overflowY: 'auto'   // 스크롤이 필요할 경우만 스크롤바 표시
                            }}
                        >
                            <table className="table table-responsive fs--1 mb-0 overflow-hidden"
                                onMouseDown={() => setIsDragging(true)}
                                onMouseUp={() => setIsDragging(false)}
                                style={{
                                    maxHeight: '100%',
                                    // tableLayout: 'fixed',
                                    width: '100%'
                                }} // 높이를 고정하고 스크롤 활성화

                            >
                                <thead className="bg-200 text-nowrap align-middle">
                                    <tr>
                                        {columns.map(col => (
                                            <th
                                                key={col.accessorKey}
                                                className="fs-10"
                                                style={col.style}
                                            >
                                                {col.header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='fs-11'>
                                    {targetKeys.map((item, index) => (
                                        <tr
                                            key={index}

                                            className={`align-middle ${item.status === '만료' ? 'text-muted' : ''} ${selectedTargetRows.includes(index) ? 'bg-primary-subtle' : ''}`}

                                            onClick={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 클릭 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleRowClickToggle(index, false); // 선택 토글
                                                }
                                            }}
                                            onMouseEnter={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 드래그 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleDragSelect(index, false); // 드래그 선택
                                                }
                                            }}
                                            onMouseDown={() => {
                                                // 빈칸(형태 유지용 ROW)인 경우 드래그 시작 방지
                                                if (item.status || item.group || item.keyname || item.role) {
                                                    handleDragStart(index, false);
                                                }
                                            }}
                                            style={{
                                                height: '42px',
                                                opacity: item.status === '만료' ? 0.5 : 1, // 상태에 따라 글씨 흐리게 적용
                                                cursor: item.status || item.group || item.keyname || item.role ? 'pointer' : 'default', // 빈칸일 경우 포인터 비활성화
                                            }}
                                        >
                                            <td>{item.status}</td>
                                            <td>{item.group}</td>
                                            <td>{item.keyname}</td>
                                            <td>{item.role}</td>
                                            {/* <td>{item.aboutEnc ? "O" : "X"}</td>
                                            <td>{item.aboutDec ? "O" : "X"}</td>
                                            <td>{item.masking ? "설정" : "미설정"}</td> */}
                                            <td>{item.aboutEnc === true ? 'O' : item.aboutEnc === false ? 'X' : ''}</td>
                                            <td>{item.aboutDec === true ? 'O' : item.aboutDec === false ? 'X' : ''}</td>
                                            <td>{item.masking === true ? '설정' : item.masking === false ? '미설정' : ''}</td>

                                            <td>{item.createdAt}</td>
                                            <td>{item.invalidAt}</td>
                                            <td>{item.lastUsedAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>취소</Button>
                <Button variant="primary" onClick={handleConfirm} disabled={!isChanged}>적용</Button>

            </Modal.Footer>
        </Modal >
    );
};

export default encryptKeyPolicyModal;
