import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillCloseCircle } from 'react-icons/ai';
import 'safedb/common/css/IniCustomCss.css';
import { max } from 'd3';

const CustomDateInput = forwardRef(
  (
    { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const WizardInput = ({
  label,
  name,
  errors,
  type = 'text',
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  datepickerProps,
  maxLength,
  handleInputProps,
  inputData,
  handleReset,
  verifiedValue,
  handleChangePrefix,
  selectedPhonePrefix
}) => {
  const [date, setDate] = useState(null);

  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    inputData !== '' ? setIsEmpty(false) : setIsEmpty(true);
  }, [inputData]);

  // const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('');

  // const handleSelectChange = e => {
  //   setSelectedPhonePrefix(e.target.value); // 선택된 값을 상태에 저장
  // };

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}

        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
        // isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }

  if (type === 'switch' || type === 'radio') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Select
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
          maxLength={maxLength} // maxLength 속성을 입력 필드에 적용
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  if (type === 'phone') {
    return (
      <Form.Group {...formGroupProps} style={{ position: 'relative' }}>
        <Form.Label>{label}</Form.Label>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Form.Control
            type="text" // phone 타입일 때 Form.Control은 text로 사용
            placeholder={placeholder}
            {...formControlProps}
            isInvalid={verifiedValue ? false : !!errors[name]}
            isValid={verifiedValue}
            maxLength={maxLength} // maxLength 속성
            onInput={handleInputProps}
            value={inputData}
            style={{ flex: '1 0 84%' }} // width를 flex로 조절
          />
          {!isEmpty && (
            <span
              onClick={e => handleReset(e, name)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-14px)'
              }}
            >
              <AiFillCloseCircle />
            </span>
          )}
        </div>

        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return (
    <Form.Group {...formGroupProps} style={{ position: 'relative' }}>
      {label && <Form.Label>{label}</Form.Label>}
      <div style={{ position: 'relative' }}>
        <Form.Control
          type={type}
          placeholder={placeholder}
          {...formControlProps}
          isInvalid={verifiedValue ? false : !!errors[name]}
          isValid={verifiedValue}
          maxLength={maxLength} // maxLength 속성을 입력 필드에 적용
          onInput={handleInputProps}
          value={inputData}
        />
        {!isEmpty && (
          <span
            onClick={e => handleReset(e, name)}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-14px)'
            }}
          >
            <AiFillCloseCircle />
          </span>
        )}
        {inputData.length > 0 && maxLength >= 1 && (
          <span
            className="fs-9"
            style={{
              position: 'absolute',
              right: 52,
              top: '50%',
              transform: 'translateY(-52%)'
            }}
          >
            ( {inputData.length}/{maxLength} ){' '}
            {/* 현재 글자 수와 최대 글자 수 표시 */}
          </span>
        )}
      </div>
      {inputData.length >= maxLength && ( // maxLength 초과 시 span 표시
        <span className="fs-10 text-danger">
          [ {label} ]은(는) {maxLength}글자를 넘을 수 없습니다.
        </span>
      )}
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  datepickerProps: PropTypes.object
};

export default WizardInput;
