import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Row,
  Col
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
  handleValueDelete,
  goToParentPath
} from 'components/common/SunnyUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { addAdminUsers, ImagesUpload_Site } from 'hooks/CallApi';
import { toast } from 'react-toastify'; // 추가
import styled from 'styled-components';

const AddSiteAdminAccount = () => {
  const {
    config: { userAuth }
  } = useAppContext();

  const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [cancelmodalShow, setCancelModalShow] = useState(false);
  const [confirmCheckShow, setConfirmCheckShow] = useState(false);
  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');
  const [IdError, setIdError] = useState('');
  const [showError, setShowError] = useState(false); // 에러 메시지 표시 상태 및 서버 에러 상태 처리용

  const [passwordError, setPasswordError] = useState(''); // 비번관련 에러 처리용
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const location = useLocation();
  const { EcompanyId } = location.state || {}; // 전달된 companyId를 가져옴
  // const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  // const handleMouseEnter = () => {
  //   if (userAuth === 'SA' || userAuth === 'ADMIN') {
  //     setIsHovered(true);
  //   }
  // };

  // const handleMouseLeave = () => setIsHovered(false);

  const handleCheckOutModal = async () => {
    try {
      const adminData = {
        userIds: { companyId: formData.companyId },
        userId: formData.id,
        userPassword: formData.password,
        userRole: formData.role,
        username: formData.name,
        imgUrl: formData.imgUrl, // 이미지 URL 필요 시 추가
        userDescription: formData.description
      };

      const response = await addAdminUsers(adminData);

      setConfirmCheckShow(true);
    } catch (error) {
      console.error('관리자 계정 등록 중 에러:', error);
      alert('관리자 계정 등록 중 문제가 발생했습니다.');
    }
  };

  const handleCancelModal = () => {
    setCancelModalShow(false);
    navigate('/safedb/common/SiteadminAccount');
  };

  const handleCancelClick = () => {
    setCancelModalShow(true);
  };
  const handleConfirmClick = () => {
    setModalShow(true);
  };

  const [formData, setFormData] = useState({
    id: '',
    password: '',
    passwordConfirm: '',
    name: '',
    role: 'ADMIN',
    description: '',
    imgUrl: '',
    companyId: EcompanyId
  });

  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'id' && value.length >= 30) {
      setIdError(`아이디는 최대 30자까지 입력 가능합니다.`);
    } else {
      setIdError('');
    }

    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`관리자 계정 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }

    const passwordPattern =
      /^(?=.*[a-zA-Z]{3,})(?=.*\d)(?=.*[!@#$%^_\-+=\{\}\[\]:;"'?.,/~])[A-Za-z\d!@#$%^_\-+=\{\}\[\]:;"'?.,/~]{9,16}$/;
    const invalidCharPattern = /[^A-Za-z\d!@#$%^_\-+=\{\}\[\]:;"'?.,/~]/;

    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    // 에러 상태 초기화
    let newPasswordError = '';

    // 비밀번호 검증 로직
    if (name === 'password' || name === 'passwordConfirm') {
      if (invalidCharPattern.test(updatedFormData.password)) {
        newPasswordError = '비밀번호에 허용되지 않은 문자가 포함되어 있습니다.';
      } else if (!passwordPattern.test(updatedFormData.password)) {
        newPasswordError =
          '비밀번호는 최소 [9]자 이상 ~ [16]자 이하의 영문 문자 [3]개와 숫자 [1], 특수문자 [1]개를 포함해야 합니다.';
      } else if (updatedFormData.password !== updatedFormData.passwordConfirm) {
        newPasswordError = '입력하신 비밀번호와 다릅니다.';
      }
    }

    setPasswordError(newPasswordError);
  };

  const handleComboChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'role') {
      // 역할 변경 처리
      setFormData({
        ...formData,
        role: value // 역할 저장
      });
    } else {
      // 기타 필드 처리
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    // setShowError(false); // 이전 에러 메시지 초기화

    if (passwordError) {
      setErrorMsg('비밀번호가 비밀번호 정책에 위반됩니다.');
      setShowError(true);
      return;
    }

    if (formData.password != formData.passwordConfirm) {
      setErrorMsg('입력하신 비밀번호와 다릅니다.');
      setShowError(true);
      return;
    }
  };

  const handleValueDeleteWrapper = field => {
    // 외부 handleValueDelete 호출
    handleValueDelete(field, formData, setFormData);

    // 비밀번호 관련 필드가 초기화될 경우 passwordError도 초기화
    if (field === 'password' || field === 'passwordConfirm') {
      setPasswordError('');
    }
  };

  const onDrop = async acceptedFiles => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          alert('이미지 크기는 최대 5MB까지 허용됩니다.');
          return;
        }

        try {
          // Call ImagesUpload API
          const uploadResponse = await ImagesUpload_Site(imageFile, 'manager'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {
            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData(prev => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl // Set the uploaded URL in formData
            }));

            // setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화
  });

  const isFormValid = () => {
    const { id, name, password, passwordConfirm } = formData;
    return id && name && password && passwordConfirm;
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          관리자 계정
        </Card.Header>
        <Card.Title className="border border-bottom-0 px-3 pt-3">
          {' '}
          관리자 계정 등록
        </Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4} className="text-center profile-section">
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor:
                      userAuth === 'SA' || userAuth === 'ADMIN'
                        ? 'pointer'
                        : 'not-allowed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto'
                  }}

                >
                  <input {...getInputProps()} />

                  <Profile>
                    <Image
                      src={avatar.length > 0 ? avatar[0].src : avatar[0].src}
                      alt=""
                    />
                    <HoverView>이미지 변경</HoverView>
                  </Profile>
                </div>
              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        아이디<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type="text"
                          name="id"
                          value={formData.id}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="30"
                          placeholder="아이디를 입력해주세요."
                          style={{
                            paddingRight: '30px'
                          }}
                        />
                        {formData.id && (
                          <span
                            onClick={e =>
                              handleValueDelete('id', formData, setFormData)
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                      {IdError && <div style={{ color: 'red' }}>{IdError}</div>}{' '}
                      {/* 에러 메시지 표시 */}
                    </Form.Group>
                  </Col>
                </Row>

                {/* Password */}

                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        비밀번호<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <FormControl
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="비밀번호를 입력해주세요."
                          style={{
                            paddingRight: '30px'
                          }}
                        />
                        {formData.password && (
                          <span
                            onClick={() => handleValueDeleteWrapper('password')}
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        비밀번호 확인<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <FormControl
                          type="password"
                          name="passwordConfirm"
                          value={formData.passwordConfirm}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="확인을 위해 비밀번호를 다시 입력해주세요."
                          style={{
                            paddingRight: '30px'
                          }}
                        />
                        {formData.passwordConfirm && (
                          <span
                            onClick={() =>
                              handleValueDeleteWrapper('passwordConfirm')
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  {passwordError && (
                    <p style={{ color: 'red', fontSize: '0.8rem' }}>
                      {passwordError}
                    </p>
                  )}
                  <p style={{ fontSize: '0.8rem' }}>
                    비밀번호는 최소 [9]자 이상 ~ [16]자 이하의 영문 문자 [3]개와
                    숫자[1], 허용된 특수문자 중 [1]자 이상을 포함해야 합니다.
                    {/* <br />! @ # $ % ^ _ - + = { } [ ] : ; " ' ? , . / ~ */}
                  </p>
                </Row>

                {/* Name */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        이름<span style={{ color: 'red' }}>*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="30"
                          placeholder="이름을 입력해주세요."
                          style={{
                            paddingRight: '30px'
                          }}
                        />
                        {formData.name && (
                          <span
                            onClick={e =>
                              handleValueDelete('name', formData, setFormData)
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                      {NameError && (
                        <div style={{ color: 'red' }}>{NameError}</div>
                      )}{' '}
                      {/* 에러 메시지 표시 */}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>역할</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        value={formData.role}
                      >
                        <option value="" disabled>
                          역할을 선택하세요
                        </option>

                        <option value="ADMIN">최고 관리자</option>
                        <option value="MANAGER">운영 관리자</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength="800"
                  />
                  {DescError && <div style={{ color: 'red' }}>{DescError}</div>}{' '}
                  {/* 에러 메시지 표시 */}
                  {formData.description && (
                    <span
                      onClick={e =>
                        handleValueDelete('description', formData, setFormData)
                      }
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                className="me-2"
                onClick={handleCancelClick}
              >
                취소
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!isFormValid()}
                onClick={handleConfirmClick}
              >
                등록
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={cancelmodalShow}
        onHide={() => setCancelModalShow(false)}
        onConfirm={handleCancelModal}
        title="확인" // 제목
        body="진행 중인 작업을 취소하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModal}
        title="확인" // 제목
        body="관리자 계정을 등록하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmCheckShow}
        onHide={() => {
          setConfirmCheckShow(false); // 첫 번째 상태 변경
          setModalShow(false); // 두 번째 상태 변경
        }}
        onConfirm={() => {
          setConfirmCheckShow(false); // 첫 번째 상태 변경
          setModalShow(false); // 두 번째 상태 변경
          navigate('/safedb/common/SiteadminAccount');
        }}
        title="완료" // 제목
        body="관리자 계정 등록이 완료되었습니다." // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
    </div>
  );
};

export default AddSiteAdminAccount;

const Profile = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const HoverView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: all 0.1s;
  background-color: #2229;
  user-select: none;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;
