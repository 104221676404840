// mypage.js
import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import avatarImg_COMMON from 'assets/img/team/avatar.png';
import avatarImg_SA from 'assets/img/initech_ci.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import 'react-toastify/dist/ReactToastify.css'; // 추가
import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
  handleValueDelete,
  goToParentPath
} from 'components/common/SunnyUtils';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import ChangePasswordModal from 'safedb/common/changePasswordModal'; // ChangePasswordModal 임포트
import {
  updateAdminInfo_Site,
  ImagesUpload_Site,
  deleteAdminInfo_Site,
  deleteAdminInfo
} from 'hooks/CallApi'; // API 함수 임포트
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const EditAdminAccount = ({ setValue }) => {
  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가
  const [modalDeleteCheckShow, setModalDeleteCheckShow] = useState(false);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      setIsHovered(true);
    }
  };
  const handleMouseLeave = () => setIsHovered(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);

  const location = useLocation();
  const {
    userId,
    username,
    userRole,
    userUuid,
    companyId,
    activestatus,
    active,
    createdAt,
    lastLoginAt,
    companyName,
    description,
    imgUrl
  } = location.state || {}; // 추가 데이터 포함

  const {
    config: { checkorg, userAuth },
    setConfig
  } = useAppContext();


  const handleShowPasswordModal = () => {
    // console.log('Opening password modal...');
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    // console.log('Closing password modal...');
    setShowPasswordModal(false);
  };

  // 내용 변경 시 변경 버튼 눌렀을때
  const handleSubmitClick = () => {
    setModalConfirmShow(true);
  };

  const handleDeleteModalClick = () => {
    setModalDeleteShow(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await updateAdminInfo_Site(userUuid, {
        imgUrl: formData.imgUrl,
        username: formData.name,
        active: formData.status,
        userDescription: formData.description
      });

      if (response) {
        setModalConfirmShow(false);
        setModalConfirmCheckShow(true);
      } else {
        alert('매니저 정보 업데이트가 실패했습니다.');
      }
    } catch (error) {
      alert('매니저 정보 업데이트 중 오류가 발생했습니다.');
    }
  };
  const navigate = useNavigate(); // useNavigate 훅 사용

  const handleDeleteRe = async () => {
    setModalDeleteShow(false);
    try {
      const result = await deleteAdminInfo_Site(userUuid); // 삭제 API 호출
      if (result.success) {
        setModalDeleteCheckShow(true);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorCode(error.response.data.code);
        setErrorMsg(error.response.data.message);
      } else {
        setErrorCode('알 수 없음');
        setErrorMsg('서버와의 통신 중 문제가 발생했습니다.');
      }
      setModalErrorShow(true);
    }
  };

  const initialFormData = {
    id: userId,
    password: '*****',
    name: username,
    role: userRole,
    org: companyName,
    status: activestatus === true ? 'true' : 'false', // Boolean을 문자열로 변환
    description: description,
    createdAt: createdAt,
    lastLoginAt: lastLoginAt,
    imgUrl: imgUrl || (userRole === 'SA' ? avatarImg_SA : avatarImg_COMMON) // 기본 이미지 설정
  };

  const [formData, setFormData] = useState({
    id: userId,
    password: '*****',
    name: username,
    role: userRole,
    org: companyName,
    status: activestatus === true ? 'true' : 'false', // Boolean을 문자열로 변환
    description: description,
    createdAt: createdAt,
    lastLoginAt: lastLoginAt,
    imgUrl: imgUrl
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    { src: imgUrl || (userRole === 'SA' ? avatarImg_SA : avatarImg_COMMON) } // 초기 상태에서 아바타 설정
  ]);

  useEffect(() => {
    if (formData.name.length < 1) {
      setNameError('필수 항목입니다.');
    } else if (formData.name.length >= 30) {
      setNameError('이름은 최대 30자까지 입력 가능합니다.');
    } else {
      setNameError('');
    }
  }, [formData.name]);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'description' && value.length >= 800) {
      setDescError(`관리자 계정 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleComboChange = e => {
    const { name, value } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value // status는 이미 문자열로 관리
    }));
  };
  const handleSubmit = e => {
    e.preventDefault();
  };


  const onDrop = async acceptedFiles => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          alert('이미지 크기는 최대 5MB까지 허용됩니다.');
          return;
        }

        try {
          // Call ImagesUpload API
          const uploadResponse = await ImagesUpload_Site(imageFile, 'manager'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {
            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData(prev => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl // Set the uploaded URL in formData
            }));
            // setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화
  });

  const isFormValid = () => {
    const hasChanged =
      formData.name !== initialFormData.name ||
      formData.status !== initialFormData.status ||
      formData.description !== initialFormData.description ||
      formData.imgUrl !== initialFormData.imgUrl; // 이미지 변경 여부

    return hasChanged;
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          관리자
        </Card.Header>

        <Card.Title className="border border-bottom-0 px-3 pt-3">
          {' '}
          계정 상세 정보
        </Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor:
                      userAuth === 'SA' || userAuth === 'ADMIN'
                        ? 'pointer'
                        : 'not-allowed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto'
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />

                  <Profile>
                    <Image
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                    />
                    <HoverView>이미지 변경</HoverView>
                  </Profile>
                </div>
              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>아이디 </Form.Label>
                      <Form.Control
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className="input-hover"
                        placeholder="아이디를 입력해주세요."
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>비밀번호 </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <FormControl
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="비밀번호를 입력해주세요."
                          disabled
                        />
                        <Button
                          variant="outline-info"
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 9,
                            padding: '5px 10px',
                            fontSize: '0.6rem'
                          }}
                          onClick={handleShowPasswordModal}
                        >
                          변경
                        </Button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        이름<span className="text-danger">*</span>
                      </Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="30"
                          placeholder="이름을 입력해주세요."
                          style={{
                            paddingRight: '30px'
                          }}
                        />
                        {formData.name && (
                          <span
                            onClick={e =>
                              handleValueDelete('name', formData, setFormData)
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                      {NameError && (
                        <div style={{ color: 'red' }}>{NameError}</div>
                      )}{' '}
                      {/* 에러 메시지 표시 */}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>역할</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        value={formData.role}
                        disabled
                      >
                        <option value="SA">시스템 관리자</option>
                        <option value="ADMIN">최고 관리자</option>
                        <option value="MANAGER">운영 관리자</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>상태</Form.Label>
                      <Form.Select
                        name="status"
                        onChange={handleComboChange}
                        value={formData.status} // formData.status 동기화
                      >
                        <option value="true">정상</option>
                        <option value="false">차단</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        type="text"
                        name="addDate"
                        value={formData.createdAt}
                        className="input-hover"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>최근 접속 일시</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastLogin"
                        value={formData.lastLoginAt}
                        className="input-hover"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <div style={{ position: 'relative' }}>
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows={3}
                      value={formData.description}
                      onChange={handleChange}
                      className="input-hover"
                      maxLength="800"
                    />

                    {formData.description && (
                      <span
                        onClick={e =>
                          handleValueDelete(
                            'description',
                            formData,
                            setFormData
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 9
                        }}
                      >
                        <AiFillCloseCircle />
                      </span>
                    )}
                  </div>
                  {DescError && <div style={{ color: 'red' }}>{DescError}</div>}{' '}
                  {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <Button
                variant="outline-danger"
                onClick={handleDeleteModalClick}
                style={{ width: '100px' }}
              >
                삭제
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmitClick}
                disabled={!isFormValid()}
                style={{ width: '100px' }}
              >
                변경
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={handleDeleteRe}
        title="경고" // 제목
        variant="warning"
        body="삭제된 관리자 정보는 복구할 수 없습니다. 정말 삭제하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="삭제" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalDeleteCheckShow}
        onHide={() => setModalDeleteCheckShow(false)}
        onConfirm={() => {
          setModalDeleteCheckShow(false);
          navigate(`/safedb/common/SiteAdminAccount`); // 데이터와 함께 이동
        }}
        title="완료" // 제목
        variant="success"
        body="삭제가 완료 되었습니다." // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        variant="info"
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => {
          setModalConfirmCheckShow(false);
          navigate(`/safedb/common/SiteadminAccount`);
        }}
        variant="success"
        onConfirm={() => {
          setModalConfirmCheckShow(false);
          navigate(`/safedb/common/SiteadminAccount`);
        }}
        title="완료" // 제목
        body="변경이 완료되었습니다." // 본문 내용
        confirmText="확인"
        SubmitButton={true}
      />
      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
        id={formData.id}
        name={formData.name}
        flag={true}
        uuid={userUuid}
      />

      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />
    </div>
  );
};

export default EditAdminAccount;

const Profile = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const HoverView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: all 0.1s;
  background-color: #2229;
  user-select: none;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;
