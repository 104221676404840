import React, { useState, useEffect } from 'react';
import {
    Form,
    Button,
    InputGroup,
    FormControl,
    Card,
    Row,
    Col
} from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';

import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiFillCloseCircle
} from 'react-icons/ai';
import {
    handleValueDelete,
    goToParentPath
} from 'components/common/SunnyUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import GroupManagementModal from 'components/modal/GroupManagementModal';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';

import { deletePubKey, updatePubKey } from 'hooks/CallApi';

const editencryptPublicKey = ({ setValue, handleClose }) => {
    const navigate = useNavigate();
    const {
        config: { userAuth_K, userId, checkorg },
        setConfig
    } = useAppContext();
    const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태
    const [deleteCheckshow, setDeleteCheckshow] = useState(false);

    const [isRemakeSelected, setIsRemakeSelected] = useState(false);
    const [isModified, setIsModified] = useState(false); // 변경 상태 추적
    const [showToast, setShowToast] = useState(false); // 토스트 상태
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가

    const [confrimCheckshow, setConfrimCheckshow] = useState(false); // 모달 상태 추가
    const [confirmmodalShow, setConfirmmodalShow] = useState(false); // 모달 상태 추가
    const [ErrorCode, setErrorCode] = useState('');
    const [ErrorMsg, setErrorMsg] = useState('');
    const [modalErrorShow, setModalErrorShow] = useState(false);

    const [isArrowHovered, setIsArrowHovered] = useState(false);

    const location = useLocation();
    const {
        publickeyName,
        algorithm,
        keylength,
        mode,
        sharedkeyUuid,
        insertTime,
        description
    } = location.state || {}; // 추가 데이터 포함

    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };

    const handleCheckOutModal = () => {
        setModalShow(false); // 관리자 모달 닫기
    };
    const handleGroupModalConfirm = () => {
        setShowGroupModal(false);
        setShowGroupAddModal(true);
    };
    const handleGroupAddModalShow = () => {
        setShowGroupAddModal(true); // 모달 열기
    };

    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handleGroupAddModalConfirm = () => {
        setShowGroupAddModal(false);
    };
    const handleDeleteClick = () => {
        setModalShow(true); // 모달 열기
    };

    const handleCheckOutModalConfirm = async () => {
        try {

            await deletePubKey(sharedkeyUuid); // deleteEncKey API 호출
            setDeleteCheckshow(true);
        } catch (error) {

            setErrorCode(error.response.data.code);
            setErrorMsg(error.response.data.message);
            setModalErrorShow(true);
        } finally {
            setModalShow(false);
            // navigate('/safedb/common/key/encryptPublicKey');
        }
    };

    const handleRemakeClick = () => {
        setIsRemakeSelected(prev => !prev); // Toggle selected state
    };
    const [pwType, setpwType] = useState({
        type: 'password',
        visible: false
    });
    const handlePasswordType = e => {
        setpwType(() => {
            // 만약 현재 pwType.visible이 false 라면
            if (!pwType.visible) {
                return { type: 'text', visible: true };

                //현재 pwType.visible이 true 라면
            } else {
                return { type: 'password', visible: false };
            }
        });
    };

    // const { publickeyName, algorithm, keylength, mode, sharedkeyUuid, insertTime, description } = location.state || {}; // 추가 데이터 포함

    const [formData, setFormData] = useState({
        publickeyName: publickeyName,
        algorithm: algorithm,
        keylength: keylength,
        mode: mode,
        description: description
    });

    const { user } = useAuthWizardContext();

    const [avatar, setAvatar] = useState([
        ...(user.avatar ? user.avatar : []),
        { src: avatarImg }
    ]);
    useEffect(() => {
        // 컴포넌트가 처음 렌더링될 때 formData 초기화
        setFormData(prev => ({
            ...prev,
            adderRole: userAuth_K, // config에서 가져온 값
            adderId: userId // config에서 가져온 값
        }));
    }, [userAuth_K, userId]); // 의존성 배열에 userAuth와 userId 추가

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setIsModified(true); // 필드가 변경되면 상태 업데이트
    };

    const handleComboChange = ({ target }) => {
        let name = target.name;
        let value = name === 'allDay' ? target.checked : target.value;
        setFormData(prev => ({ ...prev, [name]: value }));
        setIsModified(true); // 필드가 변경되면 상태 업데이트
    };
    const handleSubmit = e => {
        e.preventDefault();
        // 등록 과정 추가 필요

        setConfirmmodalShow(true);
    };

    const handlecheckConfirm = async () => {
        const pubkeyDesc = formData.description;
        try {
            const response = await updatePubKey(sharedkeyUuid, pubkeyDesc);
            setConfirmmodalShow(false);
            setConfrimCheckshow(true);
        } catch (error) {
            console.error('공유키 변경 오류:', error);
        }
    };
    const isFormValid = () => {
        const { group, keyname, accesskey, description } = formData;
        return isModified && (group || keyname || accesskey || description);
    };

    return (
        <div className="container mt-5">
            <Card className="mypage-card">
                <Card.Header className="px-3" as="h4">
                    <Link
                        to={goToParentPath()}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        onMouseEnter={() => setIsArrowHovered(true)}
                        onMouseLeave={() => setIsArrowHovered(false)}
                    >
                        <FontAwesomeIcon
                            icon={faCaretSquareLeft}
                            className="me-2"
                            style={
                                isArrowHovered
                                    ? {} // Hover 시 스타일 제거
                                    : { color: '#869fca' } // 기본 스타일
                            }
                        />
                    </Link>
                    공유키
                </Card.Header>

                <Card.Title className="px-3 pt-3">
                    {' '}
                    상세 정보
                </Card.Title>

                <Card.Body >
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>공유키명</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="publickeyName"
                                                value={formData.publickeyName}
                                                disabled
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>알고리즘</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="algorithm"
                                                value={formData.algorithm}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.algorithm}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>키 길이(bit)</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="keylength"
                                                value={formData.keylength}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.keylength}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>운영모드</Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="mode"
                                                value={formData.mode}
                                                readOnly
                                                disabled
                                            >
                                                <option value="mode">{formData.mode}</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                    <Form.Label>설명</Form.Label>
                                    <div style={{ position: 'relative' }}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            rows={10}
                                            value={formData.description}
                                            onChange={handleChange}
                                            className="input-hover"
                                        // placeholder="키의 용도, 사용할 부서 또는 시스템을 입력할 수 있습니다. (예: 마케팅 부서, pod-001 복호화 전용)"
                                        />

                                        {formData.description && (
                                            <span
                                                onClick={e =>
                                                    handleValueDelete(
                                                        'description',
                                                        formData,
                                                        setFormData
                                                    )
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 9
                                                }}
                                            >
                                                <AiFillCloseCircle />
                                            </span>
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Cancel and Confirm Buttons */}
                        <div className="d-flex justify-content-between mt-3">
                            <Button
                                variant="outline-danger"
                                style={{ width: '7rem' }}
                                onClick={handleDeleteClick}
                            >
                                삭제
                            </Button>
                            <div>
                                {/* <Button className="mx-2" variant="primary" type="submit" style={{ width: '7rem' }} disabled={!isFormValid()}> */}
                                <Button
                                    className="mx-2"
                                    variant="primary"
                                    type="submit"
                                    style={{ width: '7rem' }}
                                >
                                    수정
                                </Button>
                                {/* <Button variant="outline-secondary" onClick={() => navigate('/safedb/common/key/encryptPublicKey')} style={{ width: '7rem' }}>
                                    목록
                                </Button> */}
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <CheckOutModal
                show={confirmmodalShow}
                onHide={() => setConfirmmodalShow(false)}
                onConfirm={handlecheckConfirm}
                title="경고" // 제목
                body={'공유키 설명을 수정 하시겠습니까?'}
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="warning" // 원하는 variant
                CancelButton={true}
                SubmitButton={true}
            />

            <CheckOutModal
                show={confrimCheckshow}
                onHide={() => {
                    setConfrimCheckshow(false);
                    navigate(`/safedb/common/key/encryptPublicKey`);
                }}
                onConfirm={() => {
                    setConfrimCheckshow(false);
                    navigate(`/safedb/common/key/encryptPublicKey`);
                }}
                title="완료" // 제목
                body={'수정이 완료 되었습니다.'}
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="success" // 원하는 variant
                SubmitButton={true}
            />
            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModalConfirm}
                title="경고" // 제목
                body={'공유키는 다시 복구할 수 없습니다. 정말 삭제하시겠습니까?'}
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="삭제" // 확인 버튼 텍스트
                variant="warning" // 원하는 variant
                CancelButton={true}
                SubmitButton={true}
            />

            <CheckOutModal
                show={deleteCheckshow}
                onHide={() => setDeleteCheckshow(false)}
                onConfirm={() => {
                    setDeleteCheckshow(false);
                    navigate('/safedb/common/key/encryptPublicKey');
                }}
                title="완료" // 제목
                body={'삭제가 완료 되었습니다.'}
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="success" // 원하는 variant
                SubmitButton={true}
            />
            <CheckOutModal
                show={modalErrorShow}
                onHide={() => setModalErrorShow(false)}
                onConfirm={() => setModalErrorShow(false)}
                title="경고"
                // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
                body={`${ErrorMsg}`}
                cancelText="취소"
                confirmText="확인"
                variant="warning"
                SubmitButton={true}
            />
        </div>
    );
};

export default editencryptPublicKey;
