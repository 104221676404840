import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { hourlyAvg } from 'hooks/CallApi'; // API 함수 import
import { useAppContext } from 'providers/AppProvider';

const processingTimeThreshold = 0.5;

const SiteCfProcessSpeed = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);  // chart 인스턴스를 useRef로 관리
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    config: { period }
  } = useAppContext();

  // 차트 초기화 함수
  const initChart = useCallback(() => {
    if (chartInstance.current) {
      chartInstance.current.dispose();
    }

    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current);
      return chartInstance.current;
    }
    return null;
  }, []);

  // 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const requestParams = {
          timeZone: clientTimeZone,
          type: 'cf',
          ...(period === 'demo' && { period })
        };

        const response = await hourlyAvg(requestParams);
        const apiData = response.data;

        const formattedData = apiData.map((company) => ({
          name: company.companyName,
          hourlyData: Array.from({ length: 24 }, (_, hour) => {
            const hourData = company.hourlyAvgDataList.find((item) => item.hour === hour);
            return hourData ? hourData.hourlyAvgDuration : null;
          }),
        }));
        setData(formattedData);
      } catch (error) {
        console.error('[hourlyAvg-CF] 데이터 호출 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // cleanup
    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [period]);

  // 차트 렌더링
  useEffect(() => {
    if (isLoading || !chartRef.current || data.length === 0) return;

    const chart = initChart();
    if (!chart) return;

    const generateSeries = () =>
      data.map((company, index) => ({
        name: company.name,
        type: 'line',
        smooth: false,
        data: company.hourlyData,
        lineStyle: {
          color: ['#5cb85c', '#B0B57E', '#F6C6B1', '#800080'][index % 4],
        },
        markPoint: {
          data: company.hourlyData
            .map((value, hour) =>
              value > processingTimeThreshold
                ? { value, xAxis: hour, yAxis: value }
                : null
            )
            .filter(Boolean),
          symbol: 'circle',
          symbolSize: 7,
          itemStyle: { color: 'yellow' },
          label: { show: false },
        },
      })).concat({
        name: '지연 기준선',
        type: 'line',
        data: Array(24).fill(processingTimeThreshold),
        symbol: 'none',
        lineStyle: {
          color: '#0000FF',
          type: 'dashed',
          width: 2
        }
      });

    const option = {
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => `${value.toFixed(3)} 초`,
      },
      legend: {
        data: [...data.map((company) => company.name), '지연 기준선'],
        bottom: '5%',
      },
      xAxis: {
        type: 'category',
        data: Array.from({ length: 24 }, (_, hour) => `${hour.toString().padStart(2, '0')}시`),
      },
      yAxis: {
        type: 'value',
        min: 0,
        axisLabel: { formatter: '{value} 초' },
      },
      series: generateSeries(),
      grid: {
        left: '10%',
        right: '5%',
        bottom: '25%',
        top: '5%',
      },
    };

    chart.setOption(option);

    const handleResize = () => {
      chart && chart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data, isLoading, initChart]);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, []);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>시간별 CF 평균 처리속도</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          최근 1주일
        </span>
      </Card.Header>
      <Card.Body>
        <div style={{ minHeight: '15rem' }}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: '15rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default SiteCfProcessSpeed;
