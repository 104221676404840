import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, ButtonGroup, Card, Spinner } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { useAppContext } from 'providers/AppProvider';
import { avgRequest } from 'hooks/CallApi';
import { formatCountStyle3 } from 'components/common/SunnyUtils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

// const dataSets = {

//   barData: [
//     { name: '고객사 A', process: 30, min: 10, max: 50 },
//     { name: '고객사 B', process: 60, min: 20, max: 100 },
//     { name: '고객사 C', process: 300, min: 50, max: 550 },
//     { name: '고객사 D', process: 600, min: 100, max: 1100 },
//     { name: '고객사 E', process: 1200, min: 200, max: 2200 }
//   ]

// };

export default function PolicyCharts() {
  const { getThemeColor } = useAppContext();
  const barChartRef = useRef(null);
  const [barData, setBarData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    config: { period }  // period 추가
  } = useAppContext();

  // 데이터 가져오는 useEffect
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // API 요청 파라미터 객체 생성
        const requestParams = {
          timeZone: clientTimeZone,
          type: 'api'
        };

        // period가 'demo'일 때만 period 파라미터 추가
        if (period === 'demo') {
          requestParams.period = period;
        }

        // 생성된 파라미터로 API 요청
        const response = await avgRequest(requestParams);

        // 응답 데이터 처리 (response.data.data로 변경)
        const data = response?.data || {};
        const formattedData = Array.from({ length: 5 }, (_, i) => ({
          name: data[`top${i + 1}_company`] || ``,
          process: data[`top${i + 1}_avg_req`] || 0,
          min: data[`top${i + 1}_min_req`] || 0,
          max: data[`top${i + 1}_max_req`] || 0
        }));

        const sortedData = formattedData.reverse();

        setBarData(formattedData);
      } catch (error) {
        console.error('API 평균 요청량 데이터 불러오기 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [period]);

  const getBarOption = () => ({
    color: [getThemeColor('primary'), getThemeColor('info')],
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const customerName = params[0].name; // 고객사 이름
        const averageProcess = params[0].value; // 처리량 평균
        const minProcess = barData[params[0].dataIndex]?.min || 0; // 최소 처리량
        const maxProcess = barData[params[0].dataIndex]?.max || 0; // 최대 처리량

        return `${customerName}<br/>요청량 평균: ${formatCountStyle3(
          averageProcess
        )}<br/>최소 요청량: ${formatCountStyle3(
          minProcess
        )}<br/>최대 요청량: ${formatCountStyle3(maxProcess)}<br/>`;
      },
      axisPointer: { type: 'shadow' }
    },
    legend: {
      // data: ['요청량 평균', '최소 요청량', '최대 요청량'], // 라벨 수정
      data: ['요청량 평균'], // 라벨 수정
      bottom: 5,
      textStyle: { color: getThemeColor('gray-600') }
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        color: getThemeColor('gray-500')
        // formatter: (value) => formatCountStyle3(value), // formatCountStyle3 적용
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getThemeColor('gray-200')
        }
      }
    },
    yAxis: {
      type: 'category',
      data: barData.map(d => d.name),
      axisLabel: { color: getThemeColor('gray-500') }
    },
    series: [
      {
        name: '요청량 평균', // 시리즈 이름 수정
        type: 'bar',
        data: barData.map(d => d.process),
        itemStyle: { borderRadius: [0, 3, 3, 0] }
      }
    ],
    grid: {
      left: '3%',
      right: '10%',
      bottom: '12%',
      top: '3%',
      containLabel: true
    }
  });

  return (
    <Card>
      <CardHeader>
        <h5 style={{ margin: 0, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
          API 평균 요청량 상위
        </h5>
        <Badge>최근 1주일</Badge>
      </CardHeader>
      <Card.Body>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <ReactEchart
            ref={barChartRef}
            echarts={echarts}
            option={getBarOption()}
            style={{ magin: '2rem', width: '100%', height: '20rem' }} // 크기 조정
          />
        )}
      </Card.Body>
    </Card>
  );
}

// 1안 - 뱃지 줄바꿈
const CardHeader = styled(Card.Header)`
  display: flex;
  align-items: center;
  padding-bottom: 0;
  gap: 8px;

  @media (min-width: 1540px) and (max-width: 2560px) {
    // flex-direction: column;
    align-items: start;
    gap: 4px;
  }
`;

// 2안 - 뱃지 내부에서 줄바꿈
const Badge = styled.span`
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 8px;
  background-color: #a1c4e3; // 배경색 파란색
  color: white; // 텍스트 색상 흰색
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  flex-wrap: nowrap; // 한 줄로 표시
  white-space: nowrap; // 한 줄로 유지

  /* @media (min-width: 1540px) and (max-width: 2560px) {
    white-space: normal;
    word-break: keep-all;
    text-align: center;
  } */
`;
