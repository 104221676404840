import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import { fetchRecentlyEncDec } from 'hooks/CallApi';

function ChartOptions() {
  const chartRef = useRef(null);
  const {
    config: {
      dashboardDate,
      dashboardDateValue,
      Chart_startdate,
      Chart_enddate,
      period
    }
  } = useAppContext();
  const [view, setView] = useState('cf'); // 'top' or 'bottom'
  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  // const [chartData, setChartData] = useState({ timeData: [], encryptData: [], decryptData: [], failData: [] });
  const [chartData, setChartData] = useState({
    timeData: [],
    encryptData: [],
    decryptData: []
  });
  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const response = await fetchRecentlyEncDec({
          period: period || dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view
        });
        const data = response.data;

        // const timeData = data.map(item => item.periodKst);
        const allTimeData = data.map((item) => item.periodKst);

        let timeData;
        if (dashboardDateValue === 'now') {
          // "now"일 경우 6분 단위로 최대 10개 선택
          // 60분을 분단위로 다 안줄 경우 균등하게 처리해서 데이터는 표시되도록
          const totalCount = allTimeData.length; // 전체 데이터 개수
          const displayCount = 10; // 보여줄 데이터 개수
          if (totalCount <= displayCount) {
            // 전체 데이터가 10개 이하인 경우, 모든 데이터를 사용
            timeData = allTimeData.map((time) =>
              new Date(time).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })
            );
          } else {
            // 전체 데이터가 10개 초과인 경우, 균등 간격으로 데이터 선택
            const interval = Math.floor(totalCount / (displayCount - 1)); // 간격 계산
            timeData = Array.from({ length: displayCount }, (_, i) => {
              const index = Math.min(i * interval, totalCount - 1); // 마지막 데이터 포함
              return new Date(allTimeData[index]).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              });
            });
          }

          // 아래 주석 내용은 무조건 60개가 온다고 할 경우 아래 로직 사용
          // timeData = Array.from({ length: 10 }, (_, i) => {
          //   const index = i * 6; // 6분 간격으로 인덱스 생성
          //   console.log('index', index);
          //   return index < allTimeData.length
          //     ? new Date(allTimeData[index]).toLocaleTimeString([], {
          //       hour: '2-digit',
          //       minute: '2-digit',
          //       hour12: false
          //     })
          //     : ''; // 데이터가 없으면 빈값 반환
          // });
        } else if (dashboardDateValue === 'week' || dashboardDateValue === 'month') {
          // "week" 또는 "month"일 경우 날짜만 표시
          timeData = allTimeData.map((time) => {
            const date = new Date(time);
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월 2자리
            const day = date.getDate().toString().padStart(2, '0'); // 일 2자리
            return `${month}.${day}`; // 원하는 포맷으로 변환
          });
        } else {
          timeData = allTimeData; // 기본값
        }

        const encryptData = data.map(item => parseInt(item.encrypt_cnt) || 0);
        const decryptData = data.map(item => parseInt(item.decrypt_cnt) || 0);
        // const failData = data.map((item) => (item.fail_mark ? Math.max(parseInt(item.enc_fail_cnt), parseInt(item.dec_fail_cnt)) : null));

        // setChartData({ timeData, encryptData, decryptData, failData });
        setChartData({ timeData, encryptData, decryptData });
      } catch (error) {
        console.error('데이터 조회 에러:', error);
      }
    };

    fetchData();
  }, [dashboardDate, dateRange, view, period]);

  useEffect(() => {
    // const minFailHeight = 2;
    // const failDataAdjusted = chartData.failData.map(value =>
    //   value !== null && value < minFailHeight ? minFailHeight : value
    // );

    const isDataEmpty =
      chartData.encryptData.every(value => value === 0) &&
      chartData.decryptData.every(value => value === 0);
    // chartData.failData.every((value) => value === null);

    const formatNumber = value => {
      if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K';
      } else {
        return value;
      }
    };

    const option = {
      title: isDataEmpty
        ? {
          text: '데이터 없음',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#aaa',
            fontSize: 16
          }
        }
        : null,

      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'line' },
        formatter: params => {
          if (!params.length) return '';
          let total = 0;
          let tooltipText = `${params[0].name} 총 요청량 : ${params.reduce(
            (sum, item) => sum + (item.value || 0),
            0
          )} 건 <br/>`;
          total = `${params.reduce((sum, item) => sum + (item.value || 0), 0)}`;
          params.forEach(item => {
            tooltipText += `${item.marker} ${item.seriesName}: ${item.value || 0
              } 건, ${((item.value / total) * 100).toFixed(0)} %<br/>`;
          });
          return tooltipText;
        }
      },

      legend: {
        data: ['암호화', '복호화'],
        bottom: '5%'
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: chartData.timeData,
        name: '시간',
        // axisLabel: { formatter: '{HH}:{mm}' },
        // axisLabel: {
        //   // formatter: (value) => (value ? `{HH}:{mm}` : ''), // 값이 없으면 레이블 표시 안함
        //   formatter: value =>
        //     value
        //       ? new Date(value).toLocaleTimeString([], {
        //           hour: '2-digit',
        //           minute: '2-digit'
        //         })
        //       : '',

        //   show: !isDataEmpty // 데이터가 없으면 axisLabel 숨김
        // },
        axisLabel: {
          formatter: (value, index) => {
            if (!value) {
              return ''; // 공백 반환
            }
            if (dashboardDateValue === 'now') {
              // "now"의 경우 시간만 표시, 6분 단위로 10개 표시
              // return index % 6 === 0
              //   ? new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
              //   : '';
              return value; // 분단위 모든 데이터와서 나눌 필요 없음
            } else if (dashboardDateValue === 'week') {
              // "week"의 경우 날짜만 표시

              return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
            } else if (dashboardDateValue === 'month') {
              // "month"의 경우 날짜만 표시
              return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
            } else if (dashboardDateValue === 'custom') {
              const startDate = new Date(Chart_startdate);
              const endDate = new Date(Chart_enddate);
              if (endDate - startDate <= 24 * 60 * 60 * 1000) {
                // "custom"의 경우 24시간 이내에는 시간만 표시
                return new Date(value).toLocaleTimeString([], { hour: '2-digit' });
              } else {
                // "custom"의 경우 24시간 이상이면 날짜만 표시
                return new Date(value).toLocaleDateString([], { month: '2-digit', day: '2-digit' });
              }
            }
            return value; // 기본값
          },
          interval: 0, // 모든 데이터를 대상으로 formatter 적용
          show: true, // 항상 레이블 표시
          //
          // rotate: dashboardDateValue === 'month' ? 45 : 0
          rotate: dashboardDateValue === 'custom' && chartData.timeData.length > 20
            ? 45
            : dashboardDateValue === 'month'
              ? 45
              : 0 // 기본값


        },
        splitLine: { show: false }
      },
      yAxis: {
        type: 'value',
        name: '요청량',
        splitLine: { show: true },
        axisLabel: { formatter: value => formatNumber(value) + ' 건' },
        scale: true
        // max: (value) => Math.ceil(value.max / 1000) * 1000
      },
      series: [
        {
          name: '암호화 요청량',
          type: 'line',
          data: isDataEmpty ? [] : chartData.encryptData,
          itemStyle: { color: '#2f7ed8' },
          smooth: false
        },
        {
          name: '복호화 요청량',
          type: 'line',
          data: isDataEmpty ? [] : chartData.decryptData,
          itemStyle: { color: '#8bbc21' },
          smooth: false
        },
        {
          name: '실패',
          type: 'scatter',
          symbol: 'pin',
          symbolSize: 40,
          label: {
            show: true,
            formatter: '실패',
            position: 'inside',
            color: '#fff',
            fontSize: 10
          },
          // data: isDataEmpty ? [] : failDataAdjusted,
          itemStyle: { color: '#ff4d4d' }
        }
      ]
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    const handleResize = () => {
      chart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [chartData]);

  return (
    <Card className="h-md-100">
      <Card.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h5 style={{ margin: 0 }}>암복호화 요청량</h5>
          <span
            style={{
              marginLeft: '8px',
              marginTop: 0,
              marginBottom: 0,
              padding: '2px 8px',
              // backgroundColor: '#A1C4E3',
              backgroundColor: '#2C7BE5',
              color: 'white',
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            {dashboardDate}
          </span>
        </div>
        <ButtonGroup>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
          >
            SDK
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="space-between">
        <div style={{ textAlign: 'right' }}>
          <strong>단위 : 건수</strong>
        </div>
      </Card.Body>
      <div
        ref={chartRef}
        style={{
          width: '100%',
          maxWidth: '1200px',
          height: '15rem',
          margin: '0 auto'
        }}
      />
    </Card>
  );
}

export default ChartOptions;
