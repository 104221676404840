import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

// 공통 마이페이지, 비밀번호변경
import MyPage from 'safedb/common/mypage';
import ChgPwd from 'safedb/common/changePasswordModal';


// 관리자 메뉴

// 관리자자
import AdminAccount from 'safedb/SA/adminAccount';
import AddAdminAccount from 'safedb/SA/AddadminAccount';
import EditAdminAccount from 'safedb/SA/EditadminAccount';
// 정책관리
import PolicyManager from 'safedb/SA/policyManager';
// 로그
import LogAdmin from 'safedb/SA/logAdmin';
// 고객사사
import AdminSites from 'safedb/SA/adminSites';
import EditadminSite from 'safedb/SA/EditadminSite'
import AddadminSite from 'safedb/SA/AddadminSites';

// 고객사 메뉴
import SiteInfo from 'safedb/common/SiteInfo';
// 고객사 관리자
import SiteAdminAccount from 'safedb/common/SiteadminAccount';
import EditSiteAdminAccount from 'safedb/common/EditSiteadminAccount';
import AddSiteAdminAccount from 'safedb/common/AddSiteadminAccount';
// 인증키
import Apikey from 'safedb/common/key/apikey'
import Addapikey from 'safedb/common/key/addapikey'
import Editapikey from 'safedb/common/key/editapikey'
// 암호키
import Encryptkeypolicy from 'safedb/common/key/encryptKeyPolicy'
import Editencryptkeypolicy from 'safedb/common/key/editencryptKeyPolicy'
import Addencryptkeypolicy from 'safedb/common/key/addencryptKeyPolicy'
// 공유키
import Encryptpublickey from 'safedb/common/key/encryptPublicKey'
import Editencryptpublickey from 'safedb/common/key/editencryptPublicKey'
import Addencryptpublickey from 'safedb/common/key/addencryptPublicKey'
// 로그
import LogManage from 'safedb/common/monitoring/logManage'
import LogEncDec from 'safedb/common/monitoring/logEncDec'
// 배포 가이드
import ApiGuide from 'safedb/common/guides/apiGuide';
import SdkDownload from 'safedb/common/guides/SdkDownload';
/////////////////////////////////////////////////////////////////////////
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';

import Starter from 'components/pages/Starter';
import Crm from 'components/dashboards/crm';
// import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import CardLogout from 'components/authentication/card/Logout';
import CardRegistration from 'components/authentication/card/Registration';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import CardLockScreen from 'components/authentication/card/LockScreen';
import Dashboard from 'components/dashboards/default';
import VerticalNavLayout from 'layouts/VerticalNavLayout';
import ComboNavLayout from 'layouts/ComboNavLayout';
import BillingHistory from '../safedb/common/billing/BillingHistory';

const routes = [
  {
    element: <App />,
    children: [
      {
        path: paths.landing,
        element: <Landing />
      },
      {
        path: paths.cardLogin,
        element: <CardLogin />
      },
      {
        path: paths.cardLogout,
        element: <CardLogout />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: rootPaths.authRoot,
        children: [

          {
            path: rootPaths.authCardRoot,
            children: [

              {
                path: paths.cardRegister,
                element: <CardRegistration />
              },

              {
                path: paths.cardForgotPassword,
                element: <CardForgetPassword />
              },
              {
                path: paths.cardResetPassword,
                element: <CardPasswordReset />
              },
              {
                path: paths.cardConfirmMail,
                element: <CardConfirmMail />
              },
              {
                path: paths.cardLockScreen,
                element: <CardLockScreen />
              }
            ]
          },

        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: rootPaths.dashboardRoot,
            children: [

              {
                path: paths.crm,
                element: <Crm />
              },


            ]
          },

          {
            path: rootPaths.userRoot,
            children: [

              {
                path: paths.userSettings,
                element: <Settings />
              }
            ]
          },

          {
            path: rootPaths.pagesRoot,
            children: [
              {
                path: paths.starter,
                element: <Starter />
              }
            ]
          },
          {
            path: rootPaths.safedbRoot,
            children: [
              {
                path: rootPaths.safeCommonRoot,
                children: [
                  {
                    path: paths.myPage,
                    element: <MyPage />
                  },
                  {
                    path: paths.chgPwd,
                    element: <ChgPwd />
                  },
                  {
                    path: paths.SiteInfo,
                    element: <SiteInfo />
                  },
                  {
                    path: rootPaths.AccountRoutes,
                    children: [
                      {
                        path: paths.SiteAdminAccount,
                        element: <SiteAdminAccount />
                      },
                      {
                        path: paths.EditSiteAdminAccount,
                        element: <EditSiteAdminAccount />
                      },
                      {
                        path: paths.AddSiteAdminAccount,
                        element: <AddSiteAdminAccount />
                      },
                    ]
                  },
                  {
                    path: rootPaths.keyRoot,
                    children: [
                      {
                        path: paths.apikey,
                        element: <Apikey />
                      },
                      {
                        path: paths.addapikey,
                        element: <Addapikey />,
                      },
                      {
                        path: paths.editapikey,
                        element: <Editapikey />,
                      },
                      {
                        path: paths.encryptKeyPolicy,
                        element: <Encryptkeypolicy />
                      },
                      {
                        path: paths.editencryptKeyPolicy,
                        element: <Editencryptkeypolicy />
                      },
                      {
                        path: paths.addencryptKeyPolicy,
                        element: <Addencryptkeypolicy />
                      },
                      {
                        path: paths.encryptPublicKey,
                        element: <Encryptpublickey />
                      },
                      {
                        path: paths.editencryptPublicKey,
                        element: <Editencryptpublickey />
                      },
                      {
                        path: paths.addencryptPublicKey,
                        element: <Addencryptpublickey />
                      },
                    ]
                  },
                  {
                    path: rootPaths.monitorRoot,
                    children: [
                      {
                        path: paths.logManage,
                        element: <LogManage />
                      },
                      {
                        path: paths.logEncDec,
                        element: <LogEncDec />,
                      },
                    ]
                  },
                  {
                    path: rootPaths.billingRoot,
                    children: [
                      {
                        path: paths.billing,
                        element: <BillingHistory />
                      }
                    ]
                  },
                  {
                    path: rootPaths.guideRoot,
                    children: [
                      {
                        path: paths.apiGuide,
                        element: <ApiGuide />
                      },
                      {
                        path: paths.SdkDownload,
                        element: <SdkDownload />
                      },
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: rootPaths.safedbRoot,
            children: [
              {
                path: rootPaths.SARoot,
                children: [
                  {
                    path: paths.adminAccount,
                    element: <AdminAccount />
                  },
                  {
                    path: paths.addadminAccount,
                    element: <AddAdminAccount />
                  },
                  {
                    path: paths.editadminAccount,
                    element: <EditAdminAccount />
                  },
                  {
                    path: paths.policyManager,
                    element: <PolicyManager />
                  },
                  {
                    path: paths.logAdmin,
                    element: <LogAdmin />
                  },
                  {
                    path: paths.adminSites,
                    element: <AdminSites />
                  },
                  {
                    path: paths.addadminSites,
                    element: <AddadminSite />
                  },
                  {
                    path: paths.editadminsite,
                    element: <EditadminSite />
                  },
                ]
              }
            ]
          }


        ]
      },
      {
        path: '/',
        element: <VerticalNavLayout />,
        children: [
          {
            path: paths.verticalNavLayout,
            element: <Dashboard />
          }
        ]
      },

      {
        path: '/',
        element: <ComboNavLayout />,
        children: [
          {
            path: paths.comboNavLayout,
            element: <Dashboard />
          }
        ]
      },



      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      },

    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;

