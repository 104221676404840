import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import 'react-toastify/dist/ReactToastify.css'; // 추가
import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
  handleValueDelete,
  goToParentPath
} from 'components/common/SunnyUtils';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import {
  updateCompanyInfo,
  ImagesUpload,
  deleteCompanyInfo
} from 'hooks/CallApi'; // API 함수 임포트
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import styled from 'styled-components';

const EditadminSite = () => {
  const {
    config: { userAuth }
  } = useAppContext();

  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalDeleteCheckShow, setModalDeleteCheckShow] = useState(false);
  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [isChevronUp, setIsChevronUp] = useState(false); // 상태 변수 추가
  const toggleKeyPart = () => {
    setKeyPart(!KeyPart);
    setIsChevronUp(!isChevronUp); // 아이콘 상태 전환
  };
  const [KeyPart, setKeyPart] = useState(true);
  const [NameError, setNameError] = useState('');
  const [MailError, setMailError] = useState('');
  const [AddrError, setAddrError] = useState('');
  const [NameBlankError, setNameBlankError] = useState(false);
  const [DescError, setDescError] = useState('');
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      setIsHovered(true);
    }
  };
  const handleMouseLeave = () => setIsHovered(false);
  const [isInvalid, setIsInvalid] = useState(false); // 유효성 검사 상태

  const location = useLocation();

  const {
    createdAt,
    updateAt,
    imgUrl,
    active,
    description,
    clientName,
    clientEmail,
    clientPhone,
    clientAddress,
    companyId
  } = location.state || {}; // 추가 데이터 포함

  const initialFormData = {
    createdAt: createdAt,
    updateAt: updateAt,
    clientName: clientName,
    clientEmail: clientEmail,
    clientPhone: clientPhone,
    clientAddress: clientAddress,
    description: description,
    imgUrl: imgUrl,
    active: active,
    clientSecret: '',
    clientKey: '',
    clientIV: '',
    companyId: companyId
  };

  const [formData, setFormData] = useState({
    createdAt: createdAt,
    updateAt: updateAt,
    clientName: clientName,
    clientEmail: clientEmail,
    clientPhone: clientPhone,
    clientAddress: clientAddress,
    description: description,
    imgUrl: imgUrl,
    active: active,
    clientSecret: '',
    clientKey: '',
    clientIV: '',
    companyId: companyId
  });

  const handleConfirm = async () => {
    try {
      const response = await updateCompanyInfo(formData.companyId, {
        imgUrl: formData.imgUrl,
        name: formData.clientName,
        tel: formData.clientPhone,
        email: formData.clientEmail,
        address: formData.clientAddress,
        description: formData.description
      });

      if (response) {
        setModalConfirmShow(false);
        setModalConfirmCheckShow(true);
      } else {
        // toast.error('회사 정보 업데이트가 실패했습니다.');
        alert('회사 정보 업데이트가 실패했습니다.');
      }
    } catch (error) {
      // toast.error('회사 정보 업데이트 중 오류가 발생했습니다.');
      alert('회사 정보 업데이트 중 오류가 발생했습니다.');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCompanyInfo(formData.companyId); // 삭제 API 호출
      setModalDeleteCheckShow(true); // 삭제 확인 모달
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
    }
  };

  const navigate = useNavigate(); // useNavigate 훅 사용

  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([{ src: imgUrl || avatarImg }]);
  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });

    // 각각의 에러
    if (name === 'clientEmail') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setMailError('올바른 이메일 형식이 아닙니다.');
      } else if (value.length >= 50) {
        setMailError('고객사 메일은 최대 50자까지 입력 가능합니다.');
      } else {
        setMailError('');
      }
    }

    if (name === 'clientName') {
      if (value.length >= 50) {
        setNameError(`고객사명은 최대 50자까지 입력 가능합니다.`);
      } else {
        setNameError('');
      }
    }

    if (name === 'clientAddress') {
      if (value.length >= 100) {
        setAddrError(`고객사 주소는 최대 100자까지 입력 가능합니다.`);
      } else {
        setAddrError('');
      }
    }

    if (name === 'description') {
      if (value.length >= 800) {
        setDescError(`고객사 설명란 입력은 최대 800자까지 입력 가능합니다.`);
      } else {
        setDescError('');
      }
    }

    if (name === 'clientPhone') {
      // 숫자만 남기고 포맷팅
      const onlyNumbers = value.replace(/[^0-9]/g, '');

      let formattedPhone = onlyNumbers;

      if (onlyNumbers.startsWith('02')) {
        // 1. 지역번호가 두자리 (02)
        if (onlyNumbers.length === 9) {
          formattedPhone = onlyNumbers.replace(
            /(\d{2})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XX-XXX-XXXX
        } else if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{2})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XX-XXXX-XXXX
        }
      } else if (onlyNumbers.startsWith('010')) {
        // 2. 핸드폰 번호 (010)
        if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXX-XXXX
        } else if (onlyNumbers.length === 11) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXXX-XXXX
        }
      } else {
        // 3. 일반 3자리 지역번호
        if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXX-XXXX
        } else if (onlyNumbers.length === 11) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXXX-XXXX
        }
      }

      setFormData(prev => ({
        ...prev,
        clientPhone: formattedPhone
      }));

      // 유효성 검사
      const phoneRegex = /^[0-9-]*$/;
      if (!phoneRegex.test(value)) {
        setIsInvalid(true); // 허용되지 않은 문자가 있는 경우 에러
      } else if (formattedPhone.length >= 20) {
        setIsInvalid(true); // 20자를 넘는 경우 에러
      } else {
        setIsInvalid(false); // 유효성 검사 통과
      }
      return; // 다른 입력 필드 검사를 건너뛴다.
    }
  };

  const formatDateTimeForDisplay = dateString => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // UTC를 로컬로 변환

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  };


  const onDrop = async acceptedFiles => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          // toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
          alert('이미지 크기는 최대 5MB까지 허용됩니다.');

          return;
        }

        try {
          const uploadResponse = await ImagesUpload(imageFile, 'company'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {
            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData(prev => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl // Set the uploaded URL in formData
            }));
            // setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화
  });

  const isFormValid = () => {
    const requiredFields = ['clientName', 'clientEmail', 'clientAddress'];
    const allRequiredFieldsFilled = requiredFields.every(
      field => formData[field]?.trim().length > 0
    );

    const phoneRequiredFieldsFilled = formData.clientPhone.trim().length > 7;
    const isFormChanged = Object.keys(formData).some(
      key => formData[key] !== initialFormData[key]
    );

    return (
      allRequiredFieldsFilled &&
      phoneRequiredFieldsFilled &&
      isFormChanged &&
      !isInvalid
    );
  };
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          고객사 관리
        </Card.Header>

        <Card.Title className=" px-3 pt-3"> 상세 정보</Card.Title>
        <Card.Body>
          <Form
            onSubmit={e => {
              e.preventDefault();
              setModalConfirmShow(true);
            }}
          >
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor:
                      userAuth === 'SA' || userAuth === 'ADMIN'
                        ? 'pointer'
                        : 'not-allowed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto'
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />

                  <Profile>
                    <Image
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                    />
                    <HoverView>이미지 변경</HoverView>
                  </Profile>

                </div>

              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        고객사명<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientName"
                          value={formData.clientName}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="50"
                        // placeholder="아이디를 입력해주세요."
                        />
                        {formData.clientName && (
                          <span
                            onClick={e => {
                              handleValueDelete(
                                'clientName',
                                formData,
                                setFormData
                              );
                              handleChange({
                                target: { name: 'clientName', value: '' }
                              }); // handleChange를 직접 호출
                            }}
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '52%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {NameError && (
                      <span className="text-danger">{NameError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        고객사 연락처<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        {/* <InputGroup> */}
                        <Form.Control
                          type="phone"
                          name="clientPhone"
                          isInvalid={isInvalid}
                          value={formData.clientPhone}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="20"
                        // placeholder="비밀번호를 입력해주세요."
                        />

                        {formData.clientPhone && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientPhone',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: isInvalid ? '41%' : '50%',
                              // top: '50%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        고객사 메일<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientEmail"
                          value={formData.clientEmail}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="50"

                        // placeholder="이름을 입력해주세요."
                        />

                        {formData.clientEmail && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientEmail',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '52%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {MailError && (
                      <span className="text-danger">{MailError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>
                        고객사 주소<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientAddress"
                          value={formData.clientAddress}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="100"

                        // placeholder="이름을 입력해주세요."
                        />

                        {formData.clientAddress && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientAddress',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '52%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {AddrError && (
                      <span className="text-danger">{AddrError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        // type="datetime-local"
                        type="text"
                        name="addDate"
                        value={formatDateTimeForDisplay(formData.createdAt)}
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>수정 일시</Form.Label>
                      <Form.Control
                        // type="datetime-local"
                        type="text"
                        name="editDate"
                        value={formatDateTimeForDisplay(formData.updateAt)}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ display: 'none' }}>
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: 'lightgray',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>
                      <h5>[{formData.clientName}] KEY 관리</h5>
                    </span>
                    <button
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                      onClick={toggleKeyPart} // 상태 변경 함수 호출
                    >
                      {isChevronUp ? <BsChevronDown /> : <BsChevronUp />}{' '}
                      {/* 아이콘 상태에 따라 표시 */}
                    </button>
                  </div>
                  {KeyPart && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '2px',
                        borderStyle: 'dashed'
                      }}
                      className="p-2"
                    >
                      <div style={{ flexGrow: 1 }} className="mb-4">
                        <Row className="align-items-center">
                          <Col md={9}>
                            <Form.Group
                              className="mb-3"
                              style={{ position: 'relative' }}
                            >
                              <Form.Label>
                                [{formData.clientName}] SECRET
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="clientSecret"
                                value={formData.clientSecret}
                                onChange={handleChange}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label style={{ opacity: 0 }}>
                                버튼
                              </Form.Label>
                              <Button variant="secondary " className="w-100">
                                SECRET 재발급
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col md={9}>
                            <Form.Group
                              className="mb-3"
                              style={{ position: 'relative' }}
                            >
                              <Form.Label>암호용 KEY</Form.Label>
                              <Form.Control
                                type="text"
                                name="clientKey"
                                value={formData.clientKey}
                                onChange={handleChange}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group
                              className="mb-3"
                              style={{ position: 'relative' }}
                            >
                              <Form.Label style={{ opacity: 0 }}>
                                버튼
                              </Form.Label>
                              <Button
                                variant="secondary "
                                className="w-100 p-0"
                                style={{ height: 36, fontSize: 16 }}
                              >
                                암호용KEY/Ⅳ 재발급
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                            <Form.Group
                              className="mb-3"
                              style={{ position: 'relative' }}
                            >
                              <Form.Label>암호용 Ⅳ</Form.Label>
                              <Form.Control
                                type="text"
                                name="clientIV"
                                value={formData.clientIV}
                                onChange={handleChange}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows={3}
                      value={formData.description}
                      onChange={handleChange}
                      className="input-hover"
                      maxLength="800"
                    />
                    {formData.description && (
                      <span
                        onClick={e =>
                          handleValueDelete(
                            'description',
                            formData,
                            setFormData
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-14px)',
                          zIndex: 9
                        }}
                      >
                        <AiFillCloseCircle />
                      </span>
                    )}
                  </div>
                  {DescError && <div style={{ color: 'red' }}>{DescError}</div>}{' '}
                  {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* 키영역 복구 시 pt 빼도됨*/}
            <div className="d-flex justify-content-between mt-3 pt-8">
              <Button
                variant="outline-danger"
                onClick={() => setModalDeleteShow(true)}
                style={{ width: '7rem' }}
              >
                삭제
              </Button>
              <div>
                {/* <Button className="mx-2" variant="secondary" onClick={handleListClick} style={{ width: '7rem' }}>
                  목록
                </Button> */}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isFormValid()}
                  style={{ width: '7rem' }}
                >
                  변경
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalDeleteShow}
        onHide={() => setModalDeleteShow(false)}
        onConfirm={handleDelete}
        title="경고"
        variant="warning"
        body="선택하신 고객사에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 정말 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="확인"
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalDeleteCheckShow}
        onHide={() => {
          setModalDeleteShow(false);
          setModalDeleteCheckShow(false);
        }}
        variant="success"
        onConfirm={() => {
          setModalDeleteShow(false);
          setModalDeleteCheckShow(false);
          navigate(`/safedb/SA/adminSites`);
        }}
        title="확인" // 제목
        confirmText="확인"
        body="삭제가 완료되었습니다." // 본문 내용
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인"
        variant="info"
        body="고객사 정보를 변경하시겠습니까?"
        cancelText="취소"
        confirmText="확인"
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => {
          setModalConfirmCheckShow(false);
          navigate(`/safedb/SA/adminSites`);
        }}
        variant="success"
        onConfirm={() => {
          setModalConfirmCheckShow(false);
          navigate(`/safedb/SA/adminSites`);
        }}
        title="완료" // 제목
        body="변경이 완료 되었습니다." // 본문 내용
        confirmText="확인"
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />
    </div>
  );
};

export default EditadminSite;

const Profile = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const HoverView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: all 0.1s;
  background-color: #2229;
  user-select: none;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;
