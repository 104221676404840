import React, { useState, useEffect } from 'react';

import { Card, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';

// import data from 'safedb/common/sampledata/sampledataApi'; // data.js 파일에서 데이터 가져오기

import { DownloadSDK } from 'hooks/CallApi'; // API 함수 가져오기
import { formatDate } from 'components/common/SunnyUtils';

const columns = [
  {
    accessorKey: 'deployAt',
    header: '배포일시',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'version',
    header: '모듈 버전',
    meta: { headerProps: { className: 'text-900' } }
  },
  {
    accessorKey: 'download',
    header: 'SDK 다운로드',
    meta: { headerProps: { className: 'text-900' } },
    cell: ({ row }) => (
      <a href={row.original.link} target="_blank" rel="noopener noreferrer">
        {row.original.download}
      </a>
    ) // 하이퍼링크로 변환
  }
];

function AdvanceTableExample() {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const [tableData, setTableData] = useState([]); // 데이터 상태 추가
  const [formData, setFormData] = useState({ adderId: '' });
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정

  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)


  const table = useAdvanceTable({
    // data, // 전체 데이터를 전달
    data: tableData, // 상태에서 데이터 가져오기
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });
  // API 호출 함수
  const loadData = async (page, size) => {
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const response = await DownloadSDK(page, size); // API 호출
      const logData = response.data.content.map((item, index) => ({
        // num: index + 1 + page * size, // 순번 계산
        deployAt: item.deployTime
          ? formatDate(item.deployTime) // 포맷 함수 호출
          : '-',
        version: item.moduleVersion,
        download: item.fileName,
        link: item.fileLink
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {

    loadData(currentPage, perPage);
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };


  return (
    <>
      <AdvanceTableProvider {...table}>
        {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          className="mb-2"
        >


        </div>
        {loading ? (
          showSpinner ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <p></p>
          )
        ) : (
          <AdvanceTable
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          // onRowClick={handleRowClick} // 이벤트 핸들러 전달
          />
        )}
        <div className="mt-3">
          <p style={{ margin: 0, fontWeight: 700 }}>
            총 {totalElements.toLocaleString()}건
          </p>
          {/* <AdvanceTablePagination /> */}
          <AdvanceTablePagination
            currentPage={currentPage}
            totalItems={totalElements}
            itemsPerPage={perPage}
            onChange={newPage => handlePageChange(newPage)}
          />
        </div>
      </AdvanceTableProvider>

    </>
  );
}

const SdkDownload = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">SDK 배포</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default SdkDownload;
