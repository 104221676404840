import React from 'react';
import Wizard from 'components/wizard/Initech_WizardForms';
import FooterStandard from './FooterStandard';

const Landing = () => {
  return (
    <>
      {/* <NavbarStandard /> */}
      {/* <Hero /> */}
      {/* <Partners /> */}
      {/* <Processes /> */}
      {/* <Services /> */}
      {/* <Testimonial /> */}
      <Wizard />
      <FooterStandard />
    </>
  );
};

export default Landing;
