import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { policyPeriodRank } from 'hooks/CallApi'; // API 함수 import

const SiteIdleAverage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    companyNames: [],
    unUsedRates: [],
    unUsedPeriods: []
  });

  // 데이터 로드 함수
  const fetchData = async () => {
    try {
      setIsLoading(true); // 로딩 상태 활성화

      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await policyPeriodRank({ timeZone: clientTimeZone });
      const apiData = response.data;

      // 데이터 포맷팅
      setChartData({
        companyNames: apiData.map((item) => item.companyName),
        unUsedRates: apiData.map((item) => item.unUsedPer),
        unUsedPeriods: apiData.map((item) => item.unUsedPeriod)
      });
    } catch (error) {
      console.error("API 데이터 호출 실패:", error);
    } finally {
      setIsLoading(false); // 로딩 상태 비활성화
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사별 암호키 유휴 기간 평균</h5>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          // 로딩 중일 때 카드 내부에 스피너 표시
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          // 차트 렌더링
          <IdlePeriodChart
            companyNames={chartData.companyNames}
            unUsedRates={chartData.unUsedRates}
            unUsedPeriods={chartData.unUsedPeriods}
          />
        )}
      </Card.Body>
    </Card>
  );
};

// 차트 렌더링 컴포넌트
const IdlePeriodChart = ({ companyNames, unUsedRates, unUsedPeriods }) => {
  useEffect(() => {
    const chartDom = document.getElementById('idle-period-chart');
    if (!chartDom) {
      console.error("차트를 렌더링할 DOM 요소가 존재하지 않습니다.");
      return;
    }

    const idlePeriodChart = echarts.init(chartDom);

    // 차트 옵션
    const option = {
      title: {
        left: 'center',
        top: '2%',
        textStyle: {
          fontSize: 14,
          color: '#333',
        },
      },
      grid: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '15%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let tooltipText = `${params[0].name}<br/>`;

          params.forEach((param) => {
            if (param.seriesName === '유휴 비율 (%)') {
              tooltipText += `${param.marker} 유휴 비율: ${param.value} %<br/>`;
            } else if (param.seriesName === '평균 유휴 기간 (일)') {
              tooltipText += `${param.marker} 평균 유휴 기간: ${param.value} 일<br/>`;
            }
          });

          return tooltipText;
        },
      },
      legend: {
        data: ['유휴 비율 (%)', '평균 유휴 기간 (일)'],
        bottom: '0.1%',
      },
      xAxis: {
        type: 'category',
        data: companyNames,
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: '유휴 비율 (%)',
          position: 'left',
          splitLine: { show: false },
          axisLabel: {
            formatter: '{value} %',
          },
        },
        {
          type: 'value',
          name: '평균 유휴 기간 (일)',
          position: 'right',
          splitLine: { show: false },
          axisLabel: {
            formatter: '{value} 일',
          },
        },
      ],
      series: [
        {
          name: '유휴 비율 (%)',
          type: 'bar',
          data: unUsedRates,
          itemStyle: {
            color: 'lightgrey',
          },
          label: {
            show: true,
            position: 'insideRight',
            formatter: '{c} %',
            fontSize: 12,
            color: '#fff',
          },
        },
        {
          name: '평균 유휴 기간 (일)',
          type: 'line',
          yAxisIndex: 1,
          data: unUsedPeriods,
          itemStyle: {
            color: '#4682B4',
          },
          label: {
            show: false,
          },
        },
      ],
    };

    idlePeriodChart.setOption(option);

    const handleResize = () => {
      idlePeriodChart.resize();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      idlePeriodChart.dispose();
    };
  }, [companyNames, unUsedRates, unUsedPeriods]);

  return <div id="idle-period-chart" style={{ width: '100%', height: '20rem' }}></div>;
};

export default SiteIdleAverage;
