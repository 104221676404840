// hooks/useIdleTimer.js
import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useIdleTimer = (timeout = 2 * 60 * 60 * 1000) => {
  const navigate = useNavigate();
  const logoutTimer = useRef();

  const handleLogout = useCallback(() => {
    sessionStorage.clear(); // 세션 스토리지에서 토큰 제거
    // toast.info('비활동으로 인해 자동 로그아웃되었습니다.');
    alert('비활동으로 인해 자동 로그아웃되었습니다.');
    navigate('/login');
  }, [navigate]);

  const startTimer = useCallback(() => {
    logoutTimer.current = setTimeout(() => {
      handleLogout();
    }, timeout);
  }, [timeout, handleLogout]);

  const resetTimer = useCallback(() => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    startTimer();

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      if (logoutTimer.current) clearTimeout(logoutTimer.current);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [resetTimer, startTimer]);

  return handleLogout; // 수동 로그아웃 시 handleLogout 호출
};

export default useIdleTimer;
