import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import { useNavigate } from 'react-router-dom';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faChartBar } from '@fortawesome/free-solid-svg-icons';
import AdminPasswordAlertModal from 'components/modal/AdminPasswordAlertModal';
import { useAppContext } from 'providers/AppProvider';
import {
  requestSiteToken,
  fetchCompanyList,
  deleteMultiCompanyInfo
} from 'hooks/CallApi'; // API 함수 가져오기
import { formatDate } from 'components/common/SunnyUtils';

function AdvanceTableExample() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)

  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const {
    config: { checkorg, siteToken, userId, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();

  const columns = [
    {
      accessorKey: 'num',
      header: '순번',
      meta: { maxlength: 3, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientName',
      header: '고객사명',
      meta: { maxlength: 15, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientPhone',
      header: '고객사연락처',
      meta: { maxlength: 20, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientEmail',
      header: '고객사이메일',
      meta: { maxlength: 40, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'clientAddress',
      header: '고객사주소',
      meta: { maxlength: 50, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'createdAt',
      header: '등록일시',
      meta: { maxlength: 19, headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'dashboard',
      header: '관리',
      cell: ({ row }) => (
        <Button
          variant="link"
          id={`status-switch-${row.id}`}
          onClick={event => {
            event.stopPropagation(); // RowClick 이벤트 전파 방지
            handleStatusChange(row);
          }}
        >
          <FontAwesomeIcon icon={faChartBar} />
        </Button>
      ),
      meta: { headerProps: { className: 'text-900' } }
    }

  ];

  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });

  // API 호출 함수
  const loadData = async (page, size) => {
    // console.log("Fetching data with page:", page, "and size:", size);
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const response = await fetchCompanyList(page, size); // API 호출
      const logData = response.data.content.map((item, index) => ({
        // num: index + 1 + page * size, // 순번 계산
        num: page * size + index + 1, // 역순으로 순번 계산
        createdAt: item.insertTimeUtc
          ? formatDate(item.insertTimeUtc) // 포맷 함수 호출
          : '-',
        updateAt: item.updateTimeUtc
          ? formatDate(item.updateTimeUtc) // 포맷 함수 호출
          : '-',

        imgUrl: item.imgUrl,
        active: item.active,
        description: item.description,
        clientName: item.name, // 고객사명
        clientEmail: item.email, // 고객사 이메일
        clientPhone: item.tel, // 고객사 연락처
        clientAddress: item.address, // 고객사 주소
        companyId: item.companyId
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    setConfig('checkorg', '');
    setConfig('isNavbarVerticalCollapsed', true);
    loadData(currentPage, perPage);
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };

  const handlePerPageChange = newPerPage => {
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
  };

  const handleRowClick = row => {
    const {
      createdAt,
      updateAt,
      imgUrl,
      active,
      description,
      clientName,
      clientEmail,
      clientPhone,
      clientAddress,
      companyId
    } = row.original; // 필요한 데이터 추출
    navigate(`/safedb/SA/adminSites/edit`, {
      state: {
        createdAt,
        updateAt,
        imgUrl,
        active,
        description,
        clientName,
        clientEmail,
        clientPhone,
        clientAddress,
        companyId
      } // 전달할 데이터
    });
  };

  const handleDeleteClick = () => {
    setModalShow(true);
  };

  const handleConfirmDelete = async () => {
    // setPasswordModalShow(true);
    const selectedCompanyIds = table
      .getSelectedRowModel()
      .rows.map(row => row.original.companyId); // 선택된 행의 companyId 가져오기

    try {
      await deleteMultiCompanyInfo(selectedCompanyIds); // 삭제 API 호출
      setModalReShow(true);

      table.resetRowSelection(); // 선택 초기화
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
    }
    setModalShow(false);

  };

  const handleConfirmPassword = () => {
    setPasswordModalShow(false);
    setModalReShow(true);
  };

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDeleteRe = () => {
    setModalReShow(false);
    navigate('/safedb/SA/adminSites');
  };

  const handleStatusChange = async row => {
    const { companyId, clientName } = row.original;
    try {
      const refreshToken = sessionStorage.getItem('refreshToken');
      const impersonationData = {
        username: userId,
        refreshToken,
        companyId
      };
      const { token } = await requestSiteToken(impersonationData);

      sessionStorage.setItem('siteToken', token); // 새로 발급받은 토큰을 세션에 저장
      setConfig('checkorg', clientName); // 선택된 고객사 이름 설정
      setConfig('checkorg_companyId', companyId); // 선택된 고객사 이름 설정
      setConfig('isNavbarVerticalCollapsed', false); // 선택된 고객사 이름 설정

      navigate('/');
    } catch (error) {
      console.error('관리 모드로 전환하는데 실패했습니다:', error);
    }
  };
  return (
    <AdvanceTableProvider {...table}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        className="mb-2"
      >

        <AdvanceTableFooter
          rowsPerPageSelection
          onPageChange={(page, newPageSize) => {
            handlePerPageChange(newPageSize); // 페이지 크기 변경
            setCurrentPage(page); // 현재 페이지 설정
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8
          }}
        >
          <Button
            variant="success"
            style={{
              width: '100px'
            }}
            disabled={selectedRowCount === 0}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              style={{ marginRight: '5px' }}
            />
            Excel
          </Button>
          {selectedRowCount > 0 ? (
            <Button
              variant="outline-danger"
              style={{
                width: '100px'
              }}
              onClick={handleDeleteClick}
            >
              삭제
            </Button>
          ) : (
            <Link to={paths.addadminSites} style={{ textDecoration: 'none' }}>
              <Button
                variant="outline-success"
                style={{
                  width: '100px'
                }}
              // disabled
              >
                등록
              </Button>
            </Link>
          )}
        </div>
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
          onRowClick={handleRowClick}
        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>
          총 {totalElements.toLocaleString()}건
        </p>
        {/* <AdvanceTablePagination /> */}
        <AdvanceTablePagination
          currentPage={currentPage}
          totalItems={totalElements}
          itemsPerPage={perPage}
          onChange={newPage => handlePageChange(newPage)}
        />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirmDelete}
        title="경고"
        body="선택하신 고객사에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 정말 삭제하시겠습니까?"
        cancelText="취소"
        confirmText="삭제"
        variant="warning"
        CancelButton={true}
        SubmitButton={true}
      />
      <AdminPasswordAlertModal
        show={passwordModalShow}
        onHide={() => setPasswordModalShow(false)}
        onConfirm={handleConfirmPassword}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료"
        body="삭제가 완료 되었습니다."
        confirmText="확인"
        variant="success"
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />
    </AdvanceTableProvider>
  );
}

const adminSites = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">고객사 관리</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default adminSites;
