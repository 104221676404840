import React, { useState } from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const BillingTable = ({ data, loading }) => {
    const [showAll, setShowAll] = useState(false);
    const INITIAL_DISPLAY_COUNT = 8;

    if (loading) {
        return (
            <div className="text-center py-4">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    const displayData = showAll ? data : data.slice(0, INITIAL_DISPLAY_COUNT);

    return (
        <>
            <Table responsive striped hover>
                <thead>
                    <tr>
                        <th>날짜</th>
                        <th>서비스</th>
                        <th>사용량</th>
                        <th className="text-end">금액 (원)</th>
                    </tr>
                </thead>
                <tbody>
                    {displayData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item.service}</td>
                            <td>{item.usage.toLocaleString()}</td>
                            <td className="text-end">{item.amount.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {data.length > INITIAL_DISPLAY_COUNT && (
                <div className="text-center py-2">
                    <Button
                        variant="link"
                        onClick={() => setShowAll(!showAll)}
                        className="text-decoration-none"
                    >
                        {showAll ? (
                            <>
                                <FontAwesomeIcon icon={faChevronUp} className="me-1" />
                                접기
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faChevronDown} className="me-1" />
                                더보기 ({data.length - INITIAL_DISPLAY_COUNT}건)
                            </>
                        )}
                    </Button>
                </div>
            )}
        </>
    );
};

export default BillingTable; 