import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Modal, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillCloseCircle
} from 'react-icons/ai';
import { settings } from 'config'; // getSettings를 임포트합니다.
import { handleValueDelete } from 'components/common/SunnyUtils';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import { loginUser, getAdminInfo } from 'hooks/CallApi'; // API 함수 가져오기
import FirstChangePasswordModal from 'safedb/common/FirstChangePasswordModal';
import avatarImg from 'assets/img/team/avatar.png';
import avatarImg_SA from 'assets/img/initech_ci.png';

const LoginForm = ({ hasLabel = false, layout = 'simple' }) => {
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    id: '',
    password: '',
    isIdEmpty: false,
    isPasswordEmpty: false
  });

  const { config, setConfig } = useAppContext();

  const [showModal, setShowModal] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(''); // Modal message
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태
  const [PwMessage, setPwMessage] = useState('');
  const openChangePasswordModal = () => setShowPasswordModal(true); // 비밀번호 변경 모달 열기 함수
  const [isKorean, setIsKorean] = useState(false);
  const [isComposing, setIsComposing] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      if (config.navvalue === 'SA') {
        navigate('/dashboard/crm');
      } else if (config.navvalue) {
        navigate('/');
      }
    }
  }, [config.navvalue, isLoggedIn]); // Use config.userAuth

  const [pwType, setpwType] = useState({
    type: 'password',
    visible: false
  });

  useEffect(() => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('siteToken');
    sessionStorage.removeItem('refreshToken');
  }, []); // 빈 배열로 설정해 첫 렌더링 시 한 번만 실행

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    const { id, password } = formData;

    const isIdEmpty = !id;
    const isPasswordEmpty = !password;

    setFormData(prev => ({
      ...prev,
      isIdEmpty,
      isPasswordEmpty
    }));

    // if (!id || !password) {
    //   toast.error('ID와 비밀번호를 입력해 주세요.');
    //   return;
    // }

    if (isIdEmpty || isPasswordEmpty) {
      return; // 에러 메시지 표시 후 처리 중단
    }
    try {
      const { token, refreshToken } = await loginUser({
        username: id,
        password
      });

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('refreshToken', refreshToken);

      const { adminInfo, isPasswordChangeNeeded, changeReasonCode } =
        await getAdminInfo();

      if (isPasswordChangeNeeded) {
        let message = '';
        if (changeReasonCode === -5) {
          message =
            '보안 정책에 따라 계정의 비밀번호는 [N]일 마다 변경해야합니다. ';
          // setPwMessage('보안 정책에 따라 계정의 비밀번호는 [N]일 마다 변경해야합니다. ')
        } else if (changeReasonCode === -6) {
          message =
            'SafeDB Cloud를 이용해주셔서 감사합니다. 보안을 위해 최초 접속시 비밀번호를 변경해 주십시오. ';
          // setPwMessage('SafeDB Cloud를 이용해주셔서 감사합니다. 보안을 위해 최초 접속시 비밀번호를 변경해주세요. ')
        }
        setPwMessage(message); // 먼저 메시지 설정
        setConfig('userId', formData.id);
        setShowPasswordModal(true);
        return;
      }
      let setNav = adminInfo.data.userRole;
      const setting = settings(setNav); // navValue에 따라 설정 가져오기

      const imgUrl =
        adminInfo.data.imgUrl && adminInfo.data.imgUrl.trim() !== ''
          ? adminInfo.data.imgUrl
          : setNav === 'SA'
          ? avatarImg_SA
          : avatarImg;

      const collapsed = setNav === 'SA' ? true : false;

      setConfig('navvalue', setNav);
      setConfig('userAuth', setting.userAuth);
      setConfig('userId', formData.id);
      setConfig('userAuth_K', setting.userAuth_K);
      setConfig('userName', adminInfo.data.username);
      setConfig(
        'checkorg',
        adminInfo.data.userRole === 'SA' ? '' : adminInfo.data.companyName
      );
      setConfig('navbarPosition', setting.navbarPosition);
      setConfig('firstLogin', setting.firstLogin);
      setConfig('userUuid', adminInfo.data.userIds.userUuid);
      setConfig('checkorg_companyId', adminInfo.data.userIds.companyId); // 선택된 고객사 이름 설정
      setConfig('userAvatar', imgUrl); // 기본 이미지 설정 완료
      setConfig('isNavbarVerticalCollapsed', collapsed);

      if (adminInfo.data.active) {
        setIsLoggedIn(true);
      } else {
        alert('로그인 실패, 계정이 만료되었습니다.');
      }
    } catch (error) {
      alert('아이디 또는 비밀번호가 일치하지 않습니다. 다시 확인해주세요');
    }
  };
  const handleFieldChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleClose = () => setShowModal(false); // Close modal

  const handlePasswordType = e => {
    setpwType(() => {
      // 만약 현재 pwType.visible이 false 라면
      if (!pwType.visible) {
        return { type: 'text', visible: true };

        //현재 pwType.visible이 true 라면
      } else {
        return { type: 'password', visible: false };
      }
    });
  };
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = e => {
    setIsComposing(false);
    const { name, value } = e.target;
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
      e.target.value = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
      setFormData(prev => ({ ...prev, [name]: e.target.value }));
    }
  };

  const handleInput = e => {
    if (!isComposing) {
      const { name, value } = e.target;
      if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
        e.target.value = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
        setFormData(prev => ({ ...prev, [name]: e.target.value }));
      }
    }
  };

  const resetParentForm = () => {
    setFormData(prev => ({
      ...prev,
      password: '' // 비밀번호 초기화
    }));
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {hasLabel && (
            <Form.Label>
              ID<span className="text-danger">*</span>
            </Form.Label>
          )}
          <div style={{ position: 'relative' }}>
            <Form.Control
              placeholder={!hasLabel ? 'ID' : 'ID를 입력해 주세요.'}
              value={formData.id}
              name="id"
              onChange={handleFieldChange}
              type="text"
              className={`${
                formData.isIdEmpty ? 'is-invalid input-hover' : 'input-hover'
              }`}
              style={{
                paddingRight: '80px', // 아이콘이 들어갈 공간 확보
                // invalid 아이콘 위치 수정 (.form-control:invalid 참고)
                backgroundPosition: formData.id
                  ? 'right calc(1em + 1.1rem) center'
                  : 'right calc(1em + 0rem) center'
              }}
            />
            {formData.id && (
              <span
                onClick={() => handleValueDelete('id', formData, setFormData)}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px', // 아이콘 위치 조정
                  top: '50%',
                  transform: 'translateY(-14px)' // 수직 중앙 정렬
                }}
              >
                <AiFillCloseCircle />
              </span>
            )}
          </div>
          {formData.isIdEmpty && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              ID 입력이 필요합니다.
            </div>
          )}
        </Form.Group>

        <Form.Group className="mb-3" style={{ position: 'relative' }}>
          {hasLabel && (
            <Form.Label>
              Password<span className="text-danger">*</span>
            </Form.Label>
          )}
          <div style={{ position: 'relative' }}>
            <Form.Control
              placeholder={!hasLabel ? 'Password' : '비밀번호를 입력해주세요.'}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type={pwType.type}
              className={`${
                formData.isPasswordEmpty
                  ? 'is-invalid input-hover'
                  : 'input-hover'
              }`}
              style={{
                paddingRight: '80px', // 아이콘이 들어갈 공간 확보
                backgroundPosition: formData.password
                  ? 'right calc(1em + 2.6rem) center'
                  : 'right calc(1em + 0rem) center'
              }}
            />
            {formData.password && (
              <>
                <span
                  onClick={handlePasswordType}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '34px', // 아이콘 위치 조정
                    top: '50%',
                    transform: 'translateY(-14px)' // 수직 중앙 정렬
                  }}
                >
                  {pwType.visible ? <AiFillEye /> : <AiFillEyeInvisible />}
                </span>
                <span
                  onClick={() =>
                    handleValueDelete('password', formData, setFormData)
                  }
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '10px', // 아이콘 위치 조정
                    top: '50%',
                    transform: 'translateY(-14px)' // 수직 중앙 정렬
                  }}
                >
                  <AiFillCloseCircle />
                </span>
              </>
            )}
          </div>
          {formData.isPasswordEmpty && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              비밀번호 입력이 필요합니다.
            </div>
          )}
        </Form.Group>

        <Row className="justify-content-between align-items-center"></Row>

        <Form.Group>
          <Button type="submit" color="primary" className="mt-3 w-100">
            로그인
          </Button>
        </Form.Group>

        {/* <Divider className="mt-4">or log in with</Divider>

        <SocialAuthButtons /> */}
      </Form>

      {/* Modal for messages */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <FirstChangePasswordModal
        show={showPasswordModal}
        onHide={() => {
          setShowPasswordModal(false);

          const passwordField = document.querySelector(
            'input[name="password"]'
          );
          if (passwordField) {
            passwordField.focus(); // 비밀번호 필드에 포커스 설정
          }
        }}
        onResetParentForm={resetParentForm} // 부모 폼 초기화 콜백 전달
        message={PwMessage}
        id={formData.id}
      />
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default LoginForm;
