import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import {
  Card,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { fetchSummaryCounts } from 'hooks/CallApi';
import { formatCountStyle2 } from 'components/common/SunnyUtils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const Summary = ({
  totalRequests = 0,
  encryptionValue = 0,
  decryptionValue = 0,
  encryptionSuccessRatio = 0.95,
  decryptionSuccessRatio = 0.9
}) => {
  const { getThemeColor } = useAppContext();
  const encryptionChartRef = useRef(null);
  const decryptionChartRef = useRef(null);
  const [summaryData, setSummaryData] = useState({
    totalRequests,
    encryptionValue,
    decryptionValue
  });
  const {
    config: {
      dashboardDate,
      dashboardDateValue,
      Chart_startdate,
      Chart_enddate,
      period
    },
    setConfig
  } = useAppContext();
  const [view, setView] = useState('cf'); // 'top' or 'bottom'

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    // 타임존은 고정 값으로, useEffect 바깥에서 한 번만 구합니다.
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchSummaryCounts({
          period: period || dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view
        });

        setSummaryData({
          totalRequests: parseInt(data.data[0].total_cnt) || 0,
          encryptionValue:
            parseInt(data.data[0].encrypt_success_cnt) +
            parseInt(data.data[0].encrypt_fail_cnt) || 0,
          decryptionValue:
            parseInt(data.data[0].decrypt_success_cnt) +
            parseInt(data.data[0].decrypt_fail_cnt) || 0,
          encryptionSuccessRatio:
            parseInt(data.data[0].encrypt_success_cnt) /
            (parseInt(data.data[0].encrypt_success_cnt) +
              parseInt(data.data[0].encrypt_fail_cnt) || 1),
          decryptionSuccessRatio:
            parseInt(data.data[0].decrypt_success_cnt) /
            (parseInt(data.data[0].decrypt_success_cnt) +
              parseInt(data.data[0].decrypt_fail_cnt) || 1)
        });
      } catch (error) {
        console.error('Error fetching summary counts:', error);
      }
    };

    fetchData();
  }, [dashboardDate, view, dateRange, period]);

  // 암호화 및 복호화에 대한 성공 및 실패 처리량 계산
  const encryptionSuccess = Math.round(
    summaryData.encryptionValue * summaryData.encryptionSuccessRatio
  );
  const encryptionFail = summaryData.encryptionValue - encryptionSuccess;
  const decryptionSuccess = Math.round(
    summaryData.decryptionValue * summaryData.decryptionSuccessRatio
  );
  const decryptionFail = summaryData.decryptionValue - decryptionSuccess;

  const isDataEmpty = summaryData.totalRequests === 0;
  const getOption = (
    titleText,
    successValue,
    failValue,
    successColor,
    failColor
  ) => {
    return {
      legend: {
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: getThemeColor('gray-600')
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 3,
            borderColor: getThemeColor('gray-100'),
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'center',
            fontSize: isDataEmpty ? 14 : 10,
            fontWeight: 'bold',
            color: getThemeColor('gray-700'),
            formatter: isDataEmpty
              ? `{b|${titleText}}\n{c|0건}`
              : `{b|${titleText}}\n{c|${formatCountStyle2(
                successValue + failValue
              )}건}`,
            rich: {
              b: {
                fontSize: 14,
                lineHeight: 12,
                color: getThemeColor('gray-700')
              },
              c: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'bold',
                color: getThemeColor('gray-900')
              }
            }
          },
          labelLine: { show: false },
          data: isDataEmpty
            ? [{ value: 1, itemStyle: { color: '#f0f0f0' } }] // 빈 데이터용 회색 차트
            : [
              {
                value: successValue,
                name: '성공 처리량',
                itemStyle: {
                  color: successColor
                }
              },
              {
                value: failValue,
                name: '실패 처리량',
                itemStyle: {
                  color: failColor
                }
              }
            ]
        }
      ],
      tooltip: isDataEmpty
        ? { show: false } // 빈 데이터일 때는 툴팁 비활성화
        : {
          show: true,
          formatter: params => {
            const { name, value } = params;
            return `${name}: ${formatCountStyle2(value)}건`;
          },
          trigger: 'item',
          padding: [7, 10],
          backgroundColor: getThemeColor('gray-100'),
          borderColor: getThemeColor('gray-300'),
          textStyle: { color: getThemeColor('gray-1100') },
          borderWidth: 1,
          transitionDuration: 0,
          axisPointer: { type: 'none' }
        }
    };
  };

  return (
    <Card className="h-md-100">
      <Card.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h5 style={{ margin: 0 }}>요약</h5>
          <span
            style={{
              marginLeft: '8px',
              marginTop: 0,
              marginBottom: 0,
              padding: '2px 8px',
              // backgroundColor: '#A1C4E3', // 배경색 파란색
              backgroundColor: '#2C7BE5',
              color: 'white', // 텍스트 색상 흰색
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            {dashboardDate}
          </span>
        </div>
        <ButtonGroup>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
          >
            SDK
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body as={Flex} alignItems="center" justifyContent="space-between">
        {/* <Card.Body as={Flex} alignItems="center" justifyContent="space-between"> */}
        <div style={{ marginRight: 30 }}>
          <p style={{ margin: 0, fontSize: 14 }}>총 요청 수</p>
          <h2 style={{ fontSize: 18 }} className="fw-semibold">
            {formatCountStyle2(summaryData.totalRequests)}건
          </h2>
        </div>
        <ReactEchart
          echarts={echarts}
          option={getOption(
            '암호화',
            encryptionSuccess,
            encryptionFail,
            '#A1E3A1', // 성공 색상 (연한 녹색)
            '#D9534F' // 실패 색상 (진한 빨강)
          )}
          ref={encryptionChartRef}
          style={{ width: '38%', height: '15rem' }}
        />

        <ReactEchart
          echarts={echarts}
          option={getOption(
            '복호화',
            decryptionSuccess,
            decryptionFail,
            '#A1C4E3', // 성공 색상 (연한 하늘색)
            '#FFA07A' // 실패 색상 (주황색)
          )}
          ref={decryptionChartRef}
          style={{ width: '38%', height: '15rem' }}
        />
      </Card.Body>
    </Card>
  );
};

Summary.propTypes = {
  totalRequests: PropTypes.number,
  encryptionValue: PropTypes.number,
  decryptionValue: PropTypes.number,
  encryptionSuccessRatio: PropTypes.number,
  decryptionSuccessRatio: PropTypes.number
};

export default Summary;
