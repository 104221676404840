import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Select from 'react-select';
import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  handleValueDelete,
  goToParentPath,
  formatDate
} from 'components/common/SunnyUtils';

import { useAppContext } from 'providers/AppProvider';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable_Policy';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';

import PolicyControlModal from 'components/modal/PolicyControlModal';
import paths from 'routes/paths';
import EncryptKeyPolicyModal from 'safedb/common/key/encryptKeyPolicyModal';
import {
  detailEncKey,
  updateEncryptKeyPolicy,
  fetchEncPolicyList,
  deleteEncKey
} from 'hooks/CallApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
const editencryptKeyPolicy = () => {

  const columns = [
    {
      accessorKey: 'status',
      header: '상태',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '4rem', minWidth: '4rem', maxWidth: '4rem' }
        },
        cellProps: {
          style: { width: '4rem', minWidth: '4rem', maxWidth: '4rem' }
        }
      }
    },
    {
      accessorKey: 'group',
      header: '그룹명',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'keyname',
      header: '인증키 이름',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'role',
      header: '용도',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        },
        cellProps: {
          style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        }
      }
    },
    {
      accessorKey: 'aboutEnc',
      header: '암호화 권한',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'aboutDec',
      header: '복호화 권한',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'masking',
      header: '마스킹',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        },
        cellProps: {
          style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        }
      }
    },
    {
      accessorKey: 'createdAt',
      header: '등록일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'invalidAt',
      header: '만료일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'lastUsedAt',
      header: '최근 사용 일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    }
  ];

  const cardRef = useRef(null);

  const focusCard = () => {
    if (cardRef.current) {
      cardRef.current.focus();
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const [tableData, setTableData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedControlOptions, setSelectedControlOptions] = useState([]);

  const [pageSize, setPageSize] = useState(5); // 초기 페이지 사이즈 설정
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [apiKeyPolicyData, setApiKeyPolicyData] = useState([]); // 인증키 설정 모달 데이터 저장
  const [rowDataForModal, setRowDataForModal] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalPolicyDeleteShow, setModalPolicyDeleteShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [modalInfoShow, setModalInfoShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [isModified, setIsModified] = useState(false); // 변경 상태 추적
  const [showToast, setShowToast] = useState(false); // 토스트 상태
  const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
  const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가
  const [showPolicyControlModal, setShowPolicyControlModal] = useState(false); // 모달 상태 추가
  const [selectedOptions, setSelectedOptions] = useState([]); // 선택된 옵션 저장
  const [binaryOptions, setBinaryOptions] = useState('00000'); // 선택된 옵션 저장
  const [encOpt, setEncOpt] = useState('00000'); // encOpt 값을 저장할 상태
  const [isSelect, setIsSelect] = useState(false); // 공유키 입력 버튼 관리
  const [encryptOptions, setEncryptOptions] = useState(''); // 선택된 옵션 저장
  const [selecteValues, setSelecteValues] = useState([]);
  const [showencryptKeyPolicyModal, setShowencryptKeyPolicyModal] =
    useState(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [value, setValue] = useState(null);
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');

  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage: 5,
    selectionColumnWidth: 30
  });

  const navigate = useNavigate();
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const {
    config: { userAuth_K, userId, checkorg },
    setConfig
  } = useAppContext();

  const {
    policyId,
    keyname,
    algorithmName,
    keylength,
    mode,
    policyUuid,
    createdAt
  } = location.state || {}; // 추가 데이터 포함

  const organizerOptions = [
    { value: '1', label: 'MD' },
    { value: '2', label: 'NOBASE64' },
    { value: '3', label: '랜덤문자열 적용' },
    { value: '4', label: '이중 암호화 방지' },
    { value: '5', label: '부분 암호화' }
  ];

  const handleGroupModalShow = () => {
    setShowGroupModal(true); // 모달 열기
  };

  const handleGroupModalHide = () => {
    setShowGroupModal(false); // 모달 닫기
  };

  const handleCheckOutModal = () => {
    setModalShow(false); // 관리자 모달 닫기
  };
  const handleGroupModalConfirm = () => {
    setShowGroupModal(false);
    setShowGroupAddModal(true);
  };
  const handleGroupAddModalShow = () => {
    setShowGroupAddModal(true); // 모달 열기
  };

  const handleGroupAddModalHide = () => {
    setShowGroupAddModal(false); // 모달 닫기
  };
  const handlePolicyControlConfirm = selectedOptions => {
    // console.log('선택한 옵션:', selectedOptions);
    // console.log('선택한 행:', selecteValues);

    const selectedKeynames = selecteValues.map(value => value.keyname); // 선택된 행의 keyname 목록

    // 선택된 행에만 옵션 값 적용
    const updatedTableData = tableData.map(row => {
      if (selectedKeynames.includes(row.keyname)) {
        // console.log(`변경 중인 행: ${row.keyname}`);
        const newEncryptionPermission = selectedOptions.encryptActive;
        const newDecryptionPermission = selectedOptions.decryptActive;
        const newMaskingEnabled = selectedOptions.isMaskingEnabled;

        return {
          ...row,
          encryptionPermission: newEncryptionPermission,
          aboutEnc: newEncryptionPermission ? 'O' : 'X',
          decryptionPermission: newDecryptionPermission,
          aboutDec: newDecryptionPermission ? 'O' : 'X',
          maskingEnabled: newMaskingEnabled,
          masking: newMaskingEnabled ? 'O' : 'X',
          maskingData: selectedOptions.isMaskingEnabled
            ? selectedOptions.maskingChar
            : '',
          startindex: selectedOptions.isMaskingEnabled
            ? selectedOptions.startNumber
            : '',
          size: selectedOptions.isMaskingEnabled ? selectedOptions.size : ''
        };
      }
      // console.log(`변경되지 않은 행: ${row.keyname}`);
      return row; // 선택되지 않은 행은 그대로 유지
    });



    setTableData(updatedTableData); // 상태 업데이트
    setSelectedControlOptions(selectedOptions); // 선택된 옵션 저장 (필요시)
    setShowPolicyControlModal(false); // 모달 닫기
    setIsModified(true); // 변경 상태 업데이트
  };
  const handleDeleteClick = () => {
    setModalShow(true); // 모달 열기
  };

  const handleDataFromModal = selectedData => {

    setTableData(selectedData);
    setApiKeyPolicyData(selectedData); // 모달에서 받은 데이터를 상태로 저장
    setTotalElements(selectedData.length);
    setIsModified(true); // 변경 상태 업데이트
  };
  const handleEncryptKeyPolicyModal = () => {

    setShowencryptKeyPolicyModal(true);
  };

  const handlePolicyAdd = async () => {

    const apikeyPolicies = tableData.map(item => ({
      apikeyName: item.keyname,
      apikeyUuid: item.apikeyUuid,
      encryptionPermission: item.encryptionPermission ?? false,
      decryptionPermission: item.decryptionPermission ?? false,
      maskingEnabled: item.maskingEnabled ?? false,
      maskingData: item.maskingData ?? '',
      startindex: item.startindex ?? '',
      size: item.size ?? ''
    }));

    // 폼 데이터를 이용하여 policyData 구성
    const policyData = {
      policyUuid: policyUuid, // 정책 UUID
      sharedkeyUuid: isSelect ? formData.keyname : '', // 공유키 UUID (선택된 경우)
      policy: {
        algo: formData.algorithm,
        mode: formData.mode,
        keyLen: formData.keylength,
        policyName: formData.keyname, // 설정할 정책 이름
        encOption: binaryOptions, // 이진수 문자열로 표현된 암호화 옵션
        // encSub: `${encryptOptions?.startPosition || ""}-${encryptOptions?.encryptionSize || ""}` // 부분 암호화 설정값 (기본값 "")
        encSub: encryptOptions
      },
      apikeyPolicies: apikeyPolicies // 모달에서 받아온 모든 인증키 데이터를 사용
    };

    try {
      // API 호출
      const response = await updateEncryptKeyPolicy(policyUuid, apikeyPolicies);
      setModalConfirmShow(false);
      setModalInfoShow(true);
    } catch (error) {
      console.error('암호화 키 정책 등록 오류:', error);
    }
  };
  const handlePolicyControlButton = () => {
    const selectedRows = table.getSelectedRowModel().rows;

    // 선택된 행에서 keyname 값만 추출
    const selecteValues = selectedRows.map(row => ({
      group: row.original.group, // group 정보를 추출
      keyname: row.original.keyname // keyname 정보를 추출
    }));
    let rowData = null;

    if (selectedRows.length === 1) {
      // 1개의 로우만 선택된 경우, 데이터 준비
      const selectedRowData = selectedRows[0].original;

      rowData = {
        encryptionPermission: selectedRowData.encryptionPermission,
        decryptionPermission: selectedRowData.decryptionPermission,
        maskingEnabled: selectedRowData.maskingEnabled,
        maskingData: selectedRowData.maskingData,
        startindex: selectedRowData.startindex,
        size: selectedRowData.size
      };

      setSelecteValues(selectedRows.map(row => row.original)); // 선택된 데이터들 저장
      setRowDataForModal(rowData);
    } else {
      setSelecteValues(selecteValues); // 선택된 keyname 상태 업데이트
    }

    setShowPolicyControlModal(true);
  };

  const handleClosePolicyControl = () => {
    setShowPolicyControlModal(false); // 모달 열기
  };

  const handleInfo = () => {
    setModalInfoShow(false); // 모달 열기
    navigate(-1);
  };
  const [pwType, setpwType] = useState({
    type: 'password',
    visible: false
  });
  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const [formData, setFormData] = useState({
    policyId: policyId,
    algorithm: algorithmName,
    keylength: keylength,
    mode: mode,
    invalidDate: 'invalidDate',
    keyname: keyname,
    role: 'API',
    pubkeyYN: '',
    createdAt: createdAt,
    pubkeyName: '',
    description: '',
    adderRole: '',
    adderId: '',
    policyUuid: policyUuid
  });
  const formatDateTimeForInput = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const formatDateTimeForDisplay = dateString => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // UTC를 로컬로 변환

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  };

  // const formatDateTimeForDisplay = dateString => {
  //   if (!dateString) return '-';
  //   if (dateString === '-') return '-';
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const seconds = String(date.getSeconds()).padStart(2, '0');

  //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  // };
  const { user } = useAuthWizardContext();
  // console.log(queryParams.get('pubkeyYN'));
  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);

  // API 호출 후 데이터 확인
  useEffect(() => {
    if (policyUuid) {
      // policyUuid가 존재할 때만 API 호출
      const detailKey = async () => {
        try {
          const response = await detailEncKey(policyUuid);
          if (response.data && response.data.encOption) {
            setEncOpt(response.data.encOption);
          }
          if (response.data && response.data.encSub) {
            setEncryptOptions(response.data.encSub);
          }
        } catch (error) {
          console.error('불러오기 실패:', error);
        }
      };
      detailKey();
    }
  }, [policyUuid]);



  useEffect(() => {
    if (encOpt) {
      const initialSelectedOptions = [];
      const binaryArray = encOpt.split('');
      organizerOptions.forEach((option, index) => {
        if (binaryArray[index] === '1') {
          initialSelectedOptions.push(option.value); // encOpt 값이 1인 옵션만 선택
        }
      });
      setSelectedOptions(initialSelectedOptions); // 초기 선택 상태 설정
      setBinaryOptions(encOpt);
    }
  }, [encOpt]);

  useEffect(() => {
    // 전체 데이터 로드를 위한 함수
    const loadAllData = async () => {
      const pageSize = 5; // 5 단위로 로드
      let allData = [];
      let totalElements = 0;

      // 300ms 후에 스피너를 표시하는 타이머 설정
      const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

      try {
        // 첫 번째 호출로 totalElements 확인
        const initialResponse = await fetchEncPolicyList(
          policyUuid,
          0,
          pageSize
        );
        totalElements = initialResponse.data.page.totalElements;

        // 필요한 전체 데이터를 한 번에 요청
        const fullResponse = await fetchEncPolicyList(
          policyUuid,
          0,
          totalElements
        );
        const content = fullResponse.data.content
          .filter(item => item.selected === '1') // 필터링 조건 추가
          .map((item, index) => ({
            ...item,
            num: index + 1, // 순번 추가 (전체 순번 기준)
            status: item.state ? '정상' : '만료',
            state: item.state,
            group: item.groupName,
            keyname: item.apiKeyName,
            role: item.apiKeyType,
            apikeyUuid: item.apiKeyUuid,
            aboutEnc: item.encPermission ? 'O' : 'X',
            aboutDec: item.decPermission ? 'O' : 'X',
            encryptionPermission: item.encPermission,
            decryptionPermission: item.decPermission,
            masking: item.maskingEnabled ? '설정' : '미설정',
            maskingData: item.maskingData,
            startindex: item.startindex,
            size: item.size,
            createdAt: item.inserTimeUtc
              ? formatDate(item.inserTimeUtc) // 포맷 함수 호출
              : '-',
            invalidAt: item.expiredTimeUtc
              ? formatDate(item.expiredTimeUtc) // 포맷 함수 호출
              : '-',
            lastUsedAt: item.lastUsedTimeUtc
              ? formatDate(item.lastUsedTimeUtc) // 포맷 함수 호출
              : '-',

            selected: item.selected
          }));

        const uniqueData = Array.from(
          new Map(
            content
              .filter(item => item.selected === '1')
              .map(item => [item.apiKeyUuid, item])
          ).values()
        );

        allData = [...uniqueData];

        setTableData(allData);

        setTotalElements(allData.length); // 총 요소 수 설정
      } catch (error) {
        console.error('데이터 불러오기 에러:', error);
      } finally {
        clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
        setLoading(false);
        setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
      }
    };

    loadAllData();
  }, [policyUuid]); // pageSize가 변경될 때마다 loadAllData 실행

  const handleSubmit = e => {
    e.preventDefault();
    setModalConfirmShow(true);
  };

  const handleCheckOutModalConfirm = async () => {
    try {

      await deleteEncKey(policyUuid); // deleteEncKey API 호출
      setModalPolicyDeleteShow(true);
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);
    } finally {
      setModalShow(false);
    }
  };

  const handleRowClick = (row) => {
    const rowId = row.id; // 클릭된 행의 ID
    const isSelected = row.getIsSelected(); // 현재 선택 상태 확인
    const updatedSelection = {
      ...table.getState().rowSelection, // 기존 선택 상태 복사
      [rowId]: !isSelected // 클릭된 행의 선택 상태를 토글
    };
    table.setRowSelection(updatedSelection); // 선택 상태 업데이트
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          암호키
        </Card.Header>

        <Card.Title className=" px-3 pt-3"> 상세 정보</Card.Title>

        <Card.Body >
          <Form onSubmit={handleSubmit} className="d-flex justify-content-end">

            {/* Right Column (ID, Password, Name) */}
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>암호키 아이디</Form.Label>
                    <Form.Control
                      type="text"
                      name="policyId"
                      value={formData.policyId}
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label className="d-flex mt-3">
                        암호키 별칭
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="keyname"
                        value={formData.keyname}
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>등록 일시</Form.Label>
                        <Form.Control
                          // type="datetime-local"
                          type="text"
                          name="invalidDate"
                          value={formatDateTimeForDisplay(formData.createdAt)}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

              </Row>

            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <div
                className="px-3 py-2 mt-4 mb-3"
                style={{
                  border: '1px solid #000', // 테두리 추가
                  borderRadius: '50px', // 테두리를 둥글게
                  width: '95%', // 가로 확장
                  height: 'auto', // 높이 자동 확장
                }}
              >
                <Row style={{ padding: '15px 8px 0px 8px' }}>
                  <Col style={{ padding: '0px 8px' }}>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 알고리즘</Form.Label>
                      <Form.Select
                        name="algorithm"
                        // onChange={handleComboChange}
                        value={formData.algorithm}
                        disabled
                      >
                        <option value="" disabled>
                          선택
                        </option>
                        <option value="ARIA">ARIA</option>
                        <option value="SEED">SEED</option>
                        <option value="AES">AES</option>
                        <option disabled value="DES">
                          DES
                        </option>
                        <option disabled value="HAS160">
                          HAS160
                        </option>
                        <option disabled value="HMACwithHAS160">
                          HMACwithHAS160
                        </option>
                        <option disabled value="HMACwithMD5">
                          HMACwithMD5
                        </option>
                        <option disabled value="HMACwithMDC2">
                          HMACwithMDC2
                        </option>
                        <option disabled value="HMACwithSHA1">
                          HMACwithSHA1
                        </option>
                        <option disabled value="HMACwithSHA224">
                          HMACwithSHA224
                        </option>
                        <option disabled value="HMACwithSHA256">
                          HMACwithSHA256
                        </option>
                        <option disabled value="HMACwithSHA384">
                          HMACwithSHA384
                        </option>
                        <option disabled value="HMACwithSHA512">
                          HMACwithSHA512
                        </option>
                        <option disabled value="MD5">
                          MD5
                        </option>
                        <option disabled value="MDC2">
                          MDC2
                        </option>
                        <option disabled value="SHA1">
                          SHA1
                        </option>
                        <option disabled value="SHA224">
                          SHA224
                        </option>
                        <option disabled value="SHA256">
                          SHA256
                        </option>
                        <option disabled value="SHA384">
                          SHA384
                        </option>
                        <option disabled value="SHA512">
                          SHA512
                        </option>
                        <option disabled value="TripleDES">
                          TripleDES
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col style={{ padding: '0px 8px' }}>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 키 길이</Form.Label>
                      <Form.Select
                        name="keylength"
                        // onChange={handleComboChange}
                        value={formData.keylength}
                        disabled
                      >
                        <option value="" disabled>
                          선택
                        </option>
                        <option value="128">128</option>
                        <option value="192">192</option>
                        <option value="256">256</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col style={{ padding: '0px 8px' }}>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 운영모드</Form.Label>
                      <Form.Select
                        name="mode"
                        // onChange={handleComboChange}
                        value={formData.mode}
                        disabled
                      >
                        <option value="" disabled>
                          선택
                        </option>
                        <option value="CBC">CBC</option>
                        <option value="CFB">CFB</option>
                        <option value="OFB">OFB</option>
                        <option value="ECB">ECB</option>
                        {/* {modeOptions.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))} */}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>암호화 옵션</Form.Label>
                      <Select
                        closeMenuOnSelect={false}
                        options={organizerOptions}
                        components={{
                          DropdownIndicator: () => null, // 화살표 제거
                          IndicatorSeparator: () => null, // 구분선 제거
                          MultiValueRemove: () => null

                        }}
                        isMulti
                        isClearable={false} // Clear 버튼 비활성화
                        menuIsOpen={false} // 드롭다운 비활성화
                        classNamePrefix="react-select"
                        value={organizerOptions.filter(option =>
                          selectedOptions.includes(option.value)
                        )}
                        isDisabled={true}
                        placeholder=''

                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Col>
          </Form>
        </Card.Body>

        <Card.Body>
          <Card.Title>적용 대상 인증키</Card.Title>
          <AdvanceTableProvider {...table}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: 8
              }}
              className="mb-2"
            >
              {/* <AdvanceTableFooter rowsPerPageSelection_inner /> */}
              <Button
                variant="secondary"
                style={{
                  width: '8rem',
                  whiteSpace: 'nowrap'
                }}
                onClick={handleEncryptKeyPolicyModal}
              // disabled={selectedRowCount === 0}
              // onClick={handlePolicyControlButton}
              >
                인증키 설정
              </Button>
              <Button
                variant="secondary"
                style={{
                  width: '10rem',
                  whiteSpace: 'nowrap'
                }}
                disabled={selectedRowCount === 0}
                onClick={handlePolicyControlButton}
              >
                권한 제어 설정
              </Button>

            </div>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: false,
                className: 'fs-10 mb-0 overflow-hidden',
                // style: { tableLayout: 'fixed', width: '100%' } // 테이블 레이아웃 고정

              }}
              showEmptyMessage={totalElements === 0}
              onRowClick={handleRowClick} // 행 클릭 이벤트 핸들러 연결
            />
            <div className="mt-3">
              <p style={{ margin: 0, fontWeight: 700 }}>
                총 {totalElements.toLocaleString()}건
              </p>
              <AdvanceTablePagination />
            </div>
          </AdvanceTableProvider>
          <div className="d-flex justify-content-between mt-3">
            <Button
              variant="outline-danger"
              style={{ width: '100px' }}
              onClick={handleDeleteClick}
            >
              삭제
            </Button>
            <div>
              <Button
                onClick={() => setModalConfirmShow(true)}
                variant="primary"
                style={{ width: '100px' }}
                disabled={!isModified}
              >
                수정
              </Button>
              {/* <Link to={paths.encryptKeyPolicy}>
                                <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '100px' }}  >
                                    목록
                                </Button>
                            </Link> */}
            </div>
          </div>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModalConfirm}
        title="경고" // 제목
        body="삭제된 암호키는 복구할 수 없습니다. 정말 삭제하시겠습니까?"
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handlePolicyAdd}
        title="확인" // 제목
        body="적용 대상 인증키 수정 사항을 반영하시겠습니까?"
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalInfoShow}
        onHide={() => setModalInfoShow(false)}
        onConfirm={() => {
          setModalInfoShow(false);
          navigate('/safedb/common/key/encryptKeyPolicy');
        }}
        title="완료" // 제목
        body="수정 사항이 반영되었습니다."
        confirmText="완료" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalPolicyDeleteShow}
        onHide={() => setModalPolicyDeleteShow(false)}
        onConfirm={handleInfo}
        title="완료" // 제목
        body="암호키가 삭제되었습니다."
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />
      {/*권한제어모달*/}
      <PolicyControlModal
        show={showPolicyControlModal}
        onHide={handleClosePolicyControl}
        onConfirm={handlePolicyControlConfirm}
        selecteValues={selecteValues} // 선택된 keyname 전달
        policyId={formData.policyId}
        keyname={formData.keyname}
        rowData={rowDataForModal} // 선택된 1개의 데이터만 전달
      />

      <EncryptKeyPolicyModal
        show={showencryptKeyPolicyModal}
        onHide={() => setShowencryptKeyPolicyModal(false)}
        onConfirm={selectedData => {
          handleDataFromModal(selectedData); // 부모 페이지에서 데이터를 처리
          setShowencryptKeyPolicyModal(false); // 모달 닫기
        }}
        policyUuid={formData.policyUuid}
      />
    </div>
  );
};

export default editencryptKeyPolicy;
