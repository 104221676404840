import React, { useState, useEffect } from 'react';
import { Card, Button, ButtonGroup } from 'react-bootstrap';

const ApiGuide = () => {
  const [currentHtmlPath, setCurrentHtmlPath] = useState('/guides/SafeDB Web API Docs 1_20241217.html');
  const [htmlContent, setHtmlContent] = useState('');

  const htmlPath1 = '/guides/SafeDB Web API Docs 1_20241217.html';
  const htmlPath2 = '/guides/SafeDB Web API Docs 2_20241218.html';

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(currentHtmlPath);
        const html = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // 필요한 스타일만 추출
        const styles = Array.from(doc.getElementsByTagName('style')).map(style => style.innerHTML).join('\n');
        const body = doc.querySelector('.markdown-body')?.innerHTML || '';

        setHtmlContent(`
          <div class="api-guide-isolated">
            <style>
              .api-guide-isolated {
                all: initial;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
              }
              .api-guide-isolated * {
                all: revert;
              }
              ${styles}
              .markdown-body {
                padding: 20px;
                color: #24292e;
                font-size: 14px;
                line-height: 1.5;
              }
              .markdown-body table {
                border-collapse: collapse;
                width: 100%;
                margin: 1em 0;
              }
              .markdown-body table th,
              .markdown-body table td {
                border: 1px solid #dfe2e5;
                padding: 6px 13px;
              }
              .markdown-body pre {
                background-color: #f6f8fa;
                border-radius: 3px;
                padding: 16px;
                overflow: auto;
              }
            </style>
            <div class="markdown-body">
              ${body}
            </div>
          </div>
        `);
      } catch (error) {
        console.error('HTML 로드 실패:', error);
      }
    };

    fetchHtmlContent();
  }, [currentHtmlPath]);

  return (
    <Card className="mypage-card">
      <Card.Header className="px-3" as="h4">API 가이드</Card.Header>
      <Card.Body>
        <ButtonGroup className="mb-3">
          <Button
            variant={currentHtmlPath === htmlPath1 ? 'primary' : 'outline-primary'}
            onClick={() => setCurrentHtmlPath(htmlPath1)}
          >
            1 Page
          </Button>
          <Button
            variant={currentHtmlPath === htmlPath2 ? 'primary' : 'outline-primary'}
            onClick={() => setCurrentHtmlPath(htmlPath2)}
          >
            2 Page
          </Button>
        </ButtonGroup>

        <div
          className="api-guide-content"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          style={{
            height: '55rem',
            overflow: 'auto',
            border: '1px solid #dee2e6',
            borderRadius: '0.375rem',
            background: '#fff'
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default ApiGuide;
