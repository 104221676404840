// Input 값 삭제 버튼
const handleValueDelete = (fieldName, formData, setFormData, Change) => {
    setFormData({
        ...formData,
        [fieldName]: '' // 전달된 필드명을 기준으로 해당 필드의 값을 비움
    });

};


// 날짜 포맷  '2024-12-18 14:59:00' 용 (UTC 로컬화)
const formatDate = dateString => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // UTC를 로컬로 변환

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
};
// 날짜 포맷  '2024-12-18 14:59:00' 용 (datepicker용용)
//s는 시작날짜용 00시 00분 00초 시작
//e는 끝날짜용 23시 59분 59초 끝
const formatDate_datepicker = (dateString, type = 's') => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = utcDate;

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');

    let hours = '00';
    let minutes = '00';
    let seconds = '00';

    if (type === 'e') {
        hours = '23';
        minutes = '59';
        seconds = '59';
    }

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
};

// 날짜 포맷 (로그 검색용도) '2024-12-18 14:59:00' 용 (UTC제외)
const formatDate_log = (date) => {

    const [datePart, timePart] = date.split('T'); // 'T'를 기준으로 날짜와 시간을 분리
    const [year, month, day] = datePart.split('-'); // 날짜를 '-'로 분리
    const [hours, minutes, seconds] = timePart.split(':'); // 시간을 ':'로 분리

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 원하는 포맷으로 조합
};

// toISOString 결과를 '2024-12-18T14:59:00:00' 형태로 변환하는 함수
const formatToCustomISO = date => {
    if (!date) return null; // 날짜가 없으면 null 반환

    // 사용자의 로컬 타임존을 고려
    const offset = date.getTimezoneOffset() * 60000;
    const utcDate = new Date(date.getTime() - offset);

    // API 호출 요청 형식으로
    return utcDate
        .toISOString()
        .replace('Z', '')
        .replace(/\.(\d{3})/, ':00');
};

// 부모페이지 보내기 용도, 메뉴 헤더에 주로 추가되어있음음
const goToParentPath = () => {
    const pathSegments = location.pathname.split('/');
    // 마지막 경로 세그먼트를 제거하고 재조합
    pathSegments.pop(); // 'bc' 제거
    return pathSegments.join('/') || '/'; // '/'로 리턴 (루트로 이동)
};

/**
 * 건수 출력 방식 1
 */
const formatCountStyle1 = (count) => {
    if (count >= 100000) {
        return (count / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'K';
    }
    return count.toLocaleString();
};

/**
 * 건수 출력 방식 2
 */
const formatCountStyle2 = (count) => {
    if (count >= 1000000) {
        return (count / 1000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'M';
    }
    return count.toLocaleString();
};

/**
 * 용량 출력 방식
 */
const formatCountStyle3 = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes.toFixed(2)} B`;
    } else if (sizeInBytes < 1024 * 1024) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
        return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024) {
        return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    } else {
        return `${(sizeInBytes / (1024 * 1024 * 1024 * 1024)).toFixed(2)} TB`;
    }
};


export {
    handleValueDelete,
    goToParentPath,
    formatDate,
    formatDate_datepicker,
    formatDate_log,
    formatCountStyle1,
    formatCountStyle2,
    formatCountStyle3,
    formatToCustomISO
}