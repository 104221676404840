import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';

import { toast } from 'react-toastify';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable_Policy';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import data from 'safedb/common/sampledata/sampledataEditEncrypt';
import PublicKeydata from 'safedb/common/sampledata/sampledataPubKey';
import OptionModal from 'components/modal/EncryptOptionModal';
import EncryptKeyPolicyModal from 'safedb/common/key/encryptKeyPolicyModal';
import PolicyControlModal from 'components/modal/PolicyControlModal';
import {
  handleValueDelete,
  goToParentPath
} from 'components/common/SunnyUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import {
  settingEncKey,
  addEncryptKeyPolicy,
  addEncPolicyList,
  showPubKeyList
} from 'hooks/CallApi';
import styled from 'styled-components';

const addencryptKeyPolicy = ({ setValue, handleClose }) => {

  const columns = [
    {
      accessorKey: 'status',
      header: '상태',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '4rem', minWidth: '4rem', maxWidth: '4rem' }
        },
        cellProps: {
          style: { width: '4rem', minWidth: '4rem', maxWidth: '4rem' }
        }
      }
    },
    {
      accessorKey: 'group',
      header: '그룹명',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'keyname',
      header: '인증키 이름',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'role',
      header: '용도',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        },
        cellProps: {
          style: { width: '8rem', minWidth: '8rem', maxWidth: '8rem' }
        }
      }
    },
    {
      accessorKey: 'aboutEnc',
      header: '암호화 권한',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'aboutDec',
      header: '복호화 권한',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        },
        cellProps: {
          style: { width: '10rem', minWidth: '10rem', maxWidth: '10rem' }
        }
      }
    },
    {
      accessorKey: 'masking',
      header: '마스킹',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        },
        cellProps: {
          style: { width: '6rem', minWidth: '6rem', maxWidth: '6rem' }
        }
      }
    },
    {
      accessorKey: 'createdAt',
      header: '등록일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'invalidAt',
      header: '만료일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    },
    {
      accessorKey: 'lastUsedAt',
      header: '최근 사용 일시',
      meta: {
        headerProps: {
          className: 'text-900',
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        },
        cellProps: {
          style: { width: '25rem', minWidth: '25rem', maxWidth: '25rem' }
        }
      }
    }
  ];

  const [tableData, setTableData] = useState([]);

  const table = useAdvanceTable({
    data: tableData,
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage: 5,
    selectionColumnWidth: 30
  });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    config: { userAuth_K, userId, checkorg },
    setConfig
  } = useAppContext();
  const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [isModified, setIsModified] = useState(false); // 변경 상태 추적

  const [showConfirmModalShow, setConfirmModalShow] = useState(false); // 모달 상태 추가
  const [showOptionModal, setShowOptionModal] = useState(false); // 모달 상태 추가
  const [showPolicyControlModal, setShowPolicyControlModal] = useState(false); // 모달 상태 추가
  const [showencryptKeyPolicyModal, setShowencryptKeyPolicyModal] =
    useState(false);
  const [isAlgorithmEnabled, setIsAlgorithmEnabled] = useState(true); // 알고리즘 선택 활성화 여부 상태 추가
  const [selecteValues, setSelecteValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]); // 선택된 옵션 저장
  const [binaryOptions, setBinaryOptions] = useState('00000'); // 선택된 옵션 저장
  const [encryptOptions, setEncryptOptions] = useState([]); // 선택된 옵션 저장
  const [selectedControlOptions, setSelectedControlOptions] = useState([]);

  const [isSelect, setIsSelect] = useState(false); // 공유키 입력 버튼 관리
  const [errorMessage, setErrorMessage] = useState('');
  const [handleClear, setHandleClear] = useState(false); // 공유키 입력 버튼 관리
  const [isclean, setIsClean] = useState(false); // 공유키 입력 버튼 관리

  const [policyUuid, setPolicyUuid] = useState('');
  const [algorithms, setAlgorithms] = useState([]);
  const [encOptions, setEncOptions] = useState([]);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState('');
  const [modeOptions, setModeOptions] = useState([]);
  const [keyLengthOptions, setKeyLengthOptions] = useState([]);
  const [pageSize, setPageSize] = useState(5); // 초기 페이지 사이즈 설정
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [totalElements, setTotalElements] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(false); // 테이블 새로고침을 위한 상태
  const [apiKeyPolicyData, setApiKeyPolicyData] = useState([]); // 인증키 설정 모달 데이터 저장
  const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가
  const [publicKeyOptions, setPublicKeyOptions] = useState([]); // 공유키 목록 상태
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [rowDataForModal, setRowDataForModal] = useState(null);
  const [resultShow, setResultShow] = useState(false);
  const [emptyShow, setEmptyShow] = useState(false);

  const handleisSelectToggle = async () => {
    setIsSelect(prev => !prev); // 상태 토글
    if (!isSelect) {
      setIsAlgorithmEnabled(false); // 공유키를 사용할 경우 알고리즘 선택 비활성화
      setFormData(prev => ({
        ...prev,
        algorithm: '',
        mode: '',
        keylength: ''
      }));
      setSelectedOptions([]);
      setIsClean(true);
      try {
        const response = await showPubKeyList(); // API 호출

        // 불러올 공유키가 없을 경우
        if (Object.keys(response).length === 0) {
          setEmptyShow(true);
          return;
        }
        // API 응답 데이터를 드롭다운에 맞게 변환
        const options = response.data.map(item => ({
          value: item.sharedkeyName,
          label: item.sharedkeyName,
          ...item // 응답 데이터를 그대로 저장하여 다른 필드도 활용 가능
        }));

        setPublicKeyOptions(options); // 상태 업데이트
      } catch (error) {
        // toast.error('공유키 목록 조회 중 문제가 발생했습니다.');
        alert('공유키 목록 조회 중 문제가 발생했습니다.');
      }
    } else {
      setIsAlgorithmEnabled(true); // 공유키를 사용할 경우 알고리즘 선택 비활성화
      setFormData(prev => ({
        ...prev,
        algorithm: '',
        mode: '',
        keylength: ''
      }));
      setSelectedOptions([]);
      setIsClean(true);
    }
    setErrorMessage('');
  };

  const handlePolicyAdd = async () => {
    const apikeyPolicies = tableData.map(item => ({
      apikeyName: item.keyname,
      apikeyUuid: item.apikeyUuid,
      encryptionPermission: item.encryptionPermission ?? false,
      decryptionPermission: item.decryptionPermission ?? false,
      maskingEnabled: item.maskingEnabled ?? false,
      maskingData: item.maskingData ?? '',
      startindex: item.startindex ?? '',
      size: item.size ?? ''
    }));

    // 폼 데이터를 이용하여 policyData 구성
    const policyData = {
      policyUuid: policyUuid, // 정책 UUID
      sharedkeyUuid: isSelect ? formData.sharedkeyUuid : '', // 공유키 UUID (선택된 경우)
      policy: {
        algo: formData.algorithm,
        mode: formData.mode,
        keyLen: formData.keylength,
        policyName: formData.keyname, // 설정할 정책 이름
        encOption: binaryOptions, // 이진수 문자열로 표현된 암호화 옵션
        encSub: `${encryptOptions?.startPosition || ''}-${encryptOptions?.encryptionSize || ''
          }` // 부분 암호화 설정값 (기본값 "")
      },
      apikeyPolicies: apikeyPolicies // 모달에서 받아온 모든 인증키 데이터를 사용
    };


    try {
      // API 호출
      const response = await addEncryptKeyPolicy(policyData);
      setConfirmModalShow(false);
      // toast.success('암호화 키 정책이 등록되었습니다.', { theme: 'colored' });
      // alert('암호화 키 정책이 등록되었습니다.');
      setResultShow(true);
    } catch (error) {
      // toast.error('암호화 키 정책 등록 중 오류가 발생했습니다.');
      alert('암호화 키 정책 등록 중 오류가 발생했습니다.');
    }
  };
  const handleResultModal = () => {
    setResultShow(false);
    navigate(-1);
  };

  const handleDeleteClick = () => {
    setModalShow(true); // 모달 열기
  };
  const handleConfirmClick = () => {
    setConfirmModalShow(true); // 모달 열기
  };
  const handleOptionModal = () => {
    setShowOptionModal(true);
  };
  const handlecloseOptionModal = () => {
    setShowOptionModal(false);
  };
  const handleEncryptKeyPolicyModalConfirm = () => { };
  const handleEncryptKeyPolicyModal = () => {
    setShowencryptKeyPolicyModal(true);
  };

  const handleclearModal = () => {
    setHandleClear(true);
  };
  // 선택된 옵션을 처리하는 함수
  const handleConfirmSelectedOptions = (
    selectedOptions,
    binaryRepresentation,
    partialEncryptionSettings
  ) => {
    // console.log('선택된 옵션:', selectedOptions); // 선택된 옵션 목록을 확인
    // console.log('이진수 문자열:', binaryRepresentation); // 이진수 문자열 확인
    // console.log('부분 암호화 설정:', partialEncryptionSettings); // 부분 암호화 설정값 확인

    setSelectedOptions(selectedOptions);
    setBinaryOptions(binaryRepresentation);
    setEncryptOptions(partialEncryptionSettings);
  };

  const handleCheckOutModalConfirm = () => {
    setModalShow(false);
    navigate(-1);
  };

  const handlePublicKeyChange = e => {
    const selectedKeyName = e.target.value;
    // setFormData({ ...formData, keyname: selectedKeyName });
    // // 선택된 값이 비어있지 않으면 알고리즘 선택을 활성화
    // // PublicKeydata에서 선택된 키에 대한 정보 찾기
    // const selectedKey = PublicKeydata.find(item => item.publickeyName === selectedKeyName);

    // if (selectedKey) {
    //     // 선택된 키의 mode와 keylength 설정
    //     setFormData(prev => ({ ...prev, algorithm: selectedKey.algorithm, mode: selectedKey.mode, keylength: selectedKey.keylength }));
    //     setModeOptions([selectedKey.mode]); // 필요한 경우 다른 모드 추가 가능
    // } else {
    //     setIsAlgorithmEnabled(false);
    //     setModeOptions([]);
    // }
    const selectedKey = publicKeyOptions.find(
      item => item.sharedkeyName === selectedKeyName
    );

    if (selectedKey) {
      // 선택된 키 데이터로 formData 업데이트
      setFormData(prev => ({
        ...prev,
        keyname: selectedKey.sharedkeyName,
        algorithm: selectedKey.algo,
        mode: selectedKey.mode,
        keylength: selectedKey.keyLen,
        // description: selectedKey.description,
        sharedkeyUuid: selectedKey.sharedkeyId.sharedkeyUuid
        // insertTimeUtc: selectedKey.insertTimeUtc
      }));
    }
    setKeyLengthOptions(selectedKey.keyLen ? [selectedKey.keyLen] : []); // 키 길이 옵션 설정
    setModeOptions(selectedKey.mode ? [selectedKey.mode] : []); // 모드 옵션 설정
    setIsModified(true); // 필드가 변경되면 상태 업데이트
  };

  const handleKeyInputChange = e => {
    setFormData({ ...formData, keyname: e.target.value });
    // 에러 메시지 초기화
    setErrorMessage('');
  };
  const handlePolicyControlButton = () => {
    const selectedRows = table.getSelectedRowModel().rows;

    // 선택된 행에서 keyname 값만 추출
    const selecteValues = selectedRows.map(row => ({
      group: row.original.group, // group 정보를 추출
      keyname: row.original.keyname // keyname 정보를 추출
    }));
    // setSelecteValues(selecteValues); // 선택된 keyname 상태 업데이트
    // setShowPolicyControlModal(true);
    let rowData = null;

    if (selectedRows.length === 1) {
      // 1개의 로우만 선택된 경우, 데이터 준비
      const selectedRowData = selectedRows[0].original;

      rowData = {
        encryptionPermission: selectedRowData.encryptionPermission,
        decryptionPermission: selectedRowData.decryptionPermission,
        maskingEnabled: selectedRowData.maskingEnabled,
        maskingData: selectedRowData.maskingData,
        startindex: selectedRowData.startindex,
        size: selectedRowData.size
      };

      setSelecteValues(selectedRows.map(row => row.original)); // 선택된 데이터들 저장
      setRowDataForModal(rowData);
    } else {
      setSelecteValues(selecteValues); // 선택된 keyname 상태 업데이트
    }

    setShowPolicyControlModal(true);
  };

  const [formData, setFormData] = useState({
    policyId: queryParams.get('policyId'),
    algorithm: '',
    keylength: '',
    mode: '',
    invalidDate: 'invalidDate',
    keyname: '',
    role: 'API',
    description: '',
    adderRole: '',
    adderId: '',
    accesskey: '1234567',
    lastuseDate: '-',
    remakeDate: '-',
    options: '',
    sharedkeyUuid: ''
  });
  const handleBlur = () => {
    if (!formData.keyname) {
      setErrorMessage('암호키 별칭은 필수값 입니다.');
    }
  };
  useEffect(() => {
    // 컴포넌트가 처음 렌더링될 때 formData 초기화
    setFormData(prev => ({
      ...prev,
      adderRole: userAuth_K, // config에서 가져온 값
      adderId: userId // config에서 가져온 값
    }));
  }, [userAuth_K, userId]); // 의존성 배열에 userAuth와 userId 추가

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setIsModified(true); // 필드가 변경되면 상태 업데이트
  };

  const handleComboChange = event => {
    const { name, value } = event.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'algorithm' && { keylength: '', mode: '' }), // 알고리즘 선택 시 키 길이와 모드 초기화
      ...(name === 'keylength' && { mode: '' }) // 키 길이 선택 시 모드 초기화
    }));


    if (name === 'algorithm') {
      const selectedAlgorithm = algorithms.find(alg => alg.algo === value);
      if (selectedAlgorithm) {
        setKeyLengthOptions(selectedAlgorithm.keyLen || []);
        setModeOptions([]); // 키 길이 선택되기 전까지 모드는 비활성화
      } else {
        setKeyLengthOptions([]);
        setModeOptions([]);
      }
    } else if (name === 'keylength') {
      const selectedAlgorithm = algorithms.find(
        alg => alg.algo === formData.algorithm
      );
      if (selectedAlgorithm) {
        setModeOptions(selectedAlgorithm.mode || []); // 키 길이 선택 시 모드 활성화
      }
    }
  };
  useEffect(() => {
    const isAllFieldsFilled =
      formData.keyname.trim() !== '' &&
      formData.algorithm.trim() !== '' &&
      formData.keylength.trim() !== '' &&
      formData.mode.trim() !== '';

    setIsModified(isAllFieldsFilled);
  }, [formData.keyname, formData.algorithm, formData.keylength, formData.mode]);
  const renderAlgorithmOptions = () => {
    const allAlgorithms = [
      'ARIA',
      'SEED',
      'AES',
      'DES',
      'HAS160',
      'HMACwithHAS160',
      'HMACwithMD5',
      'HMACwithMDC2',
      'HMACwithSHA1',
      'HMACwithSHA224',
      'HMACwithSHA256',
      'HMACwithSHA384',
      'HMACwithSHA512',
      'MD5',
      'MDC2',
      'SHA1',
      'SHA224',
      'SHA256',
      'SHA384',
      'SHA512',
      'TripleDES'
    ];

    return allAlgorithms.map(algo => (
      <option
        key={algo}
        value={algo}
        disabled={!algorithms.some(a => a.algo === algo)}
      >
        {algo}
      </option>
    ));
  };

  const handleOptionChange = options => {
    setSelectedOptions(options ? options.map(option => option.value) : []);
    setIsModified(true);
  };

  const optionItems = selectedOptions.map(option => ({
    value: option,
    label: option
  }));
  const handleClosePolicyControl = () => {
    setShowPolicyControlModal(false); // 모달 열기
  };

  //   const [buttonStyle, setButtonStyle] = useState({});

  const handleGroupAddModalConfirm = () => {
    setShowGroupAddModal(false);
  };
  const handlePolicyControlConfirm = selectedOptions => {
    // console.log('선택한 옵션:', selectedOptions);
    // console.log('선택한 행:', selecteValues);

    const selectedKeynames = selecteValues.map(value => value.keyname); // 선택된 행의 keyname 목록

    // 선택된 행에만 옵션 값 적용
    const updatedTableData = tableData.map(row => {
      if (selectedKeynames.includes(row.keyname)) {
        // console.log(`변경 중인 행: ${row.keyname}`);
        const newEncryptionPermission = selectedOptions.encryptActive;
        const newDecryptionPermission = selectedOptions.decryptActive;
        const newMaskingEnabled = selectedOptions.isMaskingEnabled;
        return {
          ...row,
          encryptionPermission: newEncryptionPermission,
          aboutEnc: newEncryptionPermission ? 'O' : 'X',
          decryptionPermission: newDecryptionPermission,
          aboutDec: newDecryptionPermission ? 'O' : 'X',
          maskingEnabled: newMaskingEnabled,
          masking: newMaskingEnabled ? '설정' : '미설정',
          maskingData: selectedOptions.isMaskingEnabled
            ? selectedOptions.maskingChar
            : '',
          startindex: selectedOptions.isMaskingEnabled
            ? selectedOptions.startNumber
            : '',
          size: selectedOptions.isMaskingEnabled ? selectedOptions.size : ''
        };
      }
      // console.log(`변경되지 않은 행: ${row.keyname}`);
      return row; // 선택되지 않은 행은 그대로 유지
    });

    // console.log('변경 후 데이터:', updatedTableData);

    setTableData(updatedTableData); // 상태 업데이트
    setSelectedControlOptions(selectedOptions); // 선택된 옵션 저장 (필요시)
    setShowPolicyControlModal(false); // 모달 닫기
  };
  const handleDataFromModal = selectedData => {
    // setTableData((prevData) => [
    //     ...prevData,  // 기존 데이터 유지
    //     ...selectedData // 모달에서 받아온 데이터 추가
    // ]);

    setTableData(selectedData);
    setApiKeyPolicyData(selectedData); // 모달에서 받은 데이터를 상태로 저장
    setTotalElements(selectedData.length);
  };

  // API 호출 후 데이터 확인
  useEffect(() => {
    const addEncKey = async () => {
      try {
        const response = await settingEncKey();

        setPolicyUuid(response.data.policyUuid);
        setAlgorithms(response.data.algorithms); // algorithms 배열을 상태에 저장
        setEncOptions(response.data.encOptions);
      } catch (error) {
        console.error('그룹 목록 불러오기 실패:', error);
      }
    };
    addEncKey();
  }, []);

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const selectedRows = table.getSelectedRowModel().rows;
  const bodyMessage =
    selectedRows.length === 0
      ? '등록 후 암호키 정보는 변경할 수 없습니다. 적용 대상 인증키를 지정하지 않고 등록하시겠습니까?'
      : '등록 후 암호키 정보는 변경할 수 없습니다. 등록하시겠습니까?';

  const handleRowClick = (row) => {
    const rowId = row.id; // 클릭된 행의 ID
    const isSelected = row.getIsSelected(); // 현재 선택 상태 확인
    const updatedSelection = {
      ...table.getState().rowSelection, // 기존 선택 상태 복사
      [rowId]: !isSelected // 클릭된 행의 선택 상태를 토글
    };
    table.setRowSelection(updatedSelection); // 선택 상태 업데이트
  };



  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          암호키
        </Card.Header>
        <Card.Title className=" px-3 pt-3">
          {' '}
          등록
        </Card.Title>
        <Card.Body >
          <Form className="d-flex justify-content-end">
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>암호키 아이디</Form.Label>
                    <Form.Control
                      type="text"
                      name="policyId"
                      value={policyUuid}
                      disabled
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Form.Label className="mt-3">
                          암호키 별칭<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <Button
                          className="py-0"
                          style={{
                            height: '20px',
                            // lineHeight: '20px',
                            fontSize: '12px'
                          }}
                          onClick={handleisSelectToggle}
                          // active={isSelect} // Active 상태 추가
                          variant={isSelect ? 'outline-primary' : 'primary'} // variant 동적 설정
                        >
                          공유키 사용
                        </Button>
                      </div>

                      {isSelect ? (
                        <Form.Select
                          name="keyname"
                          // value={formData.keyname}
                          onChange={handlePublicKeyChange} // 선택 시 상태 업데이트
                          value={formData.keyname} // 선택된 값
                        >
                          <option value="" disabled>
                            암호화 키로 사용할 공유키를 선택해주세요.
                          </option>

                          {publicKeyOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                          {/* 필요한 옵션 추가 */}
                        </Form.Select>
                      ) : (
                        <Form.Control
                          type="text"
                          name="keyname"
                          value={formData.keyname}
                          onChange={handleKeyInputChange} // 입력 시 상태 업데이트
                          onBlur={handleBlur} // 포커스가 벗어날 때 호출
                          placeholder="사용할 암호화 키의 별칭을 입력해주세요."
                        />
                      )}
                      {errorMessage && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errorMessage}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>등록 일시</Form.Label>
                        <Form.Control
                          type="text"
                          name="invalidDate"
                          value="-"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

              </Row>


            </Col>
            <Col md={6} className="d-flex justify-content-end">

              <div
                className="px-3 py-2 mt-4 mb-3"
                style={{
                  border: '1px solid #000', // 테두리 추가
                  borderRadius: '50px', // 테두리를 둥글게
                  width: '95%', // 가로 확장
                  height: 'auto', // 높이 자동 확장
                }}
              >
                <Row style={{ padding: '15px 8px 0px 8px' }}>
                  <Col style={{ padding: '0px 8px' }}>
                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 알고리즘</Form.Label>
                      <Form.Select
                        name="algorithm"
                        onChange={handleComboChange}
                        value={formData.algorithm}
                        disabled={!isAlgorithmEnabled} // 알고리즘 선택 비활성화 상태
                      >
                        <option disabled value="">
                          선택
                        </option>
                        {renderAlgorithmOptions()}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col style={{ padding: '0px 8px' }}>

                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 키 길이</Form.Label>
                      <Form.Select
                        name="keylength"
                        onChange={handleComboChange}
                        value={formData.keylength}
                        disabled={
                          !isAlgorithmEnabled || formData.algorithm === ''
                        } // 알고리즘 선택 비활성화 상태
                      >
                        <option disabled value="">
                          선택
                        </option>
                        {keyLengthOptions.map((length, index) => (
                          <option key={index} value={length}>
                            {length}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col style={{ padding: '0px 8px' }}>

                    <Form.Group
                      className="mb-3"
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 운영모드</Form.Label>
                      <Form.Select
                        name="mode"
                        onChange={handleComboChange}
                        value={formData.mode}
                        disabled={
                          !isAlgorithmEnabled || formData.algorithm === ''
                        } // 알고리즘 선택 비활성화 상태
                      >
                        <option disabled value="">
                          선택
                        </option>
                        {modeOptions.map((mode, index) => (
                          <option key={index} value={mode}>
                            {mode}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="px-1" >
                  <Col>
                    <Form.Group
                      className="mb-3 "
                      style={{ position: 'relative' }}
                    >
                      <Form.Label>암호화 옵션</Form.Label>
                      <div
                        style={{
                          display: 'flex',
                          gap: '4px'
                        }}
                      >
                        <Select
                          name="options"
                          options={optionItems}
                          // value={optionItems}
                          //onChange={value => setValue(value)}
                          onChange={handleOptionChange} // 선택이 변경될 때 호출하는 핸들러 추가
                          value={optionItems.filter(option =>
                            selectedOptions.includes(option.value)
                          )}
                          components={{
                            DropdownIndicator: () => null, // 화살표 제거
                            IndicatorSeparator: () => null, // 구분선 제거
                            MultiValueRemove: () => null
                          }}
                          // isDisabled={!isAlgorithmEnabled}
                          isClearable={false} // Clear 버튼 비활성화
                          menuIsOpen={false} // 드롭다운 비활성화
                          isDisabled={true}
                          placeholder=''
                          isMulti // 여러 옵션 선택을 지원
                          styles={{
                            container: provided => ({
                              ...provided,
                              flexGrow: 1
                            })
                          }}
                        />
                        <AddOptionButton
                          onClick={handleOptionModal}
                        //   style={buttonStyle}
                        >
                          추가
                        </AddOptionButton>
                      </div>
                    </Form.Group>
                  </Col>
                  {/* <Col md={2} className="px-1">
                        <Form.Group>
                          <Form.Label>&nbsp;</Form.Label>
                        </Form.Group>

                      </Col> */}
                </Row>
              </div>
            </Col>

          </Form>
        </Card.Body>

        <Card.Body>
          <Card.Title>적용 대상 인증키</Card.Title>
          <AdvanceTableProvider {...table}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: 8
              }}
              className="mb-2"
            >
              {/* <AdvanceTableFooter rowsPerPageSelection_inner /> */}
              <Button
                variant="secondary"
                style={{
                  width: '8rem',

                  whiteSpace: 'nowrap'
                }}
                onClick={handleEncryptKeyPolicyModal}
              // disabled={selectedRowCount === 0}
              // onClick={handlePolicyControlButton}
              >
                인증키 설정
              </Button>
              <Button
                variant="secondary"
                style={{
                  width: '10rem',
                  whiteSpace: 'nowrap'
                }}
                disabled={selectedRowCount === 0}
                onClick={handlePolicyControlButton}
              >
                권한 제어 설정
              </Button>

            </div>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden',
                // style: { tableLayout: 'fixed', width: '100%' } // 테이블 레이아웃 고정

              }}
              showEmptyMessage={totalElements === 0}
              data={tableData}
              onRowClick={handleRowClick} // 행 클릭 이벤트 핸들러 연결

            />

            <div className="mt-3">
              <p style={{ margin: 0, fontWeight: 700 }}>
                총 {totalElements.toLocaleString()}건
              </p>
              <AdvanceTablePagination />
            </div>
          </AdvanceTableProvider>
          <div className="d-flex justify-content-end">
            <Button
              className="m-2"
              variant="outline-danger"
              style={{ width: '100px' }}
              onClick={handleDeleteClick}
            >
              취소
            </Button>
            <Button
              className="m-2"
              variant="primary"
              type="submit"
              style={{ width: '100px' }}
              disabled={!isModified}
              onClick={handleConfirmClick}
            >
              등록
            </Button>
          </div>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={emptyShow}
        onHide={() => setEmptyShow(false)}
        onConfirm={() => setEmptyShow(false)}
        title="경고"
        body="등록된 공유키가 없습니다."
        confirmText="확인"
        variant="warning"
        SubmitButton={true}
      />

      <CheckOutModal
        show={resultShow}
        onHide={() => setResultShow(false)}
        onConfirm={handleResultModal}
        title="완료"
        body="암호화 키 정책이 등록되었습니다."
        confirmText="확인"
        variant="success"
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModalConfirm}
        title="경고" // 제목
        body="암호키 등록을 취소하시겠습니까?"
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={showConfirmModalShow}
        onHide={() => setConfirmModalShow(false)}
        onConfirm={handlePolicyAdd}
        title="안내" // 제목
        body={bodyMessage}
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="등록" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />
      <OptionModal
        show={showOptionModal}
        onHide={handlecloseOptionModal}
        // onConfirm={handleConfirmSelectedOptions} // 선택된 옵션을 전달받을 함수
        onConfirm={(
          selectedOptions,
          binaryRepresentation,
          partialEncryptionSettings
        ) =>
          handleConfirmSelectedOptions(
            selectedOptions,
            binaryRepresentation,
            partialEncryptionSettings
          )
        }
        clear={isclean}
        onClearComplete={() => setIsClean(false)} // 초기화 완료 콜백
      />

      <PolicyControlModal
        show={showPolicyControlModal}
        onHide={handleClosePolicyControl}
        onConfirm={handlePolicyControlConfirm}
        selecteValues={selecteValues} // 선택된 keyname 전달
        policyId={policyUuid}
        keyname={formData.keyname}
        rowData={rowDataForModal} // 선택된 1개의 데이터만 전달
      />

      <EncryptKeyPolicyModal
        show={showencryptKeyPolicyModal}
        onHide={() => setShowencryptKeyPolicyModal(false)}
        onConfirm={selectedData => {
          handleDataFromModal(selectedData); // 부모 페이지에서 데이터를 처리
          setShowencryptKeyPolicyModal(false); // 모달 닫기
        }}
        policyUuid={policyUuid}
      />
    </div >
  );
};

export default addencryptKeyPolicy;

const AddOptionButton = styled(Button)`
  width: 8rem;

  @media (max-width: 1919px) {
    width: 4rem;
    min-width: max-content;
  }
`;
