import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { timeDistribution } from 'hooks/CallApi'; // API 함수 import
import { formatCountStyle3 } from 'components/common/SunnyUtils';
import { useAppContext } from 'providers/AppProvider';
const SiteAverageCfProcess = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);  // chart 인스턴스를 useRef로 관리
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({ days: [], series: [], legends: [] });
  const {
    config: { period }
  } = useAppContext();

  // 차트 초기화 함수
  const initChart = useCallback(() => {
    if (chartInstance.current) {
      chartInstance.current.dispose();
    }

    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current);
      return chartInstance.current;
    }
    return null;
  }, []);

  // 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const requestParams = {
          timeZone: clientTimeZone,
          type: 'cf',
          ...(period === 'demo' && { period })
        };

        const response = await timeDistribution(requestParams);
        const apiData = response.data;

        // Calculate min and max processedDataSize
        const allDataSizes = apiData.flatMap(company =>
          company.topTwoHourlyData.flatMap(entry =>
            entry.hourlyData.map(hour => hour.processedDataSize)
          )
        );
        const minDataSize = Math.min(...allDataSizes);
        const maxDataSize = Math.max(...allDataSizes);

        const days = apiData[0]?.topTwoHourlyData.map((entry) => {
          const date = new Date(entry.date);
          return `${date.getMonth() + 1}-${date.getDate()} ${date.toLocaleDateString('ko-KR', { weekday: 'short' })}`;
        });

        const twoHourGroups = Array.from({ length: 12 }, (_, i) => {
          const startHour = i * 2;
          return `${String(startHour).padStart(2, '0')}:00`;
        });

        const fixedColors = ['#F3B0C3', '#FFCCB6', '#FFFFB5', '#CBAACB', '#ABDEE6'];

        const transformData = (company, index) => {
          const { companyName, topTwoHourlyData } = company;

          const data = topTwoHourlyData.flatMap((entry, dayIndex) =>
            entry.hourlyData.map((hourEntry) => {
              const hourRange = `${hourEntry.hourRange.split('-')[0].padStart(2, '0')}:00`;
              const yIndex = twoHourGroups.indexOf(hourRange);
              if (yIndex === -1) return null;
              return [dayIndex, yIndex, hourEntry.processedDataSize];
            })
          ).filter(Boolean);

          return {
            name: companyName,
            type: 'scatter',
            data,
            symbolSize: (val) => {
              const dataValue = val[2];
              const minSize = 20;
              const maxSize = 100;
              if (dataValue <= minDataSize) return minSize;
              if (dataValue >= maxDataSize) return maxSize;
              return minSize + ((dataValue - minDataSize) / (maxDataSize - minDataSize)) * (maxSize - minSize);
            },
            itemStyle: {
              color: fixedColors[index % fixedColors.length],
              opacity: 0.6,
            },
          };
        };

        const series = apiData.map(transformData);
        const legends = apiData.map((company) => company.companyName);

        setChartData({ days, series, legends, twoHourGroups, rawData: apiData, minDataSize, maxDataSize });
      } catch (error) {
        console.error('API 데이터 호출 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // cleanup
    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [period]);

  // 차트 렌더링
  useEffect(() => {
    if (isLoading || !chartRef.current || !chartData.days.length) return;

    const chart = initChart();
    if (!chart) return;

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const data = params.data;
          if (!data) return '';

          const dayIndex = data[0];
          const hourRange = data[1];
          const sum = data[2];
          const companyName = params.seriesName;

          const selectedCompany = chartData.rawData.find((company) => company.companyName === companyName);
          const selectedDayHourlyData = selectedCompany?.topTwoHourlyData[dayIndex]?.hourlyData;

          const top_five_list = selectedDayHourlyData
            ?.slice(0, 5)
            .map((entry, index) => {
              const startHour = entry.hourRange.split('-')[0].padStart(2, '0');
              const formattedHourRange = `${startHour}:00`;
              const formattedValue = formatCountStyle3(entry.processedDataSize);
              return `${index + 1}. ${formattedHourRange} - ${formattedValue}`;
            })
            .join('<br/>') || '데이터 없음';

          const daySum = selectedDayHourlyData
            ?.reduce((total, entry) => total + entry.processedDataSize, 0) || 0;

          const day = chartData.days[dayIndex];
          const formattedHourRange = (() => {
            if (typeof hourRange === 'string' && hourRange.includes('-')) {
              return `${hourRange.split('-')[0].padStart(2, '0')}:00`;
            } else if (typeof hourRange === 'number') {
              return `${String(hourRange).padStart(2, '0')}:00`;
            } else if (typeof hourRange === 'string') {
              return `${hourRange.padStart(2, '0')}:00`;
            }
            return '00:00';
          })();

          return `
            ${companyName}<br/>
            ${day} ${formattedHourRange}<br/>
            현재 시간 범위의 데이터량: ${formatCountStyle3(sum)}<br/>
            하루의 총 데이터량: ${formatCountStyle3(daySum)}<br/>
            <strong>Top 5:</strong><br/>
            ${top_five_list}
          `;
        },
      },
      legend: {
        data: chartData.legends,
        bottom: '2%',
      },
      grid: {
        left: '1%',
        right: '7%',
        bottom: '15%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: chartData.days,
        name: '날짜',
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: {
        type: 'category',
        data: chartData.twoHourGroups,
        name: '시간대',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: chartData.series,
    };

    chart.setOption(option);

    const handleResize = () => {
      chart && chart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartData, isLoading, initChart]);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, []);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>일별 CF 최대 처리량 시간 분포</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          최근 1주일
        </span>
      </Card.Header>
      <Card.Body>
        <div style={{ minHeight: '15rem' }}>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: '15rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default SiteAverageCfProcess;
