import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import 'react-toastify/dist/ReactToastify.css'; // 추가
import 'safedb/common/css/IniCustomCss.css';
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { PiWarningCircle } from 'react-icons/pi';
import { createCompany, ImagesUpload } from 'hooks/CallApi'; // API 함수 임포트
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import {
  handleValueDelete,
  goToParentPath
} from 'components/common/SunnyUtils';
import { useAppContext } from 'providers/AppProvider';
import styled from 'styled-components';

const AddAdmimSites = ({ setValue }) => {
  const {
    config: { userAuth }
  } = useAppContext();

  const [modalCancelShow, setModalCancelShow] = useState(false);
  const [modalConfirmShow, setModalConfirmShow] = useState(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);

  const [modalConfirmCheckShow, setModalConfirmCheckShow] = useState(false);
  const [isChevronUp, setIsChevronUp] = useState(false); // 상태 변수 추가
  const toggleKeyPart = () => {
    setKeyPart(!KeyPart);
    setIsChevronUp(!isChevronUp); // 아이콘 상태 전환
  };
  const [KeyPart, setKeyPart] = useState(true);
  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');
  const [MailError, setMailError] = useState('');
  const [AddrError, setAddrError] = useState('');
  const [isInvalid, setIsInvalid] = useState(false); // 유효성 검사 상태

  const [isHovered, setIsHovered] = useState(false);
  // const handleMouseEnter = () => setIsHovered(true);

  const handleMouseEnter = () => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => setIsHovered(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // 내용 변경 시 변경 버튼 눌렀을때
  const handleSubmitClick = () => {
    e.preventDefault();
    setModalConfirmShow(true);
  };

  const handleConfirm = async () => {
    try {
      const response = await createCompany({
        imgUrl: formData.imgUrl,
        name: formData.clientName,
        tel: formData.clientPhone,
        email: formData.clientEmail,
        address: formData.clientAddress,
        description: formData.description
      });
      if (response) {
        setModalConfirmShow(false);
        setModalConfirmCheckShow(true);
        // alert('정상 등록되었습니다.');
      }
    } catch (error) {
      alert('회사 생성 중 오류가 발생했습니다.');
    }
    navigate(-1);
  };

  const navigate = useNavigate(); // useNavigate 훅 사용
  const handleCancel = () => {
    navigate(`/safedb/SA/adminSites`); // 데이터와 함께 이동
  };
  const [clientPhoneError, setClientPhoneError] = useState(false); // 추가된 상태 변수

  const [formData, setFormData] = useState({
    createdAt: '',
    updateAt: '',
    description: '',
    imgUrl: '',
    active: '',
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientAddress: '',
    clientSecret: '',
    clientKey: '',
    clientIV: ''
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });

    // 각각의 에러
    if (name === 'clientEmail') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setMailError('올바른 이메일 형식이 아닙니다.');
      } else if (value.length >= 50) {
        setMailError('고객사 메일은 최대 50자까지 입력 가능합니다.');
      } else {
        setMailError('');
      }
    }

    if (name === 'clientName') {
      if (value.length >= 50) {
        setNameError(`고객사명은 최대 50자까지 입력 가능합니다.`);
      } else {
        setNameError('');
      }
    }

    if (name === 'clientAddress') {
      if (value.length >= 100) {
        setAddrError(`고객사 주소는 최대 100자까지 입력 가능합니다.`);
      } else {
        setAddrError('');
      }
    }

    if (name === 'description') {
      if (value.length >= 800) {
        setDescError(`고객사 설명란 입력은 최대 800자까지 입력 가능합니다.`);
      } else {
        setDescError('');
      }
    }

    if (name === 'clientPhone') {
      const onlyNumbers = value.replace(/[^0-9]/g, '');

      let formattedPhone = onlyNumbers;
      if (onlyNumbers.startsWith('02')) {
        // 1. 지역번호가 두자리 (02)
        if (onlyNumbers.length === 9) {
          formattedPhone = onlyNumbers.replace(
            /(\d{2})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XX-XXX-XXXX
        } else if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{2})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XX-XXXX-XXXX
        }
      } else if (onlyNumbers.startsWith('010')) {
        // 2. 핸드폰 번호 (010)
        if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXX-XXXX
        } else if (onlyNumbers.length === 11) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXXX-XXXX
        }
      } else {
        // 3. 일반 3자리 지역번호
        if (onlyNumbers.length === 10) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXX-XXXX
        } else if (onlyNumbers.length === 11) {
          formattedPhone = onlyNumbers.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1-$2-$3'
          ); // XXX-XXXX-XXXX
        }
      }

      setFormData(prev => ({
        ...prev,
        clientPhone: formattedPhone
      }));

      // 유효성 검사
      const phoneRegex = /^[0-9-]*$/;
      if (!phoneRegex.test(value)) {
        setIsInvalid(true); // 허용되지 않은 문자가 있는 경우 에러
      } else if (formattedPhone.length >= 20) {
        setIsInvalid(true); // 20자를 넘는 경우 에러
      } else {
        setIsInvalid(false); // 유효성 검사 통과
      }
      return; // 다른 입력 필드 검사를 건너뛴다.
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
  };


  const onDrop = async acceptedFiles => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          // toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
          alert('이미지 크기는 최대 5MB까지 허용됩니다.');
          return;
        }

        try {
          // Call ImagesUpload API
          const uploadResponse = await ImagesUpload(imageFile, 'company'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {
            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData(prev => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl // Set the uploaded URL in formData
            }));
            // setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화
  });

  const isFormValid = () => {
    const { clientName, clientPhone, clientEmail, clientAddress } = formData;

    // 모든 필수 필드가 채워져 있고, 에러와 invalid 상태가 없는지 확인
    return (
      clientName.trim() &&
      clientPhone.trim() &&
      clientPhone.trim().length > 7 &&
      clientEmail.trim() &&
      clientAddress.trim() &&
      !NameError &&
      !clientPhoneError &&
      !MailError &&
      !AddrError &&
      !isInvalid
    );
  };

  const handleDateChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value // 이미 'YYYY-MM-DDTHH:MM' 형식으로 제공됨
    });
  };
  const formatDateTimeForDisplay = dateString => {
    if (!dateString) return '-';
    if (dateString === '-') return '-';

    const utcDate = new Date(dateString); // 서버에서 받은 UTC 시간
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // UTC를 로컬로 변환

    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(localDate.getDate()).padStart(2, '0');
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // "YYYY-MM-DD HH:MM:SS"
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header className="px-3" as="h4">
          <Link
            to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)}
          >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: '#869fca' } // 기본 스타일
              }
            />
          </Link>
          고객사 관리
        </Card.Header>
        <Card.Title className="border border-bottom-0 px-3 pt-3">
          {' '}
          정보 등록
        </Card.Title>
        <Card.Body className="border border-top-0">
          <Form
            onSubmit={e => {
              e.preventDefault();
              setModalConfirmShow(true);
            }}
          >
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    // cursor: 'pointer',
                    cursor:
                      userAuth === 'SA' || userAuth === 'ADMIN'
                        ? 'pointer'
                        : 'not-allowed',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto'
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />

                  <Profile>
                    <Image
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                    />
                    <HoverView>이미지 변경</HoverView>
                  </Profile>
                </div>
              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        고객사명<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientName"
                          value={formData.clientName}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="50"
                          style={{
                            paddingRight: '30px'
                          }}
                        //  placeholder="아이디를 입력해주세요."
                        />

                        {formData.clientName && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientName',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {NameError && (
                      <span className="text-danger">{NameError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        고객사 연락처<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        {/* <InputGroup> */}
                        <Form.Control
                          type="phone"
                          name="clientPhone"
                          isInvalid={isInvalid}
                          value={formData.clientPhone}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="20"

                        />

                        {formData.clientPhone && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientPhone',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              // top: isInvalid ? '35%' : '50%',
                              top: '50%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                      {clientPhoneError && (
                        <span
                          style={{
                            color: 'red',
                            position: 'absolute',
                            right: '30px',
                            top: '50%',
                            transform: 'translateY(-14px)',
                            zIndex: 9
                          }}
                        >
                          <PiWarningCircle />
                        </span>
                      )}{' '}
                      {/* 에러 메시지 표시 */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        고객사 메일<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientEmail"
                          value={formData.clientEmail}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="50"
                          style={{
                            paddingRight: '30px'
                          }}
                        // placeholder="이름을 입력해주세요."
                        />

                        {formData.clientEmail && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientEmail',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {MailError && (
                      <span className="text-danger">{MailError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        고객사 주소<span className="text-danger">*</span>
                      </Form.Label>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <Form.Control
                          type="text"
                          name="clientAddress"
                          value={formData.clientAddress}
                          onChange={handleChange}
                          className="input-hover"
                          maxLength="100"
                          style={{
                            paddingRight: '30px'
                          }}
                        // placeholder="이름을 입력해주세요."
                        />

                        {formData.clientAddress && (
                          <span
                            onClick={e =>
                              handleValueDelete(
                                'clientAddress',
                                formData,
                                setFormData
                              )
                            }
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-14px)',
                              zIndex: 9
                            }}
                          >
                            <AiFillCloseCircle />
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    {AddrError && (
                      <span className="text-danger">{AddrError}</span>
                    )}{' '}
                    {/* 에러 메시지 표시 */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>등록 일시</Form.Label>
                      <Form.Control
                        // type="datetime-local"
                        type="text"
                        name="addDate"
                        // value={formData.createdAt}
                        value={formatDateTimeForDisplay(formData.createdAt)} // 저장된 형식 그대로 사용
                        disabled
                      // onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>수정 일시</Form.Label>
                      <Form.Control
                        type="text"
                        name="editDate"
                        value={formatDateTimeForDisplay(formData.updateAt)}
                        disabled
                      // onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows={1}
                      value={formData.description}
                      onChange={handleChange}
                      className="input-hover"
                      maxLength="800"
                    />

                    {formData.description && (
                      <span
                        onClick={e =>
                          handleValueDelete(
                            'description',
                            formData,
                            setFormData
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-14px)',
                          zIndex: 9
                        }}
                      >
                        <AiFillCloseCircle />
                      </span>
                    )}
                  </div>
                  {DescError && <div style={{ color: 'red' }}>{DescError}</div>}{' '}
                  {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              {/* <Button variant="secondary" className="mx-3" onClick={handleDeleteRe} style={{ width: '7rem', whiteSpace: 'nowrap' }}>
                관리 키 설정
              </Button> */}
              {/* <Button className="mx-2" variant="outline-danger" onClick={() => setModalCancelShow(true)} style={{ width: '7rem' }}>
                취소
              </Button> */}
              <Button
                variant="primary"
                type="submit"
                disabled={!isFormValid()}
                style={{ width: '7rem' }}
              >
                등록
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={modalCancelShow}
        onHide={() => setModalCancelShow(false)}
        onConfirm={handleCancel}
        title="확인" // 제목
        body="진행 중인 작업을 취소하시겠습니까?"
        variant="info"
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmShow}
        onHide={() => setModalConfirmShow(false)}
        onConfirm={handleConfirm}
        title="확인" // 제목
        variant="info"
        body="고객사 정보를 등록하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={modalConfirmCheckShow}
        onHide={() => setModalConfirmCheckShow(false)}
        variant="success"
        onConfirm={handleConfirm}
        title="확인" // 제목
        body="등록이 완료 되었습니다."
        SubmitButton={true}
      />
    </div>
  );
};

export default AddAdmimSites;

const Profile = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const HoverView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: all 0.1s;
  background-color: #2229;
  user-select: none;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;
