import React, { useState, useEffect } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { checkAuthCode, reSendAuthCode } from 'hooks/CallApi';

const ConfirmMailContent = ({ email, setIsAuthenticated, formData }) => {
  const [authCode, setAuthCode] = useState('');
  const [timer, setTimer] = useState(600); // 10분 = 600초
  const [resendTimer, setResendTimer] = useState(60); // 60초
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true); // 처음 렌더링 체크 상태
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState(false); // 로컬 인증 상태
  const [isCorrectCode, setIsCorrectCode] = useState(true); // 로컬 인증 상태

  const handleConfirm = async () => {
    try {
      const response = await checkAuthCode({ email, code: authCode });
      if (response) {
        setIsAuthenticated(true); // 상위 컴포넌트 상태 변경
        setIsAuthenticatedLocal(true); // 로컬 인증 상태 변경
        setIsCorrectCode(true); // 틀린 코드에 대한 경고 제거
      } else {
        setIsCorrectCode(false); // 틀린 코드에 대한 경고 표시
      }
    } catch (error) {
      alert('이메일 발송이 실패했습니다. 다시 시도해 주세요.');
    }
  };

  // const handleConfirm = () => {
  //   if (authCode === '12345') {
  //     setIsAuthenticated(true); // 상위 컴포넌트 상태 변경
  //     setIsAuthenticatedLocal(true); // 로컬 인증 상태 변경
  //     setIsCorrectCode(true); // 틀린 코드에 대한 경고
  //   } else {
  //     setIsCorrectCode(false); // 틀린 코드에 대한 경고
  //   }
  // };

  // 타이머 작동
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(prevTimer => prevTimer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (resendTimer > 0) {
      const interval = setInterval(() => {
        setResendTimer(prev => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsResendDisabled(false); // 재전송 가능하도록 설정
    }
  }, [resendTimer]);

  const handleInputChange = e => {
    const value = e.target.value;
    // 숫자만 입력 가능
    if (/^\d*$/.test(value)) {
      setAuthCode(value);
    }
  };

  // 남은 시간을 포맷팅하는 함수
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const handleResend = async () => {
    try {
      const response = await reSendAuthCode({
        applyEmail: email
      });

      if (response) {
        setResendTimer(60); // 60초로 초기화
        setIsResendDisabled(true); // 비활성화
        setIsInitialRender(false); // 재전송 버튼이 눌렸을 때 초기 렌더링 상태 변경
        setTimer(600); // 인증 타이머 리셋
        setAuthCode(''); // 인증 코드 입력값 초기화
        setIsCorrectCode(true); // 이전 오류 메시지 제거
      }
    } catch (error) {
      console.error('인증 코드 재전송 실패:', error);
      alert('인증 코드 재전송에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <p align="center">
        발송된 이메일 <strong>({email})</strong>의 인증 코드를 입력하여 인증을
        완료해 주세요.
        <br></br>
        <br></br>
        인증 링크가 만료되었거나 이메일을 받지 못하셨다면,<br></br>
        아래 재전송 버튼을 눌러 인증 메일을 다시 요청하세요.
        <br></br>
        <br></br>
      </p>
      <label>
        <h3>인증 코드</h3>
      </label>
      <InputGroup className="mb-3">
        <div style={{ position: 'relative', flexGrow: 1 }}>
          <FormControl
            placeholder="인증번호를 입력하세요."
            aria-label="number"
            value={authCode}
            onChange={handleInputChange}
            style={{
              borderRadius: '0.25rem',
              paddingRight: '70px'
            }}
            className="input-hover"
          />

          <div
            style={{
              position: 'absolute',
              right: '14px',
              zIndex: 9,
              top: '50%',
              transform: 'translateY(-50%)',
              color: timer > 0 ? 'black' : 'red'
            }}
          >
            {formatTime(timer)}
          </div>
        </div>

        <Button
          variant="outline-secondary"
          id="button-addon2"
          disabled={isResendDisabled}
          onClick={handleResend}
          style={{ width: '85px' }}
        >
          {isInitialRender
            ? '재전송'
            : isResendDisabled
              ? `${resendTimer}`
              : '재전송'}
        </Button>
        <Button
          variant={isAuthenticatedLocal ? 'primary' : 'outline-secondary'}
          id="button-addon2"
          disabled={authCode.length === 0}
          onClick={handleConfirm}
        >
          {isAuthenticatedLocal ? '인증완료' : '인증확인'}
        </Button>
      </InputGroup>
      {!isCorrectCode && timer !== 0 && (
        <span style={{ color: 'red', fontSize: '0.875rem' }}>
          인증 코드가 일치하지 않습니다.
        </span>
      )}
      {timer === 0 && (
        <span style={{ color: 'red', fontSize: '0.875rem' }}>
          인증 코드가 만료되었습니다. 다시 시도해 주세요.
        </span>
      )}
    </>
  );
};

export default ConfirmMailContent;
